import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { Menu } from 'model/Menu';

export const MainMenuItem: Menu[] = [
  {
    text: 'ダッシュボード',
    icon: <DashboardIcon />,
    to: '/',
  },
  {
    text: '工事',
    icon: <AddIcon />,
    to: '/projects/new',
  },
  {
    text: '建設機械等損科算定表',
    icon: <SummarizeIcon />,
    to: '/reports/rx-machine',
  },
];

export const AdminMenuItem: Menu[] = [
  {
    text: 'ダッシュボード',
    icon: <DashboardIcon />,
    to: '/',
  },
  {
    text: '工事',
    icon: <AddIcon />,
    to: '/projects/new',
  },
  {
    text: '建設機械等損料算定表',
    icon: <SummarizeIcon />,
    to: '/reports/rx-machine',
  },
  {
    text: '建設機械等損料算定表編集',
    icon: <SummarizeIcon />,
    to: '/rx-machine',
  },
  {
    text: '公共工事設計労務単価管理',
    icon: <PriceChangeIcon />,
    to: '/master/municipality',
  },
  {
    text: '使用機器管理',
    icon: <PrecisionManufacturingIcon />,
    to: '/master/machine',
  },
];
