import { useState, FC, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MainFrame from 'component/MainFrame';
import HoleHammerForm from 'component/Productivity/HoleHammer';
import AugerForm from 'component/Productivity/Auger/Create';
import HammerInForm from 'component/Productivity/TypeIn/Create';
import PullOutForm from 'component/Productivity/PullOut/Create';
import CarryForm from 'component/Productivity/Carry/Create';
import { WorkType } from 'model/WorkType';
import { Project } from 'model/Project';
import { Machine, Productivity } from 'model/Productivity';
import { HoleHammer } from 'model/Productivity/HoleHammer';
import { Auger } from 'model/Productivity/Auger';
import { TypeIn as HammerIn } from 'model/Productivity/TypeIn';
import { PullOut } from 'model/Productivity/PullOut';
import { EmptyHoleHammer } from 'store/HoleHammer';
import { EmptyAuger, EmptySoils } from 'store/Auger';
import { EmptyTypeIn as EmptyHammerIn } from 'store/TypeIn';
import { EmptyPullOut } from 'store/PullOut';
import { useFetchProjects, useSendProject } from 'usecase/Project';
import { calcProductivityUnit } from 'api/calc/DownThe';
import { Municipality, Masters } from 'model/Master';
import { calcAugerUnit } from 'api/calc/Auger';
import { calcHammerInUnit } from 'api/calc/HammerIn';
import { calcPullOutUnit } from 'api/calc/PullOut';
import { useFetchMaster } from 'usecase/Master';
import { EmptyMunicipality } from 'store/Municipality';
import { useErrorsMutators } from 'globalStates/Errors';
import { useUserState } from 'globalStates/User';
import { Carry } from 'model/Productivity/Carry';
import { EmptyCarry, workContents } from 'store/Carry';
import { calcCarryUnit } from 'api/calc/Carry';
import { useFetchRentFeeCalculations } from 'usecase/RentFeeCalculation';
import { RentFeeCalculation } from 'model/RentFeeCalculation';
import { useSendAudit } from 'usecase/Audit';

const Edit: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userState = useUserState();
  const sendProject = useSendProject();
  const [projects] = useFetchProjects({ id: params.projectId });
  const [municipalities, error] = useFetchMaster({ collectionId: Masters.Municipality });
  const [municipality, setMunicipality] = useState<Municipality>(EmptyMunicipality);
  const [machines, machinesError] = useFetchMaster({ collectionId: Masters.Machine });
  const [productivity, setProductivity] = useState<HoleHammer | Auger | HammerIn | PullOut | Carry>();
  const [project, setProject] = useState<Project>();
  const [rentFeeCalculations, rentFeeError] = useFetchRentFeeCalculations();
  const setErrors = useErrorsMutators();

  const postAudit = useSendAudit();
  const location = useLocation();

  // eslint-disable-next-line
  const convertProductivityAtWorkType = (productivity: any): HoleHammer | Auger | HammerIn | PullOut => {
    const addSoilsForAuger = (productivity: Auger) => {
      if (!productivity.soils) {
        return { ...productivity, soils: EmptySoils };
      }
      return productivity;
    };
    switch (productivity.workType) {
      case WorkType.HOLE_HAMMER:
        return productivity as HoleHammer;
      case WorkType.AUGER:
        return addSoilsForAuger(productivity as Auger);
      case WorkType.HAMMER_IN:
        return productivity as HammerIn;
      case WorkType.PULL_OUT:
        return productivity as PullOut;
      default:
        return productivity as HoleHammer;
    }
  };

  useEffect(() => {
    if (projects && projects.length > 0 && projects[0].productivities && projects[0].productivities.length > 0) {
      const project = projects[0];
      const product = project.productivities.find((prod) => prod.id === params.productId);
      product && setProductivity(convertProductivityAtWorkType(product));
      setProject(project);
    }
  }, [params.productId, projects]);

  const calcProductivityUnitPrice = useCallback(
    (
      productivity: Productivity,
      municipality: Municipality,
      machine: Machine,
      rentFeeCalculations: RentFeeCalculation[]
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const emptyResult = (prod: Productivity, municipality: Municipality) => 0;

      if (productivity.workType === WorkType.HOLE_HAMMER) {
        return calcProductivityUnit(productivity as HoleHammer, municipality, machine, rentFeeCalculations);
      }
      if (productivity.workType === WorkType.AUGER) {
        return calcAugerUnit(productivity as Auger, municipality, machine);
      }
      if (productivity.workType === WorkType.HAMMER_IN) {
        return calcHammerInUnit(productivity as HammerIn, municipality, machine);
      }
      if (productivity.workType === WorkType.PULL_OUT) {
        return calcPullOutUnit(productivity as PullOut, municipality, machine);
      }
      if (productivity.workType === WorkType.CARRY) {
        return calcCarryUnit(productivity as Carry, municipality);
      }
      return emptyResult(productivity, municipality);
    },
    []
  );

  const handleClickConfirm = async (productivity: HoleHammer | Auger | HammerIn | PullOut | Carry) => {
    try {
      const oldProductivities = project?.productivities || [];
      const productivities = oldProductivities.map((prod, n) => {
        if (prod.id === productivity.id) {
          const machine = machines.filter((machine) => machine.key === productivity.usedMachine)[0];
          const unitPrice = calcProductivityUnitPrice(
            productivity,
            municipality,
            machine as Machine,
            rentFeeCalculations
          );
          return {
            ...prod,
            ...productivity,
            id: `${n}`,
            projectId: project?.id,
            unitPrice,
            remark: `第${n + 1}号代価表`,
            updatedBy: userState.user?.id,
          };
        }
        return {
          ...prod,
          id: `${n}`,
          projectId: project?.id,
          remark: `第${n + 1}号代価表`,
        };
      });
      const newProject = { ...project, productivities, updatedBy: userState.user?.id } as Project;
      const savedProject = await sendProject(newProject);
      postAudit(
        'success',
        location,
        userState.user,
        ['edit', 'productivity', productivity.workType],
        JSON.stringify({ ...productivity, projectId: project?.id }),
        undefined
      );
      navigate(`/${savedProject.id}`);
    } catch (e) {
      console.log(e);
      postAudit(
        'success',
        location,
        userState.user,
        ['edit', 'productivity', productivity.workType],
        JSON.stringify({ ...productivity, projectId: project?.id }),
        JSON.stringify(e)
      );
    }
  };

  const handleUpdateProductivity = <T extends Productivity>(prop: keyof T, productivity: T) => {
    console.log('update productivity', prop, productivity);
  };

  const handleClickBack = () => {
    navigate(`/${project?.id || '/'}`);
  };

  // eslint-disable-next-line
  const handleUpdate = (data: any) => {
    setProductivity(convertProductivityAtWorkType(data));
  };

  useEffect(() => {
    if (municipalities && municipalities.length > 0) {
      const municipality = (municipalities.find((m) => m.value === project?.targetPref) ||
        EmptyMunicipality) as Municipality;
      setMunicipality(municipality);
    }
  }, [municipalities, project?.targetPref]);

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([error, machinesError, rentFeeError]);
  }, [error, machinesError, setErrors, rentFeeError]);

  const renderProductivity = (wt: WorkType | undefined) => {
    switch (wt) {
      case WorkType.HOLE_HAMMER:
        return (
          <HoleHammerForm
            openCalculate
            productivity={(productivity as HoleHammer) || EmptyHoleHammer}
            machines={machines}
            onClickConfirm={handleClickConfirm}
            onClickBack={handleClickBack}
            onUpdate={handleUpdate}
            updateProductivity={handleUpdateProductivity}
          />
        );
      case WorkType.AUGER:
        return (
          <AugerForm
            openCalculate
            productivity={(productivity as Auger) || EmptyAuger}
            machines={machines}
            onClickConfirm={handleClickConfirm}
            onClickBack={handleClickBack}
            onUpdate={handleUpdate}
            updateProductivity={handleUpdateProductivity}
          />
        );
      case WorkType.HAMMER_IN:
        return (
          <HammerInForm
            openCalculate
            productivity={(productivity as HammerIn) || EmptyHammerIn}
            machines={machines}
            onClickConfirm={handleClickConfirm}
            onClickBack={handleClickBack}
            updateProductivity={handleUpdateProductivity}
            onUpdate={handleUpdate}
          />
        );
      case WorkType.PULL_OUT:
        return (
          <PullOutForm
            openCalculate
            productivity={(productivity as PullOut) || EmptyPullOut}
            machines={machines}
            onClickConfirm={handleClickConfirm}
            onClickBack={handleClickBack}
            updateProductivity={handleUpdateProductivity}
            onUpdate={handleUpdate}
          />
        );
      case WorkType.CARRY:
        return (
          <CarryForm
            openCalculate
            carry={(productivity as Carry) || EmptyCarry}
            workContents={workContents}
            municipality={municipality}
            onClickConfirm={handleClickConfirm}
            onClickBack={handleClickBack}
            updateProductivity={handleUpdateProductivity}
            onUpdate={handleUpdate}
          />
        );
      default:
        return <div />;
    }
  };
  // if (!project) navigate('/projects');
  return <MainFrame title="見積新規作成">{renderProductivity(productivity?.workType)}</MainFrame>;
};

export default Edit;
