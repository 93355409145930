import { FC, useEffect, useMemo } from 'react';
import RXMachineReport from 'component/Report/RXMachineReport';
import { useFetchFiscalYear, useFetchRentFeeCalculations, useFetchRentFeeNotes } from 'usecase/RentFeeCalculation';
import { useErrorsMutators } from 'globalStates/Errors';

const MAX_DISPLAYED_ROWS = 13;

const RxMachine: FC = () => {
  const setErrors = useErrorsMutators();
  const [rentFeeCalculations, error] = useFetchRentFeeCalculations();
  const [notes, noteError] = useFetchRentFeeNotes();
  const fiscalYear = useFetchFiscalYear();

  const pageNum = useMemo(
    () => Math.ceil(rentFeeCalculations.length / MAX_DISPLAYED_ROWS),
    [rentFeeCalculations.length]
  );
  const paginated = useMemo(
    () =>
      [...Array(pageNum)]
        .map((_, i) => i)
        .map((index) => {
          const min = index * MAX_DISPLAYED_ROWS;
          const max = (index + 1) * MAX_DISPLAYED_ROWS;
          return rentFeeCalculations.slice(min, max);
        }),
    [pageNum, rentFeeCalculations]
  );

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([error, noteError]);
  }, [error, noteError, setErrors]);

  return (
    <div>
      {paginated.map((data, index) => {
        const isLastPage = paginated.length === index + 1;
        return <RXMachineReport date={fiscalYear} reportDataList={data} key={index} notes={isLastPage ? notes : []} />;
      })}
    </div>
  );
};

export default RxMachine;
