import { login, logout } from 'api/firebase/Login';
import { UserCredential } from 'firebase/auth';

export const useLoginRepository =
  (): ((email: string, password: string) => Promise<UserCredential | undefined>) =>
  async (email: string, password: string): Promise<UserCredential | undefined> => {
    return await login(email, password);
  };

export const useLogoutRepository = (): (() => Promise<void> | undefined) => (): Promise<void> | undefined => logout();
