import { FC, useCallback, useState } from 'react';
import {
  DataGrid,
  GridValueGetterParams,
  GridColumns,
  GridRenderCellParams,
  GridSelectionModel,
  GridPreProcessEditCellProps,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { defaultPageSize, rowsPerPage } from 'store/Pagination';
import { Productivity } from 'model/Productivity';
import { Link } from '@mui/material';
import { workTypes } from 'store/WorkType';
import { WorkType } from 'model/WorkType';
import { amountRoundUp, round } from 'api/calc/Calc';
import { User } from 'model/User';

export type EditableColumns = 'unit' | 'quantity' | 'specification';

interface Props {
  items: Productivity[];
  users: User[];
  selectionModel?: GridSelectionModel;
  setSelectionModel?: (selectionModel: GridSelectionModel) => void;
  onChange?: (id: string, prop: EditableColumns, value: string | number) => void;
  onClickName?: (item: Productivity) => void;
}

export const ItemizedStatementDataGrid: FC<Props> = ({
  items,
  users,
  selectionModel,
  setSelectionModel,
  onChange,
  onClickName,
}) => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const handleChangeUnit = useCallback(
    (params: GridPreProcessEditCellProps) => {
      onChange && onChange(params.row.id, 'unit', params.props.value);
    },
    [onChange]
  );
  const handleChangeQuantity = useCallback(
    (params: GridPreProcessEditCellProps) => {
      onChange && onChange(params.row.id, 'quantity', params.props.value);
    },
    [onChange]
  );
  const handleChangeSpecification = useCallback(
    (params: GridPreProcessEditCellProps) => {
      onChange && onChange(params.row.id, 'specification', params.props.value);
    },
    [onChange]
  );

  const columns = useCallback(
    (): GridColumns => [
      {
        field: 'workType',
        headerName: '工種',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => workTypes.find((wt) => wt.value === params.row.workType)?.label,
      },
      {
        field: 'name',
        headerName: '名称',
        headerAlign: 'left',
        align: 'left',
        width: 250,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link
            key={params.id}
            component="button"
            color="inherit"
            variant="body2"
            onClick={() => onClickName && onClickName(params.row)}
          >
            {params.row.name}
          </Link>
        ),
      },
      {
        field: 'specification',
        headerName: '規格',
        width: 250,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          handleChangeSpecification(params);
          return { ...params.props };
        },
      },
      {
        field: 'unit',
        headerName: '単位',
        type: 'singleSelect',
        valueOptions: ['式', '本', '枚', '回', '箇所', '往復'],
        width: 80,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          handleChangeUnit(params);
          return { ...params.props };
        },
      },
      {
        field: 'quantity',
        headerName: '数量',
        headerAlign: 'left',
        width: 70,
        type: 'number',
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          handleChangeQuantity(params);
          return { ...params.props };
        },
      },
      {
        field: 'unitPrice',
        headerName: '単価',
        headerAlign: 'left',
        width: 130,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'subtotal',
        headerName: '金額',
        headerAlign: 'left',
        width: 130,
        type: 'number',
        valueGetter: (params: GridValueGetterParams) => {
          const total = () => {
            const unitPrice = params.row.unitPrice || 0;
            const quantity = params.row.quantity || 0;
            if (params.row.workType === WorkType.CARRY) {
              return unitPrice * quantity;
            }
            if (params.row.workType === WorkType.HOLE_HAMMER) {
              return round(unitPrice * quantity);
            }
            return amountRoundUp(unitPrice * quantity);
          };
          return total();
        },
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      { field: 'remark', headerName: '摘要', width: 150 },
      {
        field: 'updatedBy',
        headerName: '作成者',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => {
          return users.find((user) => params.row.updatedBy === user.id)?.name;
        },
      },
    ],
    [handleChangeQuantity, handleChangeSpecification, handleChangeUnit, onClickName, users]
  );
  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={items}
        columns={columns()}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPage}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={setSelectionModel}
        selectionModel={selectionModel}
      />
    </div>
  );
};
