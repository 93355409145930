import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { User } from 'model/User';

interface UserState {
  user: User | undefined;
}

const userState = atom<UserState>({
  key: 'user',
  default: {
    user: undefined,
  },
});

export const useUserState = () => useRecoilValue(userState);
export const useUserMutators = () => {
  const setState = useSetRecoilState(userState);
  const setUser = (user: User) => {
    return setState((previousState: UserState) => {
      return {
        ...previousState,
        user,
      };
    });
  };

  return [setUser];
};
