import { db } from 'api/firebase';
import { Municipality } from 'model/Master';
import {
  fetchMunicipalities,
  setMunicipality,
  fetchMachines,
  setMachine,
  deleteMunicipality,
  deleteMachine,
} from 'api/Master';
import { Machine } from 'model/Productivity';

export const useReadMunicipalityRepository = (): (() => Promise<Municipality[]>) => async (): Promise<Municipality[]> =>
  await fetchMunicipalities(db);

export const useWriteMunicipalityRepository =
  (): ((municipality: Municipality) => Promise<Municipality>) =>
  async (municipality: Municipality): Promise<Municipality> => {
    return await setMunicipality(db, municipality);
  };

export const useDeleteMunicipalityRepository =
  (): ((municipalities: Municipality[]) => Promise<void[]>) =>
  async (municipalities: Municipality[]): Promise<void[]> => {
    return await deleteMunicipality(db, municipalities);
  };

export const useReadMachineRepository = (): (() => Promise<Machine[]>) => async (): Promise<Machine[]> =>
  await fetchMachines(db);

export const useWriteMachineRepository =
  (): ((machine: Machine) => Promise<Machine>) =>
  async (machine: Machine): Promise<Machine> => {
    return await setMachine(db, machine);
  };

export const useDeleteMachineRepository =
  (): ((machines: Machine[]) => Promise<void[]>) =>
  async (machines: Machine[]): Promise<void[]> => {
    return await deleteMachine(db, machines);
  };
