import { db } from 'api/firebase';
import { fetchUser, fetchUsers } from 'api/User';
import { User } from 'model/User';

export const useReadUsersRepository = (): (() => Promise<User[]>) => async (): Promise<User[]> => await fetchUsers(db);

export const useReadUserRepository =
  (): ((id: string) => Promise<User | undefined>) =>
  async (id: string): Promise<User | undefined> =>
    await fetchUser(db, id);
