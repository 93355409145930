/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  DocumentReference,
} from 'firebase/firestore';
import { convertProject, Project, ProjectCondition } from 'model/Project';

export const setProject2 = async (db: any, project: Project): Promise<Project> => {
  if (project.id) {
    const docRef = doc(db, 'projects', project.id) as DocumentReference<Project>;
    const newProject = {
      ...project,
      id: project.id,
      updatedAt: serverTimestamp(),
    };
    await updateDoc(docRef, { ...newProject, createdAt: undefined, updatedAt: undefined });
    return { ...newProject, createdAt: undefined, updatedAt: undefined };
  } else {
    const docRef = doc(collection(db, 'projects')) as DocumentReference<Project>;
    const newProject = {
      ...project,
      id: docRef.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    await setDoc(docRef, { ...newProject, createdAt: undefined, updatedAt: undefined });
    return { ...newProject, createdAt: undefined, updatedAt: undefined };
  }
};

export const setProject = async (db: any, project: Project): Promise<Project> => {
  if (project.id) {
    const docRef = doc(db, 'projects', project.id);
    const newProject = {
      ...project,
      id: project.id,
    };
    await updateDoc(docRef, { ...newProject, updatedAt: serverTimestamp() });
    return newProject;
  } else {
    const docRef = doc(collection(db, 'projects'));
    const newProd = project.productivities.map((prod) => ({ ...prod, projectId: docRef.id }));
    const newProject = {
      ...project,
      id: docRef.id,
      productivities: newProd,
    };
    await setDoc(docRef, { ...newProject, createdAt: serverTimestamp(), updatedAt: serverTimestamp() });
    return newProject;
  }
};

export const setProjects = async (db: any, projects: Project[]): Promise<Project[]> =>
  Promise.all(projects.map(async (p) => await setProject(db, p)));

export const getProjects = async (db: any, condition?: ProjectCondition): Promise<Project[]> => {
  const ret: Project[] = [];
  const q: QueryConstraint[] = [];
  if (condition?.id) {
    q.push(where('id', '==', condition.id));
  }
  const ss = await getDocs(query(collection(db, 'projects'), ...q));
  ss.forEach((doc) => ret.push(convertProject(doc.data())));
  return ret;
};

export const deleteProjects = async (db: any, projects: Project[]): Promise<void[]> =>
  Promise.all(projects.map(async (p) => await deleteDoc(doc(db, 'projects', p.id || ''))));
