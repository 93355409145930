import { DownloadFile } from 'model/File';

export const DownloadItems: DownloadFile[] = [
  {
    text: 'ダウンザホールハンマ工積算資料',
    fileName: '積算資料_ダウンザホールハンマ工',
    path: '',
    sourceName: 'down_the_hole_hammer.pdf',
  },
  {
    text: 'オーガ工積算資料',
    fileName: '積算資料_オーガ工・バイブロ打込工',
    path: '',
    sourceName: 'auger.pdf',
  },
  {
    text: '打込・引抜積算資料',
    fileName: '積算資料_打込み工・引抜き工',
    path: '',
    sourceName: 'hammer_in_pull_out.pdf',
  },
  {
    text: '運搬積算資料',
    fileName: '積算資料_油圧式リーダレス工法_重機組立解体運搬費',
    path: '',
    sourceName: 'carry.pdf',
  },
];
