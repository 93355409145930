import { Project } from 'model/Project';

export const EmptyProject: Project = {
  estimateSubmissionDate: new Date(),
  estimateValidity: '',
  customerName: '',
  requesterName: '',
  constractionName: '',
  constractionSite: '',
  targetPref: '',
  productivities: [],
  createdAt: new Date(),
  updatedAt: new Date(),
};
