import { FC, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid } from '@mui/material';
import { Machine } from 'model/Productivity';
import { TextField } from 'component/TextField';

interface Props {
  machine: Machine;
  onClickBack: () => void;
  onClickConfirm: (machine: Machine) => void;
  onUpdate?: (machine: Machine) => void;
}

const schema = z.object({
  name: z.string(),
  rentFee: z.number().nonnegative(),
  requiredOilAmount: z.number().nonnegative(),
  order: z.number().nonnegative(),
});

const Create: FC<Props> = ({ machine, onClickBack, onUpdate, onClickConfirm }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  console.log('error', errors);

  // eslint-disable-next-line
  const parse = (data: any): Machine => ({ ...machine, ...data });

  const onSubmit = useCallback(
    // eslint-disable-next-line
    (data: any) => {
      onClickConfirm(parse(data));
    },
    [onClickConfirm, parse]
  );

  useEffect(() => {
    Object.entries(machine).map(([name, value]) => setValue(name, value));
  }, [machine, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parse(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, parse, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label="使用機器名称"
            variant="standard"
            defaultValue={machine.name}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="rentFee"
            name="rentFee"
            label="単価"
            type="number"
            variant="standard"
            defaultValue={machine.rentFee}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="requiredOilAmount"
            name="requiredOilAmount"
            label="燃料(ℓ/h)"
            type="number"
            variant="standard"
            defaultValue={machine.requiredOilAmount}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="order"
            name="order"
            label="並び順"
            type="number"
            variant="standard"
            defaultValue={machine.order}
            control={control}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
          戻る
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
          確認
        </Button>
      </Box>
    </form>
  );
};

export default Create;
