import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLoginMutators } from 'globalStates/Login';
import { useRoleState } from 'globalStates/Role';
import { useUserMutators } from 'globalStates/User';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useFetchUser } from 'usecase/User';

const AuthCheck = () => {
  const [setAuth] = useLoginMutators();
  const [setUserState] = useUserMutators();
  const [, setRole] = useRoleState();
  const [isLoading, setIsLoading] = useState(true);
  const getUserData = useFetchUser();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribed = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuth(true);
        getUserData(user.uid).then((data) => {
          setUserState({
            id: user.uid,
            name: user.displayName || '',
            email: user.email || '',
            role: data?.role || 'user',
          });
        });
      } else {
        setAuth(false);
        setRole(null, true);
      }
      setIsLoading(false);
    });
    return () => {
      unsubscribed();
    };
  }, [getUserData, setAuth, setRole, setUserState]);

  if (isLoading) {
    return <div />;
  }
  return <Outlet />;
};

export default AuthCheck;
