import { useCallback, FC, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainFrame from 'component/MainFrame';
import DashboardComponent from 'component/Dashboard';
import { useFetchProjects, useDeleteProjects, useSendProjects } from 'usecase/Project';
import { Project } from 'model/Project';
import { useErrorsMutators } from 'globalStates/Errors';
import { GridColumns, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Link } from '@mui/material';
import { useFetchMaster } from 'usecase/Master';
import { Masters } from 'model/Master';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const [projects, error, refetch] = useFetchProjects();
  const [municipalities, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const deleteProjects = useDeleteProjects();
  const saveProjects = useSendProjects();
  const setErrors = useErrorsMutators();

  const handleClickNew = useCallback(() => navigate('/projects/new', { state: { mode: '' } }), [navigate]);

  const handleClickDelete = useCallback(
    async (selectedItems: Project[]) => {
      try {
        await deleteProjects(selectedItems);
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      } finally {
        refetch();
      }
    },
    [deleteProjects, refetch, setErrors]
  );

  const handleClickCopy = useCallback(
    async (selectedItems: Project[]) => {
      try {
        const newProjects: Project[] = selectedItems.map((item) => {
          return {
            ...item,
            id: undefined,
          };
        });
        await saveProjects(newProjects);
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      } finally {
        refetch();
      }
    },
    [refetch, saveProjects, setErrors]
  );

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([error, municipalitiesError]);
  }, [error, municipalitiesError, setErrors]);

  const columns = useMemo(
    (): GridColumns => [
      {
        field: 'constractionName',
        headerName: '工事名称',
        headerAlign: 'left',
        align: 'left',
        width: 250,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link color="inherit" href={`/${params.id}`} key={params.id}>
            {params.row.constractionName}
          </Link>
        ),
      },
      { field: 'constractionSite', headerName: '工事場所', width: 300 },
      {
        field: 'targetPref',
        headerName: '対象県',
        width: 150,
        valueGetter: (params: GridValueGetterParams) =>
          municipalities.find((p) => p.value === params.row.targetPref)?.name,
      },
      {
        field: 'productivities',
        headerName: '歩掛数',
        width: 70,
        headerAlign: 'left',
        type: 'number',
        valueGetter: (params: GridValueGetterParams) => params.row.productivities.length,
      },
      {
        field: 'estimateSubmissionDate',
        headerName: '見積提出日',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.estimateSubmissionDate, 'yyyy年M月d日'),
      },
      { field: 'customerName', headerName: '見積宛先', width: 200 },
      { field: 'requesterName', headerName: '見積依頼元', width: 200 },
      {
        field: 'createdAt',
        headerName: '作成日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.createdAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedAt',
        headerName: '更新日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.updatedAt, 'yyyy年M月d日'),
      },
    ],
    [municipalities]
  );

  return (
    <MainFrame title="ダッシュボード">
      <DashboardComponent
        projects={projects}
        columns={columns}
        onClickNew={handleClickNew}
        onClickCopy={handleClickCopy}
        onClickDelete={handleClickDelete}
      />
    </MainFrame>
  );
};

export default Dashboard;
