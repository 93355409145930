import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Grid, Box, Button, Paper, Typography, Checkbox, FormControlLabel } from '@mui/material';
import Select, { SelectItem } from 'component/Select';
import { distances } from 'store/Carry';
import { Carry } from 'model/Productivity/Carry';
import CalculationResults from './CalculationResults';
import { TextField } from 'component/TextField';
import Copyright from 'component/Copyright';
import { MasterData, Municipality } from 'model/Master';

interface Props {
  carry: Carry;
  workContents: MasterData[];
  municipality: Municipality;
  openCalculate: boolean;
  onClickBack: () => void;
  onClickConfirm: (productivity: Carry) => void;
  updateProductivity: (prop: keyof Carry, productivity: Carry) => void;
  onUpdate?: (productivity: Carry) => void;
}
const schema = z.object({
  name: z.string(),
  distance: z.string(),
  work: z.string(),
});

const Create: FC<Props> = ({
  carry,
  workContents,
  municipality,
  openCalculate,
  onClickBack,
  updateProductivity,
  onClickConfirm,
  onUpdate,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  console.log('errors:', errors);
  // eslint-disable-next-line
  const convertSelectItem = (item: any): SelectItem => ({
    key: item?.key || '',
    label: item?.name,
    value: item?.key || '',
  });
  const distanceItems = useMemo(() => distances.map(convertSelectItem), []);

  const workItems = useMemo(() => workContents.map(convertSelectItem), [workContents]);

  // eslint-disable-next-line
  const parseProductivity = (data: any): Carry => {
    return { ...carry, ...data };
  };

  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    onClickConfirm(parseProductivity(data));
  };

  const handleChangeInjectionCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      onUpdate && onUpdate(parseProductivity({ ...carry, injection: checked }));
    },
    [carry, onUpdate, parseProductivity]
  );

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parseProductivity(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, watch, updateProductivity, parseProductivity]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {carry.remark || '新しい歩掛り'}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  label="名称"
                  variant="standard"
                  defaultValue={carry.name}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  required
                  fullWidth
                  id="distance"
                  labelId="distance-label"
                  label="運搬距離"
                  name="distance"
                  items={distanceItems}
                  defaultValue={carry.distance}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  required
                  fullWidth
                  id="work"
                  labelId="work-label"
                  label="作業内容"
                  name="work"
                  items={workItems}
                  defaultValue={carry.work}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox checked={carry.injection} name="injection" onChange={handleChangeInjectionCheckbox} />
                  }
                  label="注入プラント仮設撤去"
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
                戻る
              </Button>
              <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
                確認
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <CalculationResults productivity={carry} municipality={municipality} open={openCalculate} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Create;
