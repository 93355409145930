import { Municipality } from 'model/Master';
import { Carry } from 'model/Productivity/Carry';
import { workProductionRates, workExpenseRates } from 'store/Carry';
import { round } from './Calc';

export const calcWorkProductionRateValue = (workContents: string, injection: boolean) => {
  const rate = workProductionRates.find((wpr) => wpr.id === workContents)?.value || 0;
  return injection ? rate + 1 : rate;
};

export const calcWorkExpenseRate = (workContents: string, distance: string) =>
  workExpenseRates.find((wer) => wer.id === workContents)?.rates.find((r) => r.id === distance);

export const calcCarryUnit = (productivity: Carry, municipality: Municipality): number => {
  const quantity = calcWorkProductionRateValue(productivity.work, productivity.injection);

  const multiplication = (a: number, b: number) => round(a * b, 0);
  const gCECaretakerSubtotal = multiplication(quantity, municipality.generalCivilEngineeringCaretaker);
  const scaffoldWorkerSubtotal = multiplication(quantity, municipality.scaffoldWorker);
  const specialWorkerSubtotal = multiplication(quantity, municipality.specialWorker);
  const ordinaryWorkersSubtotal = multiplication(quantity, municipality.ordinaryWorkers);

  const subtotal = gCECaretakerSubtotal + scaffoldWorkerSubtotal + specialWorkerSubtotal + ordinaryWorkersSubtotal;
  const expenseRate = calcWorkExpenseRate(productivity.work, productivity.distance)?.value || 0;
  const expense = multiplication(expenseRate / 100, subtotal);

  return subtotal + expense;
};
