import { useState, FC, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { Project } from 'model/Project';
import { ProjectDataGrid } from 'component/ProjectDataGrid';
import { GridColumns, GridSelectionModel } from '@mui/x-data-grid';

interface Props {
  columns: GridColumns<Project>;
  projects: Project[];
  onClickNew?: () => void;
  onClickCopy?: (selectItem: Project[]) => void;
  onClickDelete?: (selectItem: Project[]) => void;
  onClickReload?: () => void;
}

const Dashboard: FC<Props> = ({ projects, columns, onClickNew, onClickCopy, onClickDelete, onClickReload }) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [enableButton, setEnableButton] = useState(false);

  const handleCheck = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };

  const handleCopy = () => {
    onClickCopy && onClickCopy(projects.filter((project) => selectionModel.includes(project.id || '')));
    setSelectionModel([]);
  };
  const handleDelete = () => {
    onClickDelete && onClickDelete(projects.filter((project) => selectionModel.includes(project.id || '')));
    setSelectionModel([]);
  };
  useEffect(() => setEnableButton(selectionModel && selectionModel.length > 0), [selectionModel]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {onClickNew && (
          <Button variant="contained" onClick={onClickNew}>
            新規作成
          </Button>
        )}
        {handleCopy && (
          <Button variant="contained" sx={{ ml: 1 }} onClick={handleCopy} disabled={!enableButton}>
            コピー
          </Button>
        )}
        {handleDelete && (
          <Button variant="contained" color="error" sx={{ ml: 1 }} onClick={handleDelete} disabled={!enableButton}>
            削除
          </Button>
        )}
        {onClickReload && (
          <Button variant="contained" sx={{ ml: 1 }} onClick={onClickReload}>
            更新
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <ProjectDataGrid
          columns={columns}
          projects={projects}
          selectionModel={selectionModel}
          setSelectionModel={handleCheck}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
