import { Municipality } from 'model/Master';
import { PullOut } from 'model/Productivity/PullOut';
import { OtherExpensiveRate } from 'store/Productivity';
import {
  PULL_OUT_BETA,
  PULL_OUT_DRIVING_AND_PULLING_TIME,
  PULL_OUT_STANDARD_QUANTITY,
  PULL_OUT_T1_PREPARATION_TIME,
  SteelMaterialTypeItems,
  SteelMaterialWorkingTimePerUnit,
  WeltingBoltMountingTimeItems,
} from 'store/PullOut';
import { amountRoundUp, round } from './Calc';
import { WORK_TIME } from 'store/Master';
import { calcHydraulicVibroUnitPrice } from './UnitPrice';
import { Machine } from 'model/Productivity';

/**
 * K：鋼矢板形式による係数 の値を求める
 * @param drivenConstant 鋼材種類寸法・型
 * @returns K：鋼矢板形式による係数
 */
export const calcPullOutSteelSheetTypeConstant = (steelMaterialType: string): number => {
  return SteelMaterialTypeItems.find((smt) => smt.value === steelMaterialType)?.factor || 0;
};

/**
 * T3：溶接時間、ボルト取り付け時間 を求める
 * @param bondingTime 切断・ボルト取り外し種類
 * @param bondingCount 回数
 * @param steelMaterialType  鋼材種類 寸法・型
 * @returns 溶接時間、ボルト取り付け時間 min
 */
export const calcPullOutBoldingTime = (
  weltingBoltMountingTime: string,
  weltingBoltMountingCount: number,
  steelMaterialType: string
): number => {
  const BoldingTimePerUnit = (): number => {
    const cutting = WeltingBoltMountingTimeItems.find((wb) => wb.name === '切断')?.value;
    const boltRemove = WeltingBoltMountingTimeItems.find((wb) => wb.name === '取り外し')?.value;

    if (weltingBoltMountingTime === cutting) {
      return SteelMaterialWorkingTimePerUnit.find((wt) => wt.value === steelMaterialType)?.cuttingTime || 0;
    }
    if (weltingBoltMountingTime === boltRemove) {
      return SteelMaterialWorkingTimePerUnit.find((wt) => wt.value === steelMaterialType)?.boltRemovingTime || 0;
    }
    return 0;
  };
  return BoldingTimePerUnit() * weltingBoltMountingCount;
};

/**
 * T2：１単位当たり引抜時間 を求める
 * @param drivenLength L：引抜長 m
 * @param betaConstant β：定数
 * @param steelSheetTypeConstant K：鋼矢板形式による係数
 * @returns １単位当たり打込時間
 */
export const calcPullOutWorkingTimePerUnit = (
  drivenLength: number,
  betaConstant: number,
  steelSheetTypeConstant: number
) => {
  return round(PULL_OUT_DRIVING_AND_PULLING_TIME * drivenLength * betaConstant * steelSheetTypeConstant);
};

/**
 * Tｃ：１単位当たり施工時間 を求める
 * @param preparationTime T1：１単位当たり準備時間 min
 * @param workingTimePerUnit T2：１単位当たり引抜時間
 * @param boldingTime T3：溶接時間、ボルト取り付け時間 min
 * @param factorTotal 作業係数
 * @returns １単位当たり施工時間 min/本
 */
export const calcPullOutConstructionTimePerUnit = (
  preparationTime: number,
  workingTimePerUnit: number,
  boldingTime: number,
  factorTotal: number
) => {
  return round((preparationTime + workingTimePerUnit + boldingTime) * factorTotal, 2);
};

/**
 * 歩掛りから算出引抜き 1単位当たり施工日数  を求める
 * @param productivity 引抜歩掛り
 * @returns 日当たり施工数量 （枚・本）/日
 */
export const calcPullOutConstructionDaysPerUnit = (productivity: PullOut) => {
  const t1 = PULL_OUT_T1_PREPARATION_TIME;
  const k = calcPullOutSteelSheetTypeConstant(productivity.steelMaterialType);
  const f = round(1 + productivity.factorTotal);
  const t = WORK_TIME;
  const beta = PULL_OUT_BETA;
  const t2 = calcPullOutWorkingTimePerUnit(productivity.drivenLength, beta, k);
  const t3 = calcPullOutBoldingTime(
    productivity.weltingBoltMountingTime,
    productivity.weltingBoltMountingCount,
    productivity.steelMaterialType
  );
  const tc = calcPullOutConstructionTimePerUnit(t1, t2, t3, f);
  return round(tc / 60 / t, 3);
};

/**
 * 単価を求める
 * @param productivity
 * @param municipality
 * @returns
 */
export const calcPullOutUnit = (productivity: PullOut, municipality: Municipality, machine: Machine): number => {
  const constructionDaysPerUnit = calcPullOutConstructionDaysPerUnit(productivity);
  const otherExpensesRate = OtherExpensiveRate.filter((oer) => oer.workType === productivity.workType)[0]?.rate || 0;
  const quantity = PULL_OUT_STANDARD_QUANTITY * constructionDaysPerUnit;

  const multiplication = (a: number, b: number) => round(a * b, 0);

  const gCECaretakerSubtotal = multiplication(quantity, municipality.generalCivilEngineeringCaretaker);
  const scaffoldWorkerSubtotal = multiplication(quantity, municipality.scaffoldWorker);
  const specialWorkerSubtotal = multiplication(quantity, municipality.specialWorker);
  const ordinaryWorkersSubtotal = multiplication(quantity, municipality.ordinaryWorkers);

  const isCutting =
    WeltingBoltMountingTimeItems.filter((wbt) => wbt.name === '切断')[0].value === productivity.weltingBoltMountingTime;
  const weldersQuantityFactor = isCutting ? 1 : 0;
  const weldersQuantity = round(quantity * weldersQuantityFactor);
  const weldersSubtotal = multiplication(weldersQuantity, municipality.welders);

  const usedMachineUnitPrice = calcHydraulicVibroUnitPrice(municipality, machine);
  const pileDrivingSubtotal = multiplication(quantity, usedMachineUnitPrice);

  const subTotal =
    gCECaretakerSubtotal +
    scaffoldWorkerSubtotal +
    specialWorkerSubtotal +
    ordinaryWorkersSubtotal +
    weldersSubtotal +
    pileDrivingSubtotal;
  const otherExpensesPrice = round(subTotal * otherExpensesRate, 2);
  const beforeRoundTotal = subTotal + otherExpensesPrice;
  const total = amountRoundUp(beforeRoundTotal);
  const beforeRoundTotalPerUnit = Math.floor(total / PULL_OUT_STANDARD_QUANTITY);

  return beforeRoundTotalPerUnit;
};
