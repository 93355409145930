import { useCallback, useState, FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainFrame from 'component/MainFrame';
import CreateComponent from 'component/Project/Create';
import { Project } from 'model/Project';
import { EmptyProject } from 'store/Project';
import { useFetchProjects } from 'usecase/Project';
import { useErrorsMutators } from 'globalStates/Errors';
import { useFetchMaster } from 'usecase/Master';
import { Masters, Municipality } from 'model/Master';
import { useUserState } from 'globalStates/User';

// TODO: 工事の編集画面が以前のコードのままのため修正が必要
const Edit: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userState = useUserState();
  const [projects, error] = useFetchProjects({ id: params.projectId });
  const [municipalities, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const setErrors = useErrorsMutators();

  const [, setMode] = useState<'' | 'hours'>('hours');
  const [project, setProject] = useState<Project>(EmptyProject);
  const handleClickConfirm = useCallback(
    (project: Project) => {
      setProject({ ...project, updatedBy: userState.user?.id });
      setMode('hours');
    },
    [userState.user?.id]
  );
  const handleClickBack = useCallback(() => navigate('/'), [navigate]);
  const updateProject = useCallback((prop: keyof Project, project: Project) => setProject(project), []);

  // const [productivity, setProductivity] = useState<HoleHammer>(EmptyHoleHammer);
  // const handleClickProductivity = useCallback(
  //   async (productivity: HoleHammer) => {
  //     try {
  //       setProductivity(productivity);
  //       const updateProductivityIndex = project.productivities.findIndex((prod) => prod.id === productivity.id);
  //       if (updateProductivityIndex > -1) {
  //         const newProject = { ...project };
  //         newProject.productivities[updateProductivityIndex] = productivity;
  //         await addProject(newProject);
  //       } else {
  //         const productivities = [...project.productivities, productivity];
  //         const newProject = { ...project, productivities };
  //         await addProject(newProject);
  //       }
  //       navigate(`/${project.id}`);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   },
  //   [project, addProject, navigate]
  // );
  // const updateProductivity = useCallback((prop: keyof HoleHammer, productivity: HoleHammer) => {
  //   if (prop === 'correctionFactors') {
  //     productivity.factorTotal = WorkCorrectionFactorList.filter((c) =>
  //       productivity.correctionFactors.some((f) => f === c.key)
  //     ).reduce((sum, c) => sum + (c.value || 0), 0);
  //   }
  //   setProductivity(productivity);
  // }, []);

  useEffect(() => {
    if (projects.length > 0) {
      // const productivity = projects[0].productivities.find((p) => p.id === params.productId);
      setProject(projects[0]);
      // setProductivity(productivity as HoleHammer);
    }
  }, [params.productId, projects]);

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([error, municipalitiesError]);
  }, [error, municipalitiesError, setErrors]);

  return (
    <MainFrame title="見積編集">
      {/* {mode === 'hours' ? (
        <HoleHammerComponent
          productivity={productivity}
          openCalculate
          onClickBack={handleClickBack}
          onClickConfirm={handleClickProductivity}
          updateProductivity={updateProductivity}
        />
      ) : ( */}
      <CreateComponent
        project={project}
        onClickConfirm={handleClickConfirm}
        onClickBack={handleClickBack}
        onUpdate={updateProject}
        prefectures={municipalities as Municipality[]}
      />
      {/* )} */}
    </MainFrame>
  );
};

export default Edit;
