import { FC, useCallback, useState } from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import Base, { TextFieldProps } from '@mui/material/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';

type Props = TextFieldProps & {
  name: string;
  // eslint-disable-next-line
  defaultValue?: any;
  // eslint-disable-next-line
  control?: Control<FieldValues, any>;
  show?: boolean;
};

const Password: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(props.show || false);
  const handleClickVisibility = useCallback(() => setShowPassword(!showPassword), [setShowPassword, showPassword]);
  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);
  const transform = (
    (tp?: string) =>
    (value: string): string | number => {
      if (tp !== 'number') {
        return value;
      }

      const output = parseFloat(value);
      return isNaN(output) ? 0 : output;
    }
  )(props.type);
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <Base
          {...props}
          {...field}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => field.onChange(transform(e.target.value))}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickVisibility}
                  onMouseDown={handleMouseDown}
                >
                  {showPassword ? (
                    <Visibility data-testid="visibility" />
                  ) : (
                    <VisibilityOff data-testid="visibility-off" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default Password;
