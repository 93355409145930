import React, { useCallback, useEffect, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainFrame from 'component/MainFrame';
import ItemizedStatementComponent from 'component/ItemizedStatement';
import { useSendProject, useFetchProjects } from 'usecase/Project';
import { Project } from 'model/Project';
import { Productivity } from 'model/Productivity';
import { useProjectState, useProjectMutators } from 'globalStates/Project';
import { Masters } from 'model/Master';
import { useFetchMaster } from 'usecase/Master';
import { useErrorsMutators } from 'globalStates/Errors';
import { EditableColumns } from 'component/ItemizedStatementDataGrid';
import { useFetchUsers } from 'usecase/User';

const ItemizedStatement: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { project } = useProjectState();
  const [setProject] = useProjectMutators();
  const saveProject = useSendProject();
  const [projects, projectsError] = useFetchProjects({ id });
  const [users, usersError] = useFetchUsers();
  const [, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const setErrors = useErrorsMutators();

  const handleClickNew = useCallback(() => navigate(`/productivities/new`), [navigate]);

  const handleClickCopy = useCallback(
    async (selectedItems: Productivity[]) => {
      try {
        const items =
          project?.productivities
            ?.filter((prod) => selectedItems.some((s) => s.id === prod.id))
            .map((item) => ({ ...item, id: 'dummy' })) || [];
        const newProductivities = [...(project?.productivities || []), ...items].map((productivity, n) => ({
          ...productivity,
          id: `${n}`,
          remark: `第${n + 1}号代価表`,
        }));
        const newProject = { ...project, productivities: newProductivities } as Project;
        const p = await saveProject(newProject);
        setProject(p);
      } catch (e) {
        console.error(e);
      }
    },
    [project, saveProject, setProject]
  );

  const handleClickDelete = useCallback(
    async (selectedItems: Productivity[]) => {
      try {
        const delIds = selectedItems.map((item) => item.id);
        const temp = project?.productivities.filter((prod) => prod.id && !delIds.includes(prod.id)) || [];
        const conversions: [{ prev: string; now: string }] = [{ prev: '', now: '' }];

        const productivities = temp.map((prod, n) => {
          conversions.push({ prev: prod.id || '', now: `${n}` });
          return { ...prod, id: `${n}`, remark: `第${n + 1}号代価表` };
        });
        const newProject = { ...project, productivities } as Project;
        const p = await saveProject(newProject);
        setProject(p);
      } catch (e) {
        console.error(e);
      }
    },
    [project, saveProject, setProject]
  );

  const handleClickExportReport = useCallback(
    (selectedItems: Productivity[], type: string) => {
      const ids = selectedItems.map((i) => i.id || '');
      window.open(`/reports/${type}?projectId=${project?.id}&prodId=${ids}`);
    },
    [project]
  );

  const handleClickExportItemizedReport = useCallback(() => {
    window.open(`/reports/itemized-statement?projectId=${project?.id}`);
  }, [project]);

  const handleSelectedItem = useCallback(
    (item: Productivity) => {
      navigate(`/projects/${project?.id}/${item.id}`);
    },
    [navigate, project]
  );

  const handleChangeRow = async (id: string, prop: EditableColumns, value: string | number) => {
    const targetProd = project?.productivities.find((p) => p.id === id);
    if (targetProd) {
      const newProductivities = project?.productivities.map((prod) => {
        if (prod.id === targetProd.id) {
          return { ...prod, [prop]: value };
        }
        return prod;
      });
      try {
        const newProject = { ...project, productivities: newProductivities || [] } as Project;
        await saveProject(newProject);
        setProject(newProject);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (!projects || projects.length === 0) {
      return;
    }
    setProject(projects[0]);
  }, [projects, setProject]);

  useEffect(() => {
    const error = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    error([projectsError, municipalitiesError, usersError]);
  }, [projectsError, municipalitiesError, setErrors, usersError]);

  return (
    <MainFrame title={project?.constractionName || ''}>
      <ItemizedStatementComponent
        items={project?.productivities || []}
        users={users}
        onClickNew={handleClickNew}
        onClickCopy={handleClickCopy}
        onClickDelete={handleClickDelete}
        onClickExportReport={handleClickExportReport}
        onClickExportItemizedReport={handleClickExportItemizedReport}
        onChangeRow={handleChangeRow}
        onSelectedItem={handleSelectedItem}
      />
    </MainFrame>
  );
};

export default ItemizedStatement;
