import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MainFrame from 'component/MainFrame';
import MunicipalityForm from 'component/Master/Municipality/Create';
import UsedMachineForm from 'component/Master/UsedMachine/Create';
import CreateFrame from 'component/Master/CreateFrame';
import { useNavigate, useParams } from 'react-router-dom';
import { Masters, MasterTypes, Municipality } from 'model/Master';
import { useSendMunicipality, useSendMachine, useFetchMaster } from 'usecase/Master';
import { useErrorsMutators } from 'globalStates/Errors';
import { EmptyMunicipality } from 'store/Municipality';
import { EmptyMachine } from 'store/Machine';
import { Machine } from 'model/Productivity';
import { useUserState } from 'globalStates/User';

const Edit: FC = () => {
  console.log('Edit');
  const navigate = useNavigate();
  const params = useParams();
  const userState = useUserState();
  const [fetchData, error] = useFetchMaster({
    collectionId: params.collectionId || '',
    documentId: params.documentId,
  });
  const sendMunicipality = useSendMunicipality();
  const sendMachine = useSendMachine();
  const [masterData, setMasterData] = useState<MasterTypes>();
  const setErrors = useErrorsMutators();

  const handleClickBack = () => {
    navigate(`/master/${params.collectionId}`);
  };

  const handleClickConfirm = useCallback(
    async (data: MasterTypes) => {
      const saveData = { ...data, updatedBy: userState.user?.id };
      try {
        if (params.collectionId === Masters.Machine) {
          await sendMachine(saveData as Machine);
        }
        if (params.collectionId === Masters.Municipality) {
          await sendMunicipality(saveData as Municipality);
        }

        navigate(`/master/${params.collectionId}`);
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      }
    },
    [navigate, params.collectionId, sendMachine, sendMunicipality, setErrors, userState.user?.id]
  );

  const handleUpdate = useCallback((data: MasterTypes) => setMasterData(data), []);

  useEffect(() => {
    if (error) {
      if (error instanceof Error) {
        setErrors({ code: 999, message: error.message });
      } else {
        setErrors({ code: 999, message: error as string });
      }
    }
  }, [error, setErrors]);

  useEffect(() => {
    setMasterData(fetchData.find((m) => m.id === params.documentId));
  }, [fetchData, params.collectionId, params.documentId]);

  const title = useMemo(() => {
    switch (params.collectionId) {
      case 'machine':
        return '使用機器管理';
      default:
        return '公共工事設計労務単価';
    }
  }, [params.collectionId]);

  const renderEditComponent = (cid?: string) => {
    switch (cid) {
      case 'municipality':
        return (
          <CreateFrame title="単価編集">
            <MunicipalityForm
              municipality={(masterData as Municipality) || EmptyMunicipality}
              onClickBack={handleClickBack}
              onClickConfirm={handleClickConfirm}
              onUpdate={handleUpdate}
            />
          </CreateFrame>
        );
      case 'machine':
        return (
          <CreateFrame title="使用機器編集">
            <UsedMachineForm
              machine={(masterData as Machine) || EmptyMachine}
              onClickBack={handleClickBack}
              onClickConfirm={handleClickConfirm}
              onUpdate={handleUpdate}
            />
          </CreateFrame>
        );
      default:
        return <div />;
    }
  };
  return <MainFrame title={title}>{renderEditComponent(params.collectionId)}</MainFrame>;
};

export default Edit;
