import { FC, useState, useEffect } from 'react';
import { Paper, Divider, Typography, Stack } from '@mui/material';
import { Carry } from 'model/Productivity/Carry';
import { round } from 'api/calc/Calc';
import { Municipality } from 'model/Master';
import { calcWorkExpenseRate, calcWorkProductionRateValue } from 'api/calc/Carry';

interface Props {
  productivity: Carry;
  municipality: Municipality;
  open: boolean;
}

interface ConstructionValue {
  quantity: number;
  gCECaretakerUnitPrice: number;
  gCECaretakerSubtotal: number;
  scaffoldWorkerUnitPrice: number;
  scaffoldWorkerSubtotal: number;
  specialWorkerUnitPrice: number;
  specialWorkerSubtotal: number;
  ordinaryWorkersUnitPrice: number;
  ordinaryWorkersSubtotal: number;
  subtotal: number;
  expenseRate: number;
  expense: number;
  total: number;
}

const CalculationResults: FC<Props> = ({ open, productivity, municipality }) => {
  const [value, setValue] = useState<ConstructionValue>({
    quantity: 0,
    gCECaretakerUnitPrice: 0,
    gCECaretakerSubtotal: 0,
    scaffoldWorkerUnitPrice: 0,
    scaffoldWorkerSubtotal: 0,
    specialWorkerUnitPrice: 0,
    specialWorkerSubtotal: 0,
    ordinaryWorkersUnitPrice: 0,
    ordinaryWorkersSubtotal: 0,
    subtotal: 0,
    expenseRate: 0,
    expense: 0,
    total: 0,
  });

  const multiplication = (a: number, b: number) => round(a * b, 0);
  useEffect(() => {
    const quantity = calcWorkProductionRateValue(productivity.work, productivity.injection);
    const gCECaretakerUnitPrice = municipality.generalCivilEngineeringCaretaker;
    const gCECaretakerSubtotal = multiplication(quantity, gCECaretakerUnitPrice);
    const scaffoldWorkerUnitPrice = municipality.scaffoldWorker;
    const scaffoldWorkerSubtotal = multiplication(quantity, scaffoldWorkerUnitPrice);
    const specialWorkerUnitPrice = municipality.specialWorker;
    const specialWorkerSubtotal = multiplication(quantity, specialWorkerUnitPrice);
    const ordinaryWorkersUnitPrice = municipality.ordinaryWorkers;
    const ordinaryWorkersSubtotal = multiplication(quantity, ordinaryWorkersUnitPrice);

    const subtotal = gCECaretakerSubtotal + scaffoldWorkerSubtotal + specialWorkerSubtotal + ordinaryWorkersSubtotal;
    const expenseRate = ((calcWorkExpenseRate(productivity.work, productivity.distance)?.value || 1) / 100) * 100;
    const expense = multiplication(expenseRate / 100, subtotal);

    const total = subtotal + expense;
    setValue({
      quantity,
      gCECaretakerUnitPrice,
      gCECaretakerSubtotal,
      scaffoldWorkerUnitPrice,
      scaffoldWorkerSubtotal,
      specialWorkerUnitPrice,
      specialWorkerSubtotal,
      ordinaryWorkersUnitPrice,
      ordinaryWorkersSubtotal,
      subtotal,
      expenseRate,
      expense,
      total,
    });
  }, [setValue, municipality, productivity.work, productivity.distance, productivity.injection]);
  if (!open) {
    return <div />;
  }
  return (
    <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, position: 'sticky', top: 0, maxWidth: 450 }}>
      <Stack spacing={2} m={2}>
        <div>
          <Typography variant="subtitle2">重機組立解体運搬費</Typography>
          <Typography variant="body2">数量 * 単価 = 金額</Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle2">世話役</Typography>
          <Typography variant="body2">
            {value.quantity} * {value.gCECaretakerUnitPrice.toLocaleString()} ={' '}
            {value.gCECaretakerSubtotal.toLocaleString()}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">とび工</Typography>
          <Typography variant="body2">
            {value.quantity} * {value.scaffoldWorkerUnitPrice.toLocaleString()} ={' '}
            {value.scaffoldWorkerSubtotal.toLocaleString()}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">特殊作業員</Typography>
          <Typography variant="body2">
            {value.quantity} * {value.specialWorkerUnitPrice.toLocaleString()} ={' '}
            {value.specialWorkerSubtotal.toLocaleString()}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">普通作業員</Typography>
          <Typography variant="body2">
            {value.quantity} * {value.ordinaryWorkersUnitPrice.toLocaleString()} ={' '}
            {value.ordinaryWorkersSubtotal.toLocaleString()}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">諸雑費</Typography>
          <Typography variant="body2">
            {value.expenseRate}% * {value.subtotal.toLocaleString()} = {value.expense.toLocaleString()}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">計</Typography>
          <Typography variant="body2">{value.total.toLocaleString()}</Typography>
        </div>
      </Stack>
    </Paper>
  );
};

export default CalculationResults;
