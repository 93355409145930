import { DocumentData } from 'firebase/firestore';

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
}

export const convertUser = (data: DocumentData): User => ({
  id: data.id,
  name: data.name,
  email: data.email,
  role: data.role || 'user',
});

export const convertUsers = (data: DocumentData): User[] => data.map(convertUser);
