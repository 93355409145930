import { Menu } from 'model/Menu';
import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

interface MenuState {
  menu: Menu[];
}
const menuState = atom<MenuState>({
  key: 'menu',
  default: {
    menu: [],
  },
});
export const useMenuState = () => useRecoilValue(menuState);

export const useMenuMutators = () => {
  const setState = useSetRecoilState(menuState);
  const setMenu = useCallback(
    (menu: Menu[]) => {
      return setState((previousState: MenuState) => {
        return {
          ...previousState,
          menu,
        };
      });
    },
    [setState]
  );

  return setMenu;
};
