import { FC, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid } from '@mui/material';
import { TextField } from 'component/TextField';
import { RentFeeCalculationNote } from 'model/RentFeeCalculation';

interface Props {
  note: RentFeeCalculationNote;
  onClickBack: () => void;
  onClickConfirm: (note: RentFeeCalculationNote) => void;
  onUpdate?: (note: RentFeeCalculationNote) => void;
}

const schema = z.object({
  // id: z.string().nullable(),
  no: z.string().trim().min(1),
  reference: z.string().trim().min(1),
  order: z.number().nonnegative(),
});

const CreateNote: FC<Props> = ({ note, onClickBack, onUpdate, onClickConfirm }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  console.log('error', errors);
  // NOTE:MUIとの連携のため、any型を使用
  // eslint-disable-next-line
  const parse = (data: any): RentFeeCalculationNote => ({ ...note, ...data });

  const onSubmit = useCallback(
    // NOTE:MUIとの連携のため、any型を使用
    // eslint-disable-next-line
    (data: any) => {
      onClickConfirm(parse(data));
    },
    [onClickConfirm, parse]
  );

  useEffect(() => {
    Object.entries(note).map(([name, value]) => setValue(name, value));
  }, [note, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parse(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, parse, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="no"
            name="no"
            label="No"
            variant="standard"
            defaultValue={note.no}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="reference"
            name="reference"
            label="備考内容"
            variant="standard"
            defaultValue={note.reference}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="order"
            name="order"
            label="並び順"
            variant="standard"
            type="number"
            defaultValue={note.order}
            control={control}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
          戻る
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
          確認
        </Button>
      </Box>
    </form>
  );
};

export default CreateNote;
