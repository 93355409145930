import { Municipality } from 'model/Master';
import { Auger, Soils } from 'model/Productivity/Auger';
import {
  AUGER_STANDARD_QUANTITY,
  CasingItems,
  DrivenTimeItems,
  InjectionTimeGroutItems,
  InstallationTimeFactors,
  InstallationTimeItems,
  NValuesPerDrillingDiameter,
  SandFillingTimeItems,
  SteelMaterialItems,
  SteelMaterialPreparationTimes,
} from 'store/Auger';
import { OtherExpensiveRate } from 'store/Productivity';
import { amountRoundUp, round } from './Calc';
import { WORK_TIME } from 'store/Master';
import { Machine } from 'model/Productivity';
import { createAugerUnitPriceList, createHydraulicAugerUnitPriceList } from './UnitPrice';

/**
 * T1準備時間
 * @param steelMaterial
 * @param injectionTimeGrout
 * @param casing
 * @param sandFillingTime
 * @returns
 */
export const calcAugerPreparationTime = (
  steelMaterial: string,
  injectionTimeGrout: string,
  casing: string,
  sandFillingTime: string
) => {
  const steelMaterialPreparationTime = (): number => {
    return SteelMaterialPreparationTimes.find((smt) => smt.value === steelMaterial)?.preparationTime || 0;
  };
  const injectionTimeGroutPreparationTime = (): number => {
    const injection = InjectionTimeGroutItems.find((itg) => itg.name === '注入なし')?.value;
    if (!injectionTimeGrout || injectionTimeGrout === injection) {
      return 0;
    }
    return 10;
  };
  const casingPreparationTime = CasingItems.find((c) => c.value === casing)?.preparationTime || 0;
  const sandFillingPreparationTime = calcSandFillingTime(sandFillingTime);
  return (
    steelMaterialPreparationTime() +
    injectionTimeGroutPreparationTime() +
    casingPreparationTime +
    sandFillingPreparationTime
  );
};

export const calcAugerScrewJointTime = (screwJointTimes: number) => {
  return 30 * screwJointTimes;
};
export const calcInjectionTimePerUnit = (injectionTimeGrout: string) => {
  return InjectionTimeGroutItems.find((itg) => itg.value === injectionTimeGrout)?.time || 0;
};
export const calcDrivenTimePerUnit = (drivenTime: string) => {
  return DrivenTimeItems.find((dt) => dt.value === drivenTime)?.time || 0;
};
export const calcInstallationTime = (installationTime: string, installationCount: number) => {
  const countPerMin = InstallationTimeItems.find((it) => it.value === installationTime)?.time;
  return (installationCount || 0) * (countPerMin || 0);
};
export const calcSandFillingTime = (sandFillingTime: string) => {
  return SandFillingTimeItems.find((sft) => sft.value === sandFillingTime)?.time || 0;
};

const calcSoilNValue = (drillingDiameter: string, soilN: string): number => {
  const nValues = NValuesPerDrillingDiameter.find((npdd) => npdd.drillingDiameter === drillingDiameter)?.nValues;
  return nValues?.find((n) => n.key === soilN)?.value || 0;
};

export const calcDrillingTime = (drillingDiameter: string, soils?: Soils[]) => {
  const result = soils?.map((soil) => calcSoilNValue(drillingDiameter, soil.nValue) * soil.value);
  let sum = 0;
  result?.map((r) => (sum += r));
  return sum;
};
export const calcAugerConstructionDaysPerUnit = (productivity: Auger): number => {
  const t1 = calcAugerPreparationTime(
    productivity.steelMaterial,
    productivity.injectionTimeGrout,
    productivity.casing,
    productivity.sandFillingTime
  );
  const t3 = calcInjectionTimePerUnit(productivity.injectionTimeGrout);
  const t4 = calcDrivenTimePerUnit(productivity.drivenTime);
  const t5 = calcInstallationTime(productivity.installationTime, productivity.installationCount);
  const t6 = calcSandFillingTime(productivity.sandFillingTime);
  const al = calcDrillingTime(productivity.drillingDiameter, productivity.soils);
  const t = WORK_TIME;
  const nw = calcAugerScrewJointTime(productivity.screwJointTimes);
  const t2 = round(al + nw, 2);
  const f = round(1 + productivity.factorTotal);
  const tc = round((t1 + t2 + t3 + t4 + t5 + t6) * f, 2);
  return round((1 * tc) / 60 / t, 3);
};

export const calcAugerUnit = (productivity: Auger, municipality: Municipality, machine: Machine): number => {
  const constructionDaysPerUnit = calcAugerConstructionDaysPerUnit(productivity);
  const expenses = OtherExpensiveRate.find((oer) => oer.workType === productivity.workType);
  const exitsPlantRate = expenses?.exitsPlant?.rate || 0;
  const noPlantRate = expenses?.noPlant?.rate || 0;
  const grout = calcInjectionTimePerUnit((productivity as Auger).injectionTimeGrout);
  const otherExpensesRate = grout === 0 ? noPlantRate : exitsPlantRate;
  const quantity = round(constructionDaysPerUnit * AUGER_STANDARD_QUANTITY);

  const multiplication = (a: number, b: number) => round(a * b, 0);

  const gCECaretakerSubtotal = multiplication(quantity, municipality.generalCivilEngineeringCaretaker);
  const scaffoldWorkerSubtotal = multiplication(quantity, municipality.scaffoldWorker);
  const specialWorkerSubtotal = multiplication(quantity, municipality.specialWorker);
  const ordinaryWorkersSubtotal = multiplication(quantity, municipality.ordinaryWorkers);

  const weldersQuantityFactor = () => {
    const item = InstallationTimeFactors.find((bdf) => bdf.key === (productivity as Auger).installationTime);
    if (item?.name.includes('溶接')) {
      return item.value;
    }
    return 0;
  };
  const weldersQuantity = round(quantity * weldersQuantityFactor());
  const weldersSubtotal = multiplication(weldersQuantity, municipality.welders);

  const usedMachineUnitPrice = () => {
    const noMaterial = SteelMaterialItems.find((sm) => sm.name === 'なし');
    if (noMaterial && productivity.steelMaterial === noMaterial.value) {
      // 鋼材が「なし」の場合
      return createHydraulicAugerUnitPriceList(municipality, machine).total || 0;
    }
    return createAugerUnitPriceList(municipality, machine).total || 0;
  };

  const pileDrivingSubtotal = multiplication(quantity, usedMachineUnitPrice());

  const subTotal =
    gCECaretakerSubtotal +
    scaffoldWorkerSubtotal +
    specialWorkerSubtotal +
    ordinaryWorkersSubtotal +
    weldersSubtotal +
    pileDrivingSubtotal;
  const otherExpensesPrice = round(subTotal * otherExpensesRate, 2);
  const beforeRoundTotal = subTotal + otherExpensesPrice;
  const total = amountRoundUp(beforeRoundTotal);
  const beforeRoundTotalPerUnit = Math.floor(total / AUGER_STANDARD_QUANTITY);
  return beforeRoundTotalPerUnit;
};
