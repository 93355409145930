import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FirstPriceListReport from 'component/Report/FirstPriceListReport';
import { Machine, Productivity } from 'model/Productivity';
import { Project } from 'model/Project';
import { HoleHammer } from 'model/Productivity/HoleHammer';
import { FirstPriceDetails, FirstPrices, UnitPrices, UsedMachinePrice } from 'model/FirstPriceList';
import { amountRoundUp, round } from 'api/calc/Calc';
import {
  calcInstallationDay,
  calcFillingDays,
  calcBondingDays,
  calcAirCompressorUnitPrice,
  calcHoleHammerUnitPrice,
} from 'api/calc/DownThe';
import { calcDaysOfChippingHoles } from 'api/calc/QualitySoil';
import { BondingDaysFactors, HoleHammerSpecifications, Cranes, AirCompressors } from 'store/HoleHammer';
import { BondingDaysItems, OtherExpensiveRate } from 'store/Productivity';
import { WorkType } from 'model/WorkType';
import { TypeIn } from 'model/Productivity/TypeIn';
import { calcConstructionDaysPerUnit } from 'api/calc/HammerIn';
import { BondingTimeItems } from 'store/TypeIn';
import { PullOut } from 'model/Productivity/PullOut';
import { calcPullOutConstructionDaysPerUnit } from 'api/calc/PullOut';
import { WeltingBoltMountingTimeItems } from 'store/PullOut';
import { Auger } from 'model/Productivity/Auger';
import { calcInjectionTimePerUnit, calcAugerConstructionDaysPerUnit } from 'api/calc/Auger';
import { InstallationTimeFactors, SteelMaterialItems } from 'store/Auger';
import { useFetchProjects } from 'usecase/Project';
import { useErrorsMutators } from 'globalStates/Errors';
import { useFetchMaster } from 'usecase/Master';
import { Masters, Municipality } from 'model/Master';
import { Carry } from 'model/Productivity/Carry';
import { calcWorkExpenseRate, calcWorkProductionRateValue } from 'api/calc/Carry';
import { calcCraneUnitPrice } from 'api/calc/Crane';
import {
  createAugerUnitPriceList,
  createDownTheDriveUnitPriceList,
  createFirstPriceReportData,
  createHydraulicAugerUnitPriceList,
  createHydraulicVibroUnitPriceList,
  findUnitPriceTitle,
} from 'api/calc/UnitPrice';
import { useFetchRentFeeCalculations } from 'usecase/RentFeeCalculation';
import { workContents } from 'store/Carry';
import { MACHINE_RX3300 } from 'store/Master';

const PriceList: FC = () => {
  const location = useLocation();
  const projectId = location.search.split('&')[0].replace('?projectId=', '');
  const [projects, projectsError] = useFetchProjects({ id: projectId });
  const [firstPrice, setFirstPrices] = useState<FirstPrices[]>();
  const [municipalities, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const [machines, machinesError] = useFetchMaster({ collectionId: Masters.Machine });
  const [rentFeeCalculations, rentFeeError] = useFetchRentFeeCalculations();
  const setErrors = useErrorsMutators();

  const calcHoleHammerQuantity = useCallback((prod: Productivity) => {
    const productivity = prod as HoleHammer;
    const t1 = calcDaysOfChippingHoles(productivity.qualitySoil);
    const t2 = calcInstallationDay(productivity.preparationDays, productivity.scrwJointTimes);
    const t3 = calcFillingDays(productivity.fillingDays);
    const t4 = calcBondingDays(productivity.bondingDays, productivity.joiningTimes);
    const a = Number(productivity.correctionFactorValue || 0);
    const f = round(1 + productivity.factorTotal);
    return round((t1 + t2 + t3 + t4 * a) * f);
  }, []);

  const calcAugerQuantity = useCallback((prod: Productivity) => {
    const productivity = prod as Auger;
    return calcAugerConstructionDaysPerUnit(productivity);
  }, []);

  const calcHammerInQuantity = useCallback((prod: Productivity) => {
    const productivity = prod as TypeIn;
    return calcConstructionDaysPerUnit(productivity);
  }, []);

  const calcPullOutQuantity = useCallback((prod: Productivity) => {
    const productivity = prod as PullOut;
    return calcPullOutConstructionDaysPerUnit(productivity);
  }, []);

  const calcCarryQuantity = useCallback((prod: Productivity) => {
    const productivity = prod as Carry;
    return calcWorkProductionRateValue(productivity.work, productivity.injection);
  }, []);

  const typeInWeldersQuantityFactor = useCallback((productivity: Productivity) => {
    const bondingTime = BondingTimeItems.find((bt) => bt.value === (productivity as TypeIn).bondingTime);
    if (bondingTime?.name === '溶接接合') {
      return 1;
    }
    return 0;
  }, []);
  const pullOutWeldersQuantityFactor = useCallback((productivity: Productivity) => {
    const isCutting =
      WeltingBoltMountingTimeItems.find((wbt) => wbt.name === '切断')?.value ===
      (productivity as PullOut).weltingBoltMountingTime;
    return isCutting ? 1 : 0;
  }, []);
  const holeHammerWeldersQuantityFactor = useCallback((productivity: Productivity) => {
    const item = BondingDaysItems.find((bdi) => (bdi.key = (productivity as HoleHammer).bondingDays));
    if (item?.name.includes('溶接')) {
      return BondingDaysFactors.find((bdf) => bdf.key === (productivity as HoleHammer).bondingDays)?.value || 0;
    }
    return 0;
  }, []);

  const augerWeldersQuantityFactor = useCallback((productivity: Productivity) => {
    const item = InstallationTimeFactors.find((bdf) => bdf.key === (productivity as Auger).installationTime);
    if (item?.name.includes('溶接')) {
      return item.value;
    }
    return 0;
  }, []);

  const typeInAndPullOutUsedMachineUnitPrice = useCallback(
    (productivity: Productivity, municipalities: Municipality, machine?: Machine): UsedMachinePrice => {
      if (!machine) {
        return {
          machine: MACHINE_RX3300,
          price: 0,
          remark: '',
          key: '-',
        };
      }
      const machineUnit = createHydraulicVibroUnitPriceList(municipalities, machine);
      return {
        machine: machine.key || MACHINE_RX3300,
        price: machineUnit.total,
        remark: machineUnit.title,
        key: machineUnit.key,
      };
    },
    []
  );

  const holeHammerUsedMachineUnitPrice = useCallback(
    (productivity: Productivity, municipality: Municipality, machine?: Machine): UsedMachinePrice => {
      if (!machine) {
        return {
          machine: MACHINE_RX3300,
          price: 0,
          remark: '',
          key: '-',
        };
      }
      const machineUnit = createDownTheDriveUnitPriceList(municipality, machine);
      return {
        machine: machine.key || MACHINE_RX3300,
        price: machineUnit.total,
        remark: machineUnit.title,
        key: machineUnit.key,
      };
    },
    []
  );

  const augerUsedMachineUnitPrice = useCallback(
    (productivity: Productivity, municipality: Municipality, machine?: Machine): UsedMachinePrice => {
      if (!machine) {
        return {
          machine: MACHINE_RX3300,
          price: 0,
          remark: '',
          key: '-',
        };
      }
      const noMaterial = SteelMaterialItems.find((sm) => sm.name === 'なし');
      if (noMaterial && (productivity as Auger).steelMaterial === noMaterial.value) {
        const machineUnit = createHydraulicAugerUnitPriceList(municipality, machine);
        return {
          machine: machine.key || MACHINE_RX3300,
          price: machineUnit.total,
          remark: machineUnit.title,
          key: machineUnit.key,
        };
      }
      const machineUnit = createAugerUnitPriceList(municipality, machine);
      return {
        machine: machine.key || MACHINE_RX3300,
        price: machineUnit.total,
        remark: machineUnit.title,
        key: machineUnit.key,
      };
    },
    []
  );

  const calcCreator = useCallback(
    (
      type: WorkType
    ): [
      (productivity: Productivity) => number,
      (productivity: Productivity) => number,
      (productivity: Productivity, municipality: Municipality, machine?: Machine) => UsedMachinePrice
    ] => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const emptyResult = (prod: Productivity) => 0;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const emptyUsedMachinePrice = (prod: Productivity): UsedMachinePrice => {
        return {
          machine: MACHINE_RX3300,
          price: 0,
          remark: '',
          key: '-',
        };
      };

      if (type === WorkType.HOLE_HAMMER) {
        return [calcHoleHammerQuantity, holeHammerWeldersQuantityFactor, holeHammerUsedMachineUnitPrice];
      }
      if (type === WorkType.AUGER) {
        return [calcAugerQuantity, augerWeldersQuantityFactor, augerUsedMachineUnitPrice];
      }
      if (type === WorkType.HAMMER_IN) {
        return [calcHammerInQuantity, typeInWeldersQuantityFactor, typeInAndPullOutUsedMachineUnitPrice];
      }
      if (type === WorkType.PULL_OUT) {
        return [calcPullOutQuantity, pullOutWeldersQuantityFactor, typeInAndPullOutUsedMachineUnitPrice];
      }
      if (type === WorkType.CARRY) {
        return [calcCarryQuantity, emptyResult, emptyUsedMachinePrice];
      }
      return [emptyResult, emptyResult, emptyUsedMachinePrice];
    },
    [
      calcHoleHammerQuantity,
      holeHammerWeldersQuantityFactor,
      holeHammerUsedMachineUnitPrice,
      calcAugerQuantity,
      augerWeldersQuantityFactor,
      augerUsedMachineUnitPrice,
      calcHammerInQuantity,
      typeInWeldersQuantityFactor,
      typeInAndPullOutUsedMachineUnitPrice,
      calcPullOutQuantity,
      pullOutWeldersQuantityFactor,
      calcCarryQuantity,
    ]
  );

  const createFirstPriceReport = useCallback(
    (project: Project, productivity: Productivity, unitPrices: UnitPrices[]): FirstPrices => {
      const municipality = municipalities.find((m) => m.value === project.targetPref) as Municipality;
      const [calcTc, calcWeldersQuantityFactor, calcUsedMachineUnitPrice] = calcCreator(productivity.workType);

      const tc = calcTc(productivity);
      const otherExpensesRate = () => {
        if (productivity.workType === WorkType.CARRY) {
          const carry = productivity as Carry;
          return (calcWorkExpenseRate(carry.work, carry.distance)?.value || 1) / 100;
        }
        const expenses = OtherExpensiveRate.find((oer) => oer.workType === productivity.workType);
        if (productivity.workType === WorkType.AUGER) {
          const exitsPlantRate = expenses?.exitsPlant?.rate || 0;
          const noPlantRate = expenses?.noPlant?.rate || 0;
          const grout = calcInjectionTimePerUnit((productivity as Auger).injectionTimeGrout);
          return grout === 0 ? noPlantRate : exitsPlantRate;
        }
        return expenses?.rate || 0;
      };

      const quantityByWorkType = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER || productivity.workType === WorkType.CARRY) {
          return 1;
        }
        return 10;
      };
      const quantity = round(tc * quantityByWorkType());

      const multiplication = (a: number, b: number) => round(a * b, 0);

      const gCECaretakerSubtotal = multiplication(quantity, municipality?.generalCivilEngineeringCaretaker || 0);
      const scaffoldWorkerSubtotal = multiplication(quantity, municipality?.scaffoldWorker || 0);
      const specialWorkerSubtotal = multiplication(quantity, municipality?.specialWorker || 0);
      const ordinaryWorkersSubtotal = multiplication(quantity, municipality?.ordinaryWorkers || 0);

      const weldersQuantityFactor = calcWeldersQuantityFactor(productivity);

      const weldersQuantity = () => {
        return round(quantity * weldersQuantityFactor);
      };
      const weldersSubtotal = multiplication(weldersQuantity(), municipality?.welders || 0);
      const usedMachine = machines.find((mi) => mi.key === productivity.usedMachine) as Machine;
      const usedMachineUnitPrice = calcUsedMachineUnitPrice(productivity, municipality, usedMachine);

      const pileDrivingSubtotal = multiplication(quantity, usedMachineUnitPrice.price);

      const airCompressor7_5 = AirCompressors.find((acp) => acp.key === '7_5m3');
      const airCompressor18 = AirCompressors.find((acp) => acp.key === '18m3');
      const airCompressor7_5Quantity = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return (productivity as HoleHammer).airCompressor7_5 * quantity;
        }
        return 0;
      };
      const airCompressor7_5UnitPrice = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return calcAirCompressorUnitPrice(municipality, airCompressor7_5);
        }
        return 0;
      };
      const airCompressor7_5OperationSubtotal = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return multiplication(airCompressor7_5UnitPrice(), airCompressor7_5Quantity());
        }
        return 0;
      };
      const airCompressor18Quantity = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return (productivity as HoleHammer).airCompressor18 * quantity;
        }
        return 0;
      };
      const airCompressor18UnitPrice = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return calcAirCompressorUnitPrice(municipality, airCompressor18);
        }
        return 0;
      };
      const airCompressor18OperationSubtotal = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return multiplication(airCompressor18UnitPrice(), airCompressor18Quantity());
        }
        return 0;
      };

      const holeHammerSpecification = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return HoleHammerSpecifications.find((hs) => hs.drivenPile === (productivity as HoleHammer).drivenPile);
        }
        return { drivenPile: '', specification: { label: '', min: 0, max: 0 } };
      };
      const holeHammerUnitPrice = () => {
        if (productivity.workType !== WorkType.HOLE_HAMMER) {
          return 0;
        }
        return calcHoleHammerUnitPrice(productivity as HoleHammer, rentFeeCalculations, holeHammerSpecification());
      };
      const holeHammerSubtotal = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return multiplication(quantity, holeHammerUnitPrice());
        }
        return 0;
      };

      const crane = Cranes.find((item) => item.key === (productivity as HoleHammer).crane);
      const craneUnitPrice = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return calcCraneUnitPrice(municipality, crane);
        }
        return 0;
      };
      const craneSubTotal = () => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return multiplication(quantity, craneUnitPrice());
        }
        return 0;
      };

      const subTotal =
        gCECaretakerSubtotal +
        scaffoldWorkerSubtotal +
        specialWorkerSubtotal +
        ordinaryWorkersSubtotal +
        weldersSubtotal +
        pileDrivingSubtotal +
        airCompressor7_5OperationSubtotal() +
        airCompressor18OperationSubtotal() +
        holeHammerSubtotal() +
        craneSubTotal();
      const otherExpensesPrice = multiplication(subTotal, otherExpensesRate());
      const beforeRoundTotal = subTotal + otherExpensesPrice;
      const total = () => {
        if (productivity.workType === WorkType.CARRY) {
          return beforeRoundTotal;
        }
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return round(beforeRoundTotal / 100, 0) * 100;
        }
        return amountRoundUp(beforeRoundTotal);
      };

      const details = (): FirstPriceDetails[] => {
        if (productivity.workType === WorkType.HOLE_HAMMER) {
          return [
            {
              name: '土木一般世話役',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.generalCivilEngineeringCaretaker,
              subtotal: gCECaretakerSubtotal,
              remark: '',
            },
            {
              name: 'とび工',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.scaffoldWorker,
              subtotal: scaffoldWorkerSubtotal,
              remark: '',
            },
            {
              name: '特殊作業員',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.specialWorker,
              subtotal: specialWorkerSubtotal,
              remark: '',
            },
            {
              name: '普通作業員',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.ordinaryWorkers,
              subtotal: ordinaryWorkersSubtotal,
              remark: '',
            },
            {
              name: '溶接工',
              specification: '',
              unit: '人',
              quantity: weldersQuantity(),
              unitPrice: municipality?.welders,
              subtotal: weldersSubtotal,
              remark: '',
            },
            {
              name: '杭打ち機運転',
              specification: usedMachine?.name || '',
              unit: '日',
              quantity,
              unitPrice: usedMachineUnitPrice.price,
              subtotal: pileDrivingSubtotal,
              remark: findUnitPriceTitle(usedMachineUnitPrice.key, unitPrices),
            },
            {
              name: '空気圧縮機運転',
              specification: '7.5～7.6m3/min',
              unit: '日',
              quantity: airCompressor7_5Quantity(),
              unitPrice: airCompressor7_5UnitPrice() || 0,
              subtotal: airCompressor7_5OperationSubtotal(),
              remark: findUnitPriceTitle('hole_hammer_air_compressor_7_5m3', unitPrices),
            },
            {
              name: '空気圧縮機運転',
              specification: '18～19m3/min',
              unit: '日',
              quantity: airCompressor18Quantity(),
              unitPrice: airCompressor18UnitPrice() || 0,
              subtotal: airCompressor18OperationSubtotal(),
              remark: findUnitPriceTitle('hole_hammer_air_compressor_18m3', unitPrices),
            },
            {
              name: 'ﾀﾞｳﾝｻﾞﾎｰﾙﾊﾝﾏ損料',
              specification: holeHammerSpecification()?.specification.label || '',
              unit: '日',
              quantity,
              unitPrice: holeHammerUnitPrice(),
              subtotal: holeHammerSubtotal(),
              remark: '',
            },
            {
              name: 'ラフテレーンクレーン運転',
              specification: crane?.name || '',
              unit: '日',
              quantity,
              unitPrice: craneUnitPrice(),
              subtotal: craneSubTotal(),
              remark: '',
            },
          ];
        }
        if (productivity.workType === WorkType.CARRY) {
          return [
            {
              name: '土木一般世話役',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.generalCivilEngineeringCaretaker,
              subtotal: gCECaretakerSubtotal,
              remark: '',
            },
            {
              name: 'とび工',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.scaffoldWorker,
              subtotal: scaffoldWorkerSubtotal,
              remark: '',
            },
            {
              name: '特殊作業員',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.specialWorker,
              subtotal: specialWorkerSubtotal,
              remark: '',
            },
            {
              name: '普通作業員',
              specification: '',
              unit: '人',
              quantity,
              unitPrice: municipality?.ordinaryWorkers,
              subtotal: ordinaryWorkersSubtotal,
              remark: '',
            },
          ];
        }
        return [
          {
            name: '土木一般世話役',
            specification: '',
            unit: '人',
            quantity,
            unitPrice: municipality?.generalCivilEngineeringCaretaker,
            subtotal: gCECaretakerSubtotal,
            remark: '',
          },
          {
            name: 'とび工',
            specification: '',
            unit: '人',
            quantity,
            unitPrice: municipality?.scaffoldWorker,
            subtotal: scaffoldWorkerSubtotal,
            remark: '',
          },
          {
            name: '特殊作業員',
            specification: '',
            unit: '人',
            quantity,
            unitPrice: municipality?.specialWorker,
            subtotal: specialWorkerSubtotal,
            remark: '',
          },
          {
            name: '普通作業員',
            specification: '',
            unit: '人',
            quantity,
            unitPrice: municipality?.ordinaryWorkers,
            subtotal: ordinaryWorkersSubtotal,
            remark: '',
          },
          {
            name: '溶接工',
            specification: '',
            unit: '人',
            quantity: weldersQuantity(),
            unitPrice: municipality?.welders,
            subtotal: weldersSubtotal,
            remark: '',
          },
          {
            name: '杭打ち機運転',
            specification: usedMachine?.name || '',
            unit: '日',
            quantity,
            unitPrice: usedMachineUnitPrice.price,
            subtotal: pileDrivingSubtotal,
            remark: findUnitPriceTitle(usedMachineUnitPrice.key, unitPrices),
          },
        ];
      };

      const reportType = () => {
        if (productivity.workType === WorkType.CARRY && 'work' in productivity) {
          const type = workContents.find((wc) => wc.key == productivity.work)?.name || '';
          return `重機組立解体運搬費 ${type}`;
        }
        return '';
      };

      return {
        details: details(),
        incidentals: {
          name: '諸雑費',
          specification: '',
          unit: '%',
          quantity: round(otherExpensesRate() * 100),
          subtotal: total() - subTotal,
          remark: '上記計*率　まるめ',
        },
        total: total(),
        pricePerUnit: Math.floor(total() / quantityByWorkType()),
        unit: productivity.unit || '式',
        standard: quantityByWorkType(),
        productName: productivity.name,
        specification: productivity.specification || '',
        title: `第${parseInt(productivity.id || '0') + 1}号代価表`, // FIXME: 本来productivityにidは存在するが、現状任意指定していたため初期値1を指定してます
        reportType: reportType(),
      };
    },
    [calcCreator, machines, municipalities, rentFeeCalculations]
  );

  useEffect(() => {
    if (!projects || projects.length === 0) {
      return;
    }
    const project = projects[0];
    if (!project) {
      return;
    }
    if (
      !machines ||
      machines.length === 0 ||
      !municipalities ||
      municipalities.length === 0 ||
      !rentFeeCalculations ||
      rentFeeCalculations.length === 0
    )
      return;
    const productIds = location.search.split('&')[1].replace('prodId=', '').split(',');
    const productivities = project.productivities.filter((p) => productIds.includes(p.id || ''));
    const unitPrices = createFirstPriceReportData(
      municipalities,
      machines,
      AirCompressors,
      Cranes,
      project,
      productivities
    );
    setFirstPrices(productivities.map((prod) => createFirstPriceReport(project, prod, unitPrices)));
  }, [createFirstPriceReport, location.search, machines, municipalities, projects, rentFeeCalculations]);

  useEffect(() => {
    const error = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    error([projectsError, municipalitiesError, machinesError, rentFeeError]);
  }, [projectsError, municipalitiesError, machinesError, setErrors, rentFeeError]);

  return (
    <div>
      {firstPrice &&
        firstPrice.map((item, index) => {
          return (
            <FirstPriceListReport
              key={index}
              data={item}
              title={item.title}
              productName={item.productName}
              specification={item.specification}
            />
          );
        })}
    </div>
  );
};

export default PriceList;
