const HOLE_HAMMER = 'hole-hammer';
const AUGER = 'auger';
const PULL_OUT = 'pull-out';
const HAMMER_IN = 'hammer-in';
const CARRY = 'carry';

export const WorkType = {
  HOLE_HAMMER,
  AUGER,
  PULL_OUT,
  HAMMER_IN,
  CARRY,
} as const;

export type WorkType = (typeof WorkType)[keyof typeof WorkType];
