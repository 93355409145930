import { UsedMachinePrice } from 'model/FirstPriceList';
import { Crane, MasterData } from 'model/Master';
import { WorkCorrectionCategory, WorkCorrectionFactor } from 'model/Productivity';
import { AirCompressor, HoleHammer, HoleHammerSpecification } from 'model/Productivity/HoleHammer';
import { EmptyQualitySoil } from 'store/Productivity';

export const EmptyHoleHammer: HoleHammer = {
  projectId: '',
  name: '',
  usedMachine: '',
  workType: 'hole-hammer',
  specification: '',
  unit: '本',
  quantity: 1,
  unitPrice: 2620230,
  subtotal: 0,
  remark: '',
  drivenPile: '',
  drivenPileLength: 0,
  scrwJointTimes: 0,
  scrwJointTimesSelect: '0',
  drillingHoleLength: {
    value: 0,
  },
  drillingDiameter: 0,
  airCompressor7_5: 0,
  airCompressor18: 0,
  preparationDays: '',
  fillingDays: '',
  bondingDays: '',
  joiningTimes: 0,
  joiningTimesSelect: '0',
  correctionFactor: '',
  correctionFactorValue: 0,
  injectionMaterial: '',
  injectionRate: 0,
  injectionVolume: 0,
  injectionCastingLength: 0,
  totalQualitySoil: 0,
  qualitySoil: EmptyQualitySoil,
  factorTotal: 0,
  correctionFactors: [],
  otherFactor: {
    name: '',
    value: 0,
  },
  crane: '',
};

export const WorkCorrectionFactorCategory: WorkCorrectionCategory[] = [
  {
    key: 'none',
    name: '',
  },
  {
    key: 'construction-quantity',
    name: '施工数量',
  },
  {
    key: 'drilling-length',
    name: '削孔長',
  },
  {
    key: 'bit',
    name: 'ビット',
  },
  {
    key: 'work-constraints',
    name: '作業制約',
  },
  {
    key: 'work-conditions',
    name: '作業条件',
  },
];

export const WorkCorrectionFactorList: WorkCorrectionFactor[] = [
  {
    key: 'none',
    category: 'none',
    name: '削孔のみ・建込なし',
    value: -0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_20',
    category: 'construction-quantity',
    name: '20本以下',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_10',
    category: 'construction-quantity',
    name: '10本以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_5',
    category: 'construction-quantity',
    name: '5本以下',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'drilling_hole_length_6',
    category: 'drilling-length',
    name: '6.0ｍ以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'drilling_hole_length_12',
    category: 'drilling-length',
    name: '12m超え18m未満',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'drilling_hole_length_18',
    category: 'drilling-length',
    name: '18m以上',
    value: 0.4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'expanded_diameter_bit',
    category: 'bit',
    name: '拡径ビット',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'cycle_hanmer',
    category: 'bit',
    name: 'サイクルハンマ-',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'night_work',
    category: 'work-constraints',
    name: '夜間作業',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'work_restrictions',
    category: 'work-constraints',
    name: '作業制限',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'one_way',
    category: 'work-constraints',
    name: '片側通行',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'time_limit',
    category: 'work-constraints',
    name: '時間制限',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'narrow',
    category: 'work-constraints',
    name: '狭隘',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'airspace_limitations',
    category: 'work-constraints',
    name: '上空制限',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'sloping_land',
    category: 'work-constraints',
    name: '傾斜地',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'casing',
    category: 'work-conditions',
    name: 'ケーシング使用',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'rever',
    category: 'work-conditions',
    name: '河川内',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'rules',
    category: 'work-conditions',
    name: '支持杭及び定規設置',
    value: 0.3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const HoleHammerSpecifications: HoleHammerSpecification[] = [
  { drivenPile: 'H-150', specification: { label: '250〜300', min: 250, max: 300 } },
  { drivenPile: 'H-200', specification: { label: '382～457', min: 382, max: 457 } },
  { drivenPile: 'H-250', specification: { label: '382～457', min: 382, max: 457 } },
  { drivenPile: 'H-300', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'H-350', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'H-400', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'SP_3', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'SP_4', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'SP_6', specification: { label: '508～762', min: 508, max: 762 } }, // FIXME: PR686 ダウンザホールハンマ規格
  { drivenPile: '216_3', specification: { label: '250～300', min: 250, max: 300 } },
  { drivenPile: '267_4', specification: { label: '302～381', min: 302, max: 381 } },
  { drivenPile: '318_5', specification: { label: '382～457', min: 382, max: 457 } },
  { drivenPile: '406_4', specification: { label: '508～762', min: 508, max: 762 } },
  { drivenPile: 'manual', specification: { label: '', min: 0, max: 0 } },
];

export const EmptyMachinePrice: UsedMachinePrice = {
  key: '-',
  machine: '',
  remark: '',
  price: 0,
};

export const BondingDaysFactors = [
  {
    key: '-',
    value: 0,
  },
  {
    key: '150',
    value: 1,
  },
  {
    key: '200',
    value: 1,
  },
  {
    key: '250',
    value: 1,
  },
  {
    key: '300',
    value: 1,
  },
  {
    key: '350',
    value: 1,
  },
  {
    key: '400',
    value: 1,
  },
  {
    key: 'type2',
    value: 1,
  },
  {
    key: 'type3',
    value: 1,
  },
  {
    key: 'type4',
    value: 1,
  },
  {
    key: 'type5',
    value: 1,
  },
  {
    key: 'type2w',
    value: 1,
  },
  {
    key: 'type3w',
    value: 1,
  },
  {
    key: 'type4w',
    value: 1,
  },
  {
    key: '150j',
    value: 0,
  },
  {
    key: '200j',
    value: 0,
  },
  {
    key: '250j',
    value: 0,
  },
  {
    key: '300j',
    value: 0,
  },
  {
    key: '350j',
    value: 0,
  },
  {
    key: '400j',
    value: 0,
  },
  {
    key: '170i',
    value: 0,
  },
  {
    key: '300i',
    value: 0,
  },
  {
    key: '400i',
    value: 0,
  },
  {
    key: '500i',
    value: 0,
  },
];

export const CraneItems: MasterData[] = [
  {
    id: 'none',
    key: 'none',
    name: 'なし',
    value: 'none',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    updatedBy: '',
  },
  {
    id: '25t',
    key: '25t',
    name: '25t吊',
    value: '25t',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    updatedBy: '',
  },
  {
    id: '16t',
    key: '16t',
    name: '16t吊',
    value: '16t',
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    updatedBy: '',
  },
];

export const Cranes: Crane[] = [
  {
    quantity: 1.02,
    requiredOilAmount: 102,
    basePrice: 32700,
    name: '25t吊',
    value: '25t',
    key: '25t',
  },
  {
    quantity: 1.02,
    requiredOilAmount: 84,
    basePrice: 27900,
    name: '16t吊',
    value: '16t',
    key: '16t',
  },
];

export const AirCompressors: AirCompressor[] = [
  {
    key: '7_5m3',
    value: '7_5m3',
    name: '7.5～7.6m3/min',
    quantity: 1.33,
    unitPrice: 4710,
    requiredOilAmount: 66,
  },
  {
    key: '18m3',
    value: '18m3',
    name: '18～19m3/min',
    quantity: 1.33,
    unitPrice: 8480,
    requiredOilAmount: 156,
  },
];

export const AirCompressorInitialValues = [
  {
    drivenPile: 'H-150',
    airCompressor7_5: 1,
    airCompressor18: 1,
  },
  {
    drivenPile: 'H-200',
    airCompressor7_5: 0,
    airCompressor18: 3,
  },
  {
    drivenPile: 'H-250',
    airCompressor7_5: 0,
    airCompressor18: 2,
  },
  {
    drivenPile: 'H-300',
    airCompressor7_5: 0,
    airCompressor18: 3,
  },
  {
    drivenPile: 'H-350',
    airCompressor7_5: 1,
    airCompressor18: 3,
  },
  {
    drivenPile: 'H-400',
    airCompressor7_5: 0,
    airCompressor18: 4,
  },
  {
    drivenPile: 'SP_3',
    airCompressor7_5: 0,
    airCompressor18: 4,
  },
  {
    drivenPile: 'SP_4',
    airCompressor7_5: 0,
    airCompressor18: 4,
  },
  {
    // FIXME: PR686 値の修正 空気圧縮機の台数
    drivenPile: 'SP_6',
    airCompressor7_5: 0,
    airCompressor18: 4,
  },
  {
    drivenPile: '216_3',
    airCompressor7_5: 0,
    airCompressor18: 2,
  },
  {
    drivenPile: '267_4',
    airCompressor7_5: 0,
    airCompressor18: 2,
  },
  {
    drivenPile: '318_5',
    airCompressor7_5: 0,
    airCompressor18: 2,
  },
  {
    drivenPile: '406_4',
    airCompressor7_5: 0,
    airCompressor18: 3,
  },
  {
    drivenPile: '550',
    airCompressor7_5: 1,
    airCompressor18: 3,
  },
  {
    drivenPile: 'manual',
    airCompressor7_5: 0,
    airCompressor18: 0,
  },
];
