import { useState, FC, useEffect } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import { DataGrid } from 'component/DataGrid';
import { GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import { Municipality } from 'model/Master';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { Machine } from 'model/Productivity';
import { RentFeeCalculation, RentFeeCalculationNote } from 'model/RentFeeCalculation';

export type TableType = RentFeeCalculation | Municipality | Machine | RentFeeCalculationNote;
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: GridColumns<any>;
  initialState?: GridInitialStateCommunity;
  data: TableType[];
  reportButton?: string;
  fiscalYear?: string;
  onClickNew?: () => void;
  onClickCopy?: (selectItem: TableType[]) => void;
  onClickDelete?: (selectItem: TableType[]) => void;
  onClickReload?: () => void;
  onClickReport?: () => void;
  onChangeYear?: (year: string) => void;
  onSaveYear?: () => void;
}

const DataList: FC<Props> = ({
  columns,
  initialState,
  data,
  fiscalYear,
  onChangeYear,
  onClickNew,
  onClickCopy,
  onClickDelete,
  onClickReload,
  reportButton,
  onClickReport,
  onSaveYear,
}) => {
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [enableButton, setEnableButton] = useState(false);
  const handleCheck = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };
  const handleCopy = () => {
    onClickCopy && onClickCopy(data.filter((d) => selectionModel.includes(d.id || '')));
    setSelectionModel([]);
  };
  const handleDelete = () => {
    onClickDelete && onClickDelete(data.filter((d) => selectionModel.includes(d.id || '')));
    setSelectionModel([]);
  };
  useEffect(() => setEnableButton(selectionModel && selectionModel.length > 0), [selectionModel]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {onClickNew && (
          <Button variant="contained" onClick={onClickNew}>
            新規作成
          </Button>
        )}
        {onClickCopy && (
          <Button variant="contained" sx={{ ml: 1 }} onClick={handleCopy} disabled={!enableButton}>
            コピー
          </Button>
        )}
        {onClickDelete && (
          <Button variant="contained" color="error" sx={{ ml: 1 }} onClick={handleDelete} disabled={!enableButton}>
            削除
          </Button>
        )}
        {onClickReload && (
          <Button variant="contained" sx={{ ml: 1 }} onClick={onClickReload}>
            更新
          </Button>
        )}
        {onClickReport && (
          <Button variant="contained" sx={{ ml: 1 }} onClick={onClickReport}>
            {reportButton}
          </Button>
        )}
        {onSaveYear && (
          <>
            <TextField
              required
              id="email"
              name="email"
              label="年度"
              variant="standard"
              value={fiscalYear}
              onChange={(e) => onChangeYear && onChangeYear(e.target.value)}
              sx={{ ml: 1 }}
            />
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              onClick={onSaveYear}
              disabled={!(fiscalYear && fiscalYear?.length > 0 && fiscalYear?.length < 20)}
            >
              年度を保存
            </Button>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          columns={columns}
          initialState={initialState}
          data={data}
          selectionModel={selectionModel}
          setSelectionModel={handleCheck}
        />
      </Grid>
    </Grid>
  );
};

export default DataList;
