import { useEffect, useState, FC } from 'react';
import { Paper, Divider, Typography, Stack } from '@mui/material';
import { HoleHammer } from 'model/Productivity/HoleHammer';
import { calcDaysOfChippingHoles } from 'api/calc/QualitySoil';
import { calcBondingDays, calcFillingDays, calcInstallationDay } from 'api/calc/DownThe';
import { round } from 'api/calc/Calc';

interface Props {
  productivity: HoleHammer;
  open: boolean;
}

interface ConstructionValue {
  tc: number;
  t1: number;
  t2: number;
  t3: number;
  t4: number;
  a: number;
  f: number;
}

const CalculationResults: FC<Props> = ({ productivity, open }) => {
  const [value, setValue] = useState<ConstructionValue>({
    tc: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
    a: 0,
    f: 0,
  });
  useEffect(() => {
    const t1 = round(calcDaysOfChippingHoles(productivity.qualitySoil));
    const t2 = round(calcInstallationDay(productivity.preparationDays, productivity.scrwJointTimes));
    const t3 = round(calcFillingDays(productivity.fillingDays));
    const t4 = round(calcBondingDays(productivity.bondingDays, productivity.joiningTimes));
    const a = productivity.correctionFactorValue;
    const f = round(1 + productivity.factorTotal);
    const tc = round((t1 + t2 + t3 + t4 * a) * f);
    setValue({ tc, t1, t2, t3, t4, a, f });
  }, [productivity, setValue]);
  if (!open) {
    return <div />;
  }
  return (
    <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, position: 'sticky', top: 0, maxWidth: 450 }}>
      <Stack spacing={2} m={2}>
        <div>
          <Typography variant="subtitle1">鋼管杭・H鋼・鋼矢板</Typography>
          <Typography variant="subtitle2">{'Tc=(T1 + T2 + T3 + ( T4 * α )) * F'}</Typography>
        </div>
        <div>
          <Typography variant="subtitle1">防護柵</Typography>
          <Typography variant="subtitle2">Tc=(T1 + T2 + T3) * F</Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle2">Tc:杭1本当たり施工日数</Typography>
          <Typography variant="body2" data-testid="label tc">
            {value.tc} 日/本
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T1:杭1本当たり削孔日数</Typography>
          <Typography variant="body2" data-testid="label t1">
            {value.t1} 日/本
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2:杭1本当たり準備・建込み等日数</Typography>
          <Typography variant="body2" data-testid="label t2">
            {value.t2} 日/本
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T3:杭1本当たり充填日数</Typography>
          <Typography variant="body2" data-testid="label t3">
            {value.t3} 日/本
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T4:杭1本当たり溶接・ボルト接合日数</Typography>
          <Typography variant="body2" data-testid="label t4">
            {value.t4} 日/本
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">α:鋼管厚板補正係数（H鋼 鋼矢板は係数:1）</Typography>
          <Typography variant="body2" data-testid="label a">
            {value.a}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">F:作業係数</Typography>
          <Typography variant="subtitle2">標準を1.0とするが、作業条件等により係数を考慮する</Typography>
          <Typography variant="body2" data-testid="label f">
            {value.f}
          </Typography>
        </div>
      </Stack>
    </Paper>
  );
};

export default CalculationResults;
