import { UnitPriceKeys, UnitPrices } from 'model/FirstPriceList';
import { Crane, MasterTypes, Municipality } from 'model/Master';
import { Machine, Productivity } from 'model/Productivity';
import { hydraulicVibroHammer, hydraulicAuger, WORK_TIME, MACHINE_RX3300, MACHINE_RX2300 } from 'store/Master';
import { round, amountRoundUp } from './Calc';
import { AirCompressor, HoleHammer } from 'model/Productivity/HoleHammer';
import { Project } from 'model/Project';
import { WorkType } from 'model/WorkType';

export const calcHydraulicVibroHammerUnitPrice = (machine: Machine) => {
  return hydraulicVibroHammer.find((hvh) => machine.key === hvh.key)?.rentFee || 0;
};

export const calcHydraulicAugerUnitPrice = (machine: Machine) => {
  return hydraulicAuger.find((ha) => machine.key === ha.key)?.rentFee || 0;
};

/**
 * 単価ｵｰｶﾞ and ﾊﾞｲﾌﾞﾛ の第1号,2号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createAugerUnitPriceList = (municipality: Municipality, machine: Machine): UnitPrices => {
  const title = () => {
    if (machine.key === MACHINE_RX3300) {
      return '第1号単価表';
    }
    if (machine.key === MACHINE_RX2300) {
      return '第2号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (machine.key === MACHINE_RX3300) {
      return 'auger_rx3300';
    }
    if (machine.key === MACHINE_RX2300) {
      return 'auger_rx2300';
    }
    return '-';
  };

  const specialDriverQuantity = 1;
  const specialDriverUnitPrice = municipality.specialDriver;
  const specialDriverSubtotal = round(specialDriverUnitPrice * specialDriverQuantity, 0);

  const oilQuantity = machine.requiredOilAmount * WORK_TIME;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const machineQuantity = 1.7;
  const machineUnitPrice = machine.rentFee;
  const machineSubtotal = round(machineQuantity * machineUnitPrice, 0);

  const hydraulicAugerQuantity = 1.56;
  const hydraulicAugerUnitPrice = calcHydraulicAugerUnitPrice(machine);
  const hydraulicAugerSubtotal = round(hydraulicAugerUnitPrice * hydraulicAugerQuantity, 0);

  const hydraulicVibroHammerQuantity = 1.5;
  const hydraulicVibroHammerUnitPrice = calcHydraulicVibroHammerUnitPrice(machine);
  const hydraulicVibroHammerSubtotal = round(hydraulicVibroHammerUnitPrice * hydraulicVibroHammerQuantity, 0);

  const total =
    specialDriverSubtotal + oilSubtotal + machineSubtotal + hydraulicVibroHammerSubtotal + hydraulicAugerSubtotal;
  const roundTotal = amountRoundUp(total);
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '特殊運転手',
        specification: '',
        unit: '人',
        quantity: specialDriverQuantity,
        unitPrice: specialDriverUnitPrice,
        subtotal: specialDriverSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
      {
        name: '杭打機損料',
        specification: machine.name,
        unit: '人',
        quantity: machineQuantity,
        unitPrice: machineUnitPrice,
        subtotal: machineSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧オーガ',
        unit: '人',
        quantity: hydraulicAugerQuantity,
        unitPrice: hydraulicAugerUnitPrice,
        subtotal: hydraulicAugerSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧ﾊﾞｲﾌﾞﾛﾊﾝﾏ',
        unit: '人',
        quantity: hydraulicVibroHammerQuantity,
        unitPrice: hydraulicVibroHammerUnitPrice,
        subtotal: hydraulicVibroHammerSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: '杭打ち機運転',
    specification: machine.name + '　油圧オーガ・油圧バイブロ',
    title: title(),
    reportType: '',
    key: key(),
  };
};

/**
 * 単価ｵｰｶﾞ and ﾊﾞｲﾌﾞﾛ の第3号,4号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createHydraulicVibroUnitPriceList = (municipality: Municipality, machine: Machine): UnitPrices => {
  const title = () => {
    if (machine.key === MACHINE_RX3300) {
      return '第3号単価表';
    }
    if (machine.key === MACHINE_RX2300) {
      return '第4号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (machine.key === MACHINE_RX3300) {
      return 'auger_hydraulic_vibro_rx3300';
    }
    if (machine.key === MACHINE_RX2300) {
      return 'auger_hydraulic_vibro_rx2300';
    }
    return '-';
  };

  const specialDriverQuantity = 1;
  const specialDriverUnitPrice = municipality.specialDriver;
  const specialDriverSubtotal = round(specialDriverUnitPrice * specialDriverQuantity, 0);

  const oilQuantity = machine.requiredOilAmount * WORK_TIME;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const machineQuantity = 1.7;
  const machineUnitPrice = machine.rentFee;
  const machineSubtotal = round(machineQuantity * machineUnitPrice, 0);

  const hydraulicVibroHammerQuantity = 1.5;
  const hydraulicVibroHammerUnitPrice = calcHydraulicVibroHammerUnitPrice(machine);
  const hydraulicVibroHammerSubtotal = round(hydraulicVibroHammerUnitPrice * hydraulicVibroHammerQuantity, 0);

  const total = specialDriverSubtotal + oilSubtotal + machineSubtotal + hydraulicVibroHammerSubtotal;
  const roundTotal = amountRoundUp(total);
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '特殊運転手',
        specification: '',
        unit: '人',
        quantity: specialDriverQuantity,
        unitPrice: specialDriverUnitPrice,
        subtotal: specialDriverSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
      {
        name: '杭打機損料',
        specification: machine.name,
        unit: '人',
        quantity: machineQuantity,
        unitPrice: machineUnitPrice,
        subtotal: machineSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧ﾊﾞｲﾌﾞﾛ',
        unit: '人',
        quantity: hydraulicVibroHammerQuantity,
        unitPrice: hydraulicVibroHammerUnitPrice,
        subtotal: hydraulicVibroHammerSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: '杭打ち機運転',
    specification: machine.name + '　油圧バイブロ',
    title: title(),
    reportType: '',
    key: key(),
  };
};

export const calcHydraulicVibroUnitPrice = (municipality: Municipality, machine: Machine): number => {
  return createHydraulicVibroUnitPriceList(municipality, machine).total;
};

/**
 * 単価ｵｰｶﾞ and ﾊﾞｲﾌﾞﾛ の第5号,6号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createHydraulicAugerUnitPriceList = (municipality: Municipality, machine: Machine): UnitPrices => {
  const title = () => {
    if (machine.key === MACHINE_RX3300) {
      return '第5号単価表';
    }
    if (machine.key === MACHINE_RX2300) {
      return '第6号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (machine.key === MACHINE_RX3300) {
      return 'auger_hydraulic_rx3300';
    }
    if (machine.key === MACHINE_RX2300) {
      return 'auger_hydraulic_rx2300';
    }
    return '-';
  };

  const specialDriverQuantity = 1;
  const specialDriverUnitPrice = municipality.specialDriver;
  const specialDriverSubtotal = round(specialDriverUnitPrice * specialDriverQuantity, 0);

  const oilQuantity = machine.requiredOilAmount * WORK_TIME;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const machineQuantity = 1.7;
  const machineUnitPrice = machine.rentFee;
  const machineSubtotal = round(machineQuantity * machineUnitPrice, 0);

  const hydraulicAugerQuantity = 1.56;
  const hydraulicAugerUnitPrice = calcHydraulicAugerUnitPrice(machine);
  const hydraulicAugerSubtotal = round(hydraulicAugerUnitPrice * hydraulicAugerQuantity, 0);

  const total = specialDriverSubtotal + oilSubtotal + machineSubtotal + hydraulicAugerSubtotal;
  const roundTotal = amountRoundUp(total);
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '特殊運転手',
        specification: '',
        unit: '人',
        quantity: specialDriverQuantity,
        unitPrice: specialDriverUnitPrice,
        subtotal: specialDriverSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
      {
        name: '杭打機損料',
        specification: machine.name,
        unit: '人',
        quantity: machineQuantity,
        unitPrice: machineUnitPrice,
        subtotal: machineSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧オーガ',
        unit: '人',
        quantity: hydraulicAugerQuantity,
        unitPrice: hydraulicAugerUnitPrice,
        subtotal: hydraulicAugerSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: '杭打ち機運転',
    specification: machine.name + '　油圧オーガ',
    title: title(),
    reportType: '',
    key: key(),
  };
};

/**
 * 単価ダウンザホールハンマーの第1号,2号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createDownTheDriveUnitPriceList = (municipality: Municipality, machine: Machine): UnitPrices => {
  const title = () => {
    if (machine.key === MACHINE_RX3300) {
      return '第1号単価表';
    }
    if (machine.key === MACHINE_RX2300) {
      return '第2号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (machine.key === MACHINE_RX3300) {
      return 'hole_hammer_rx3300';
    }
    if (machine.key === MACHINE_RX2300) {
      return 'hole_hammer_rx2300';
    }
    return '-';
  };

  const specialDriverQuantity = 1;
  const specialDriverUnitPrice = municipality.specialDriver;
  const specialDriverSubtotal = round(specialDriverUnitPrice * specialDriverQuantity, 0);

  const oilQuantity = machine.requiredOilAmount * WORK_TIME;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const machineQuantity = 1.7;
  const machineUnitPrice = machine.rentFee;
  const machineSubtotal = round(machineQuantity * machineUnitPrice, 0);

  const hydraulicVibroHammerQuantity = 1.5;
  const hydraulicVibroHammerUnitPrice = calcHydraulicVibroHammerUnitPrice(machine);
  const hydraulicVibroHammerSubtotal = round(hydraulicVibroHammerUnitPrice * hydraulicVibroHammerQuantity, 0);

  const hydraulicAugerQuantity = 1.56;
  const hydraulicAugerUnitPrice = calcHydraulicAugerUnitPrice(machine);
  const hydraulicAugerSubtotal = round(hydraulicAugerUnitPrice * hydraulicAugerQuantity, 0);

  const total =
    specialDriverSubtotal + oilSubtotal + machineSubtotal + hydraulicVibroHammerSubtotal + hydraulicAugerSubtotal;
  const roundTotal = amountRoundUp(total);
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '特殊運転手',
        specification: '',
        unit: '人',
        quantity: specialDriverQuantity,
        unitPrice: specialDriverUnitPrice,
        subtotal: specialDriverSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
      {
        name: '杭打機損料',
        specification: machine.name,
        unit: '人',
        quantity: machineQuantity,
        unitPrice: machineUnitPrice,
        subtotal: machineSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧ﾊﾞｲﾌﾞﾛﾊﾝﾏ',
        unit: '人',
        quantity: hydraulicVibroHammerQuantity,
        unitPrice: hydraulicVibroHammerUnitPrice,
        subtotal: hydraulicVibroHammerSubtotal,
        remark: '',
      },
      {
        name: '機械損料',
        specification: '油圧オーガ',
        unit: '人',
        quantity: hydraulicAugerQuantity,
        unitPrice: hydraulicAugerUnitPrice,
        subtotal: hydraulicAugerSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: '杭打ち機運転',
    specification: machine.name,
    title: title(),
    reportType: '',
    key: key(),
  };
};

/**
 * 単価ダウンザホールハンマーの第3号,4号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createAirCompressorUnitPriceList = (
  municipality: Municipality,
  airCompressor: AirCompressor
): UnitPrices => {
  const title = () => {
    if (airCompressor.key === '7_5m3') {
      return '第3号単価表';
    }
    if (airCompressor.key === '18m3') {
      return '第4号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (airCompressor.key === '7_5m3') {
      return 'hole_hammer_air_compressor_7_5m3';
    }
    if (airCompressor.key === '18m3') {
      return 'hole_hammer_air_compressor_18m3';
    }
    return '-';
  };
  const airCompressorQuantity = airCompressor.quantity;
  const airCompressorUnitPrice = airCompressor.unitPrice;
  const airCompressorSubtotal = round(airCompressorQuantity * airCompressorUnitPrice, 0);

  const oilQuantity = airCompressor.requiredOilAmount;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const total = airCompressorSubtotal + oilSubtotal;
  const roundTotal = amountRoundUp(total, 1); // Excel上空気圧縮機は1の位で切り上げしている
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '空気圧縮機',
        specification: '',
        unit: '日',
        quantity: airCompressorQuantity,
        unitPrice: airCompressorUnitPrice,
        subtotal: airCompressorSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: '空気圧縮機運転',
    specification: airCompressor.name,
    title: title(),
    reportType: '',
    key: key(),
  };
};

/**
 * 単価ダウンザホールハンマーの第5号,6号単価表計算を行う
 * @param municipality
 * @param machine
 * @returns
 */
export const createCraneUnitPriceList = (municipality: Municipality, crane: Crane): UnitPrices => {
  const title = () => {
    if (crane.key === '25t') {
      return '第5号単価表';
    }
    if (crane.key === '16t') {
      return '第6号単価表';
    }
    return '';
  };
  const key = (): UnitPriceKeys => {
    if (crane.key === '25t') {
      return 'hole_hammer_25t';
    }
    if (crane.key === '16t') {
      return 'hole_hammer_16t';
    }
    return '-';
  };
  const craneName = crane.name + 'り';
  const specialDriverQuantity = 1;
  const specialDriverUnitPrice = municipality.specialDriver;
  const specialDriverSubtotal = round(specialDriverQuantity * specialDriverUnitPrice, 0);

  const oilQuantity = crane.requiredOilAmount;
  const oilUnitPrice = municipality.lightOil;
  const oilSubtotal = round(oilUnitPrice * oilQuantity, 0);

  const craneQuantity = crane.quantity;
  const craneUnitPrice = crane.basePrice;
  const craneSubtotal = round(craneQuantity * craneUnitPrice, 0);

  const total = specialDriverSubtotal + oilSubtotal + craneSubtotal;
  const roundTotal = amountRoundUp(total, 1); // Excel上空気圧縮機は1の位で切り上げしている
  const otherExpensesQuantity = 1;
  const otherExpenses = round((roundTotal - total) * otherExpensesQuantity);

  return {
    details: [
      {
        name: '特殊運転手',
        specification: '',
        unit: '人',
        quantity: specialDriverQuantity,
        unitPrice: specialDriverUnitPrice,
        subtotal: specialDriverSubtotal,
        remark: '',
      },
      {
        name: '軽油',
        specification: '',
        unit: 'ℓ',
        quantity: oilQuantity,
        unitPrice: oilUnitPrice,
        subtotal: oilSubtotal,
        remark: '',
      },
      {
        name: 'ラフテレーンクレーン',
        specification: craneName,
        unit: '供用日',
        quantity: craneQuantity,
        unitPrice: craneUnitPrice,
        subtotal: craneSubtotal,
        remark: '',
      },
    ],
    incidentals: {
      name: '諸雑費',
      specification: '',
      unit: '式',
      quantity: otherExpensesQuantity,
      unitPrice: undefined,
      subtotal: otherExpenses,
      remark: '丸め',
    },
    total: roundTotal,
    pricePerUnit: round(roundTotal),
    unit: '日',
    standard: 1,
    productName: 'ラフテレーンクレーン',
    specification: craneName,
    title: title(),
    reportType: '',
    key: key(),
  };
};

export const findUnitPriceTitle = (key: UnitPriceKeys, unitPrices: UnitPrices[]): string => {
  return unitPrices.find((up) => up.key === key)?.title || '';
};

/**
 *
 * 作成した歩掛りから工種絞り込み、単価表を出力する
 * |条件                          |出力単価表                                  |
 * |ダウンザホールハンマー            | 単価ダウンザ 1,2,3,4号単価表出力             |
 * |ダウンザホールハンマークレーンあり  | 単価クレーン 5,6号単価表出力                 |
 * |オーガ工                       | 単価オーガandバイブロ 1,2,3,4,5,6号単価表出力 |
 * |打込 or 引抜                   | 単価オーガandバイブロ 3,4号単価表出力         |
 * 詳細：https://github.com/SecureCycle/civil-engineering-cost-estimation-system/issues/621#issuecomment-1330318315
 *
 * @param municipalities
 * @param machines
 * @param airCompressors
 * @param cranes
 * @param project
 * @param productivities
 * @returns
 */
export const createFirstPriceReportData = (
  municipalities: MasterTypes[],
  machines: MasterTypes[],
  airCompressors: AirCompressor[],
  cranes: Crane[],
  project: Project,
  productivities: Productivity[]
): UnitPrices[] => {
  const municipality = municipalities.find((m) => project.targetPref === m.key) as Municipality;
  const uniqueWorkTypes = () => {
    const workTypes = Array.from(new Set(productivities.map((p) => p.workType)));
    if (workTypes.includes(WorkType.AUGER)) {
      // オーガ工を選択した場合、単価表が重複してしまうため打込み・引抜きを削除
      return workTypes.filter((uwt) => !(uwt === WorkType.HAMMER_IN || uwt === WorkType.PULL_OUT));
    }
    if (workTypes.includes(WorkType.HAMMER_IN) && workTypes.includes(WorkType.PULL_OUT)) {
      // 打込み・引抜きどちらも選択した場合、単価表が重複してしまうため引抜きを削除
      return workTypes.filter((uwt) => !(uwt === WorkType.PULL_OUT));
    }
    return workTypes;
  };
  const isShowCranePriceList = productivities.find(
    (prod) =>
      prod.workType === WorkType.HOLE_HAMMER && (prod as HoleHammer).crane && (prod as HoleHammer).crane !== 'none'
  );
  return uniqueWorkTypes()
    .map((type) => {
      if (type === WorkType.HOLE_HAMMER) {
        const downTheDriveUnitPriceList = machines.map((m) =>
          createDownTheDriveUnitPriceList(municipality, m as Machine)
        );
        const airCompressorUnitPriceList = airCompressors.map((item) =>
          createAirCompressorUnitPriceList(municipality, item)
        );
        const cranePriceList = () => {
          if (isShowCranePriceList) {
            return cranes.map((crane) => createCraneUnitPriceList(municipality, crane));
          }
          return [];
        };
        return downTheDriveUnitPriceList.concat(airCompressorUnitPriceList).concat(cranePriceList());
      }
      if (type === WorkType.AUGER) {
        const augerUnitPrice = machines.map((m) => createAugerUnitPriceList(municipality, m as Machine));
        const hydraulicVibroUnitPrice = machines.map((m) =>
          createHydraulicVibroUnitPriceList(municipality, m as Machine)
        );
        const hydraulicAugerUnitPrice = machines.map((m) =>
          createHydraulicAugerUnitPriceList(municipality, m as Machine)
        );
        return augerUnitPrice.concat(hydraulicVibroUnitPrice).concat(hydraulicAugerUnitPrice);
      }
      if (type === WorkType.HAMMER_IN || type === WorkType.PULL_OUT) {
        const hydraulicVibroUnitPrice = machines.map((m) =>
          createHydraulicVibroUnitPriceList(municipality, m as Machine)
        );
        return hydraulicVibroUnitPrice;
      }
      return [];
    })
    .flat()
    .map((item, index) => ({ ...item, title: `第${index + 1}号単価表` }));
};
