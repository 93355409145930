import { useState, useCallback, FC } from 'react';
import { Box, styled } from '@mui/material';
import MenuAppBar from './MenuAppBar';
import SideMenu from './SideMenu';
import { useUserState } from 'globalStates/User';
import { useLogout } from 'usecase/Login';
import { useSendAudit } from 'usecase/Audit';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  children?: React.ReactNode;
  title: string;
}

const MainFrame: FC<Props> = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  const updateOpen = useCallback((open: boolean) => () => setOpen(open), []);
  const user = useUserState();
  const logout = useLogout();
  const postAudit = useSendAudit();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    postAudit('success', location, user.user, ['logout'], undefined, undefined);
    logout();
  }, [location, logout, postAudit, user.user]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <MenuAppBar
          open={open}
          title={title}
          onClickMenu={updateOpen(true)}
          user={user.user}
          onClickLogout={handleLogout}
        />
        <SideMenu open={open} onCloseMenu={updateOpen(false)} />
      </Box>
      <Main>{children}</Main>
    </Box>
  );
};

export default MainFrame;
