import { useEffect, useState, FC } from 'react';
import { Paper, Divider, Typography, Stack } from '@mui/material';
import { Auger } from 'model/Productivity/Auger';
import {
  calcAugerPreparationTime,
  calcAugerScrewJointTime,
  calcDrillingTime,
  calcDrivenTimePerUnit,
  calcInjectionTimePerUnit,
  calcInstallationTime,
  calcSandFillingTime,
} from 'api/calc/Auger';
import { round } from 'api/calc/Calc';
import { WORK_TIME } from 'store/Master';

interface Props {
  productivity: Auger;
  open: boolean;
}

interface ConstructionValue {
  tc: number;
  t1: number;
  t2: number;
  t3: number;
  t4: number;
  t5: number;
  t6: number;
  t: number;
  al: number;
  nw: number;
  f: number;
  days: number;
  quantity: number;
}

const CalculationResults: FC<Props> = ({ productivity, open }) => {
  const [value, setValue] = useState<ConstructionValue>({
    tc: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    t4: 0,
    t5: 0,
    t6: 0,
    t: 0,
    al: 0,
    nw: 0,
    f: 0,
    days: 0,
    quantity: 0,
  });
  useEffect(() => {
    const t1 = calcAugerPreparationTime(
      productivity.steelMaterial,
      productivity.injectionTimeGrout,
      productivity.casing,
      productivity.sandFillingTime
    );
    const t3 = calcInjectionTimePerUnit(productivity.injectionTimeGrout);
    const t4 = calcDrivenTimePerUnit(productivity.drivenTime);
    const t5 = calcInstallationTime(productivity.installationTime, productivity.installationCount);
    const t6 = calcSandFillingTime(productivity.sandFillingTime);
    const al = calcDrillingTime(productivity.drillingDiameter, productivity.soils);
    const nw = calcAugerScrewJointTime(productivity.screwJointTimes);
    const t2 = round(al + nw, 2);
    const f = round(1 + productivity.factorTotal);
    const t = WORK_TIME;
    const tc = round((t1 + t2 + t3 + t4 + t5 + t6) * f, 2);
    const days = round((1 * tc) / 60 / t, 3);
    const quantity = tc ? round((t * 60) / tc, 2) : 0; // NOTE: 初期表示時など tc が 0 のときは「日当たり施工数量 0 本/日」として表示する
    setValue({ tc, t1, t2, t3, t4, t5, t6, t, al, nw, f, days, quantity });
  }, [productivity, setValue]);
  if (!open) {
    return <div />;
  }
  return (
    <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, position: 'sticky', top: 0, maxWidth: 450 }}>
      <Stack spacing={2} m={2}>
        <div>
          <Typography variant="subtitle2">１ヶ所当たり施工時間</Typography>
          <Typography variant="subtitle2">Tc= (T1 + T2 + T3 + T4 + T5 + T6) * F</Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle2">Tc:1本当たり施工時間</Typography>
          <Typography variant="body2">{value.tc} min/本</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T1:１本当たり準備時間</Typography>
          <Typography variant="body2">{value.t1} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2:1本当たりオーガー掘削時間</Typography>
          <Typography variant="subtitle2">T2 = Σ(a・ℓ) + ｔ・ｎ</Typography>
          <Typography variant="body2">{value.t2} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">A・L:掘削時間</Typography>
          <Typography variant="body2">{value.al} min/m</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">nw:スクリュージョイント時間</Typography>
          <Typography variant="body2">{value.nw} min/回</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T3:1本当たりグラウト注入時間</Typography>
          <Typography variant="body2">{value.t3} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T4:1本あたり打込時間</Typography>
          <Typography variant="body2">{value.t4} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T5:溶接、ボルト取り付け時間</Typography>
          <Typography variant="body2">{value.t5} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T6:1本あたり砂充填時間</Typography>
          <Typography variant="body2">{value.t6} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T:作業時間</Typography>
          <Typography variant="body2">{value.t} h/日</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">F:作業係数</Typography>
          <Typography variant="body2">{value.f}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">1か所当たり Tc/(T（6.2）*60)</Typography>
          <Typography variant="body2">{value.days} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">日当たり施工数量</Typography>
          <Typography variant="body2">{value.quantity} 本/日</Typography>
        </div>
      </Stack>
    </Paper>
  );
};

export default CalculationResults;
