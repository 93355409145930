import { MasterData } from 'model/Master';
import {
  BondingDays,
  CorrectionFactor,
  DrivenPile,
  FillingDays,
  InjectionMaterial,
  Jointing,
  PreparationDays,
  Productivity,
  QualitySoil,
  QualitySoilType,
  ScrewJoint,
} from 'model/Productivity';

export const EmptyQualitySoil: QualitySoil = {
  sandySoil: 0,
  lechySoil: 0,
  claySoil: 0,
  rockBlock: 0,
  softRock: 0,
  mediumHardRock: 0,
  hardRock: 0,
};

export const EmptyProductivity: Productivity = {
  projectId: '',
  name: '',
  usedMachine: '',
  workType: 'hole-hammer',
};

export const DrivenPileItems: DrivenPile[] = [
  {
    key: 'H-150',
    name: 'H-150',
    value: 'H-150',
    drillingDiameter: 300,
    designPileDiameter: {
      value: 212,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-200',
    name: 'H-200',
    value: 'H-200',
    drillingDiameter: 400,
    designPileDiameter: {
      value: 283,
      min: 270,
      max: 320,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-250',
    name: 'H-250',
    value: 'H-250',
    drillingDiameter: 450,
    designPileDiameter: {
      value: 354,
      min: 320,
      max: 360,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-300',
    name: 'H-300',
    value: 'H-300',
    drillingDiameter: 500,
    designPileDiameter: {
      value: 424,
      min: 360,
      max: 430,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-350',
    name: 'H-350',
    value: 'H-350',
    drillingDiameter: 550,
    designPileDiameter: {
      value: 495,
      min: 430,
      max: 510,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-400',
    name: 'H-400',
    value: 'H-400',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 566,
      min: 510,
      max: 580,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'SP_3',
    name: 'SP Ⅲ型',
    value: 'SP_3',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 400,
      min: 510,
      max: 580,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'SP_4',
    name: 'SP Ⅳ型',
    value: 'SP_4',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 400,
      min: 510,
      max: 580,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    // FIXME: PR686 値の修正 設計杭系・削杭経・Ta用設計杭経
    key: 'SP_6',
    name: 'SP VL型',
    value: 'SP_6',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 400,
      min: 510,
      max: 580,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '216_3',
    name: '鋼管216.3',
    value: '216_3',
    drillingDiameter: 350,
    designPileDiameter: {
      value: 216.3,
      min: 170,
      max: 220,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '267_4',
    name: '鋼管267.4',
    value: '267_4',
    drillingDiameter: 400,
    designPileDiameter: {
      value: 267.4,
      min: 270,
      max: 320,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '318_5',
    name: '鋼管318.5',
    value: '318_5',
    drillingDiameter: 400,
    designPileDiameter: {
      value: 318.5,
      min: 270,
      max: 320,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '406_4',
    name: '鋼管406.4',
    value: '406_4',
    drillingDiameter: 500,
    designPileDiameter: {
      value: 406.4,
      min: 360,
      max: 430,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '550',
    name: '鋼管550',
    value: '550',
    drillingDiameter: 550,
    designPileDiameter: {
      value: 550,
      min: 430,
      max: 510,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'manual',
    name: '手入力',
    value: 'manual',
    drillingDiameter: 500,
    designPileDiameter: {
      value: 0,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SpDrillingDiameterItems: MasterData[] = [
  { key: '1', name: '400 mm', value: 400 },
  { key: '2', name: '450 mm', value: 450 },
  { key: '3', name: '500 mm', value: 500 },
  { key: '4', name: '550 mm', value: 550 },
  { key: '5', name: '600 mm', value: 600 },
];

export const PreparationDaysItemsForSteelPipe: PreparationDays[] = [
  {
    key: 'steel10',
    name: '鋼管 防護柵ℓ≦10',
    value: 'steel10',
    n: 0.17,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'steel12',
    name: '鋼管 防護柵10＜ℓ≦12',
    value: 'steel12',
    n: 0.25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const PreparationDaysItemsForHSteelAndSteelSheetPiles: PreparationDays[] = [
  {
    key: 'h10',
    name: 'H鋼 鋼矢板ℓ≦10',
    value: 'h10',
    n: 0.16,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h12',
    name: 'H鋼 鋼矢板10＜ℓ≦12',
    value: 'h12',
    n: 0.21,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const PreparationDaysItems: PreparationDays[] = PreparationDaysItemsForHSteelAndSteelSheetPiles.concat(
  PreparationDaysItemsForSteelPipe
);

export const FillingDaysItems: FillingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h170-10',
    name: 'H鋼鋼矢板170-320.ℓ≦10',
    value: 'h170-10',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h170-12',
    name: 'H鋼鋼矢板170-320.10＜ℓ≦12',
    value: 'h170-12',
    n: 0.17,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h320-10',
    name: 'H鋼鋼矢板320-580.ℓ≦10',
    value: 'h320-10',
    n: 0.15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h-10',
    name: 'H鋼鋼矢板320-580.10＜ℓ≦12',
    value: 'h-10',
    n: 0.31,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's170-10',
    name: '鋼管杭170-320.ℓ≦10',
    value: 's170-10',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's170-12',
    name: '鋼管杭170-320.10＜ℓ≦12',
    value: 's170-12',
    n: 0.13,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's320-10',
    name: '鋼管杭320-580.ℓ≦10',
    value: 's320-10',
    n: 0.11,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's320-12',
    name: '鋼管杭320-580.10＜ℓ≦12',
    value: 's320-12',
    n: 0.27,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-10',
    name: '土砂170-320.ℓ≦10',
    value: 'd170-10',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-12',
    name: '土砂170-320.10＜ℓ≦12',
    value: 'd170-12',
    n: 0.09,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-10',
    name: '土砂320-580.ℓ≦10',
    value: 'd320-10',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-12',
    name: '土砂320-580.10＜ℓ≦12',
    value: 'd320-12',
    n: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd270-8',
    name: '防護柵270-320.ℓ≦8',
    value: 'd270-8',
    n: 0.02,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
export const FillingDaysItemsForHSteelAndSteelSheetPiles: FillingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h170-10',
    name: 'H鋼鋼矢板170-320.ℓ≦10',
    value: 'h170-10',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h170-12',
    name: 'H鋼鋼矢板170-320.10＜ℓ≦12',
    value: 'h170-12',
    n: 0.17,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h320-10',
    name: 'H鋼鋼矢板320-580.ℓ≦10',
    value: 'h320-10',
    n: 0.15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h-10',
    name: 'H鋼鋼矢板320-580.10＜ℓ≦12',
    value: 'h-10',
    n: 0.31,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-10',
    name: '土砂170-320.ℓ≦10',
    value: 'd170-10',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-12',
    name: '土砂170-320.10＜ℓ≦12',
    value: 'd170-12',
    n: 0.09,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-10',
    name: '土砂320-580.ℓ≦10',
    value: 'd320-10',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-12',
    name: '土砂320-580.10＜ℓ≦12',
    value: 'd320-12',
    n: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd270-8',
    name: '防護柵270-320.ℓ≦8',
    value: 'd270-8',
    n: 0.02,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
export const FillingDaysItemsForSteelPipes: FillingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's170-10',
    name: '鋼管杭170-320.ℓ≦10',
    value: 's170-10',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's170-12',
    name: '鋼管杭170-320.10＜ℓ≦12',
    value: 's170-12',
    n: 0.13,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's320-10',
    name: '鋼管杭320-580.ℓ≦10',
    value: 's320-10',
    n: 0.11,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 's320-12',
    name: '鋼管杭320-580.10＜ℓ≦12',
    value: 's320-12',
    n: 0.27,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-10',
    name: '土砂170-320.ℓ≦10',
    value: 'd170-10',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd170-12',
    name: '土砂170-320.10＜ℓ≦12',
    value: 'd170-12',
    n: 0.09,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-10',
    name: '土砂320-580.ℓ≦10',
    value: 'd320-10',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd320-12',
    name: '土砂320-580.10＜ℓ≦12',
    value: 'd320-12',
    n: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'd270-8',
    name: '防護柵270-320.ℓ≦8',
    value: 'd270-8',
    n: 0.02,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingDaysItems: BondingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '150',
    name: 'H150-H175溶接時間',
    value: '150',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '200',
    name: 'H200溶接時間',
    value: '200',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '250',
    name: 'H250溶接時間',
    value: '250',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300',
    name: 'H300溶接時間',
    value: '300',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '350',
    name: 'H350溶接時間',
    value: '350',
    n: 0.13,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400',
    name: 'H400溶接時間',
    value: '400',
    n: 0.19,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type2',
    name: 'Ⅱ型溶接時間',
    value: 'type2',
    n: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type3',
    name: 'Ⅲ型溶接時間',
    value: 'type3',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type4',
    name: 'Ⅳ型溶接時間',
    value: 'type4',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type5',
    name: 'Ⅴ型溶接時間',
    value: 'type5',
    n: 0.19,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type2w',
    name: 'Ⅱw型溶接時間',
    value: 'type2w',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type3w',
    name: 'Ⅲw型溶接時間',
    value: 'type3w',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type4w',
    name: 'Ⅳw型溶接時間',
    value: 'type4w',
    n: 0.21,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '150j',
    name: 'H150～H175ボルト接合',
    value: '150j',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '200j',
    name: 'H200ボルト接合',
    value: '200j',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '250j',
    name: 'H250ボルト接合',
    value: '250j',
    n: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300j',
    name: 'H300ボルト接合',
    value: '300j',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '350j',
    name: 'H350ボルト接合',
    value: '350j',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400j',
    name: 'H400ボルト接合',
    value: '400j',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '170i',
    name: '鋼管外径170以上300未満',
    value: '170i',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300i',
    name: '300以上400未満',
    value: '300i',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400i',
    name: '400以上500未満',
    value: '400i',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '500i',
    name: '500以上580以下',
    value: '500i',
    n: 0.11,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingDaysItemsForHSteel: BondingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '150',
    name: 'H150-H175溶接時間',
    value: '150',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '200',
    name: 'H200溶接時間',
    value: '200',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '250',
    name: 'H250溶接時間',
    value: '250',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300',
    name: 'H300溶接時間',
    value: '300',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '350',
    name: 'H350溶接時間',
    value: '350',
    n: 0.13,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400',
    name: 'H400溶接時間',
    value: '400',
    n: 0.19,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '150j',
    name: 'H150～H175ボルト接合',
    value: '150j',
    n: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '200j',
    name: 'H200ボルト接合',
    value: '200j',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '250j',
    name: 'H250ボルト接合',
    value: '250j',
    n: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300j',
    name: 'H300ボルト接合',
    value: '300j',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '350j',
    name: 'H350ボルト接合',
    value: '350j',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400j',
    name: 'H400ボルト接合',
    value: '400j',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingDaysItemsForSteelSheetPiles: BondingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type2',
    name: 'Ⅱ型溶接時間',
    value: 'type2',
    n: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type3',
    name: 'Ⅲ型溶接時間',
    value: 'type3',
    n: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type4',
    name: 'Ⅳ型溶接時間',
    value: 'type4',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type5',
    name: 'Ⅴ型溶接時間',
    value: 'type5',
    n: 0.19,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type2w',
    name: 'Ⅱw型溶接時間',
    value: 'type2w',
    n: 0.07,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type3w',
    name: 'Ⅲw型溶接時間',
    value: 'type3w',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'type4w',
    name: 'Ⅳw型溶接時間',
    value: 'type4w',
    n: 0.21,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingDaysItemsForSteelPiles: BondingDays[] = [
  {
    key: '-',
    name: 'なし',
    value: '-',
    n: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '170i',
    name: '鋼管外径170以上300未満',
    value: '170i',
    n: 0.04,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '300i',
    name: '300以上400未満',
    value: '300i',
    n: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '400i',
    name: '400以上500未満',
    value: '400i',
    n: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '500i',
    name: '500以上580以下',
    value: '500i',
    n: 0.11,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const JointingItems: Jointing[] = [
  {
    key: '0',
    name: '0',
    value: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '1',
    name: '1',
    value: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: '2',
    value: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: '3',
    value: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const ScrewJointItems: ScrewJoint[] = [
  {
    key: '0',
    name: '0',
    value: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '1',
    name: '1',
    value: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: '2',
    value: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: '3',
    value: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const CorrectionFactorItemsForHSteelAndSteelSheetPiles: CorrectionFactor[] = [
  {
    key: 'h',
    name: 'H鋼・鋼矢板',
    value: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
export const CorrectionFactorItemsForSteelPipe: CorrectionFactor[] = [
  {
    key: '14',
    name: '鋼管厚さ 〜14',
    value: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '15',
    name: '鋼管厚さ 15〜17',
    value: 1.41,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '18',
    name: '鋼管厚さ 18〜21',
    value: 2.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '22',
    name: '鋼管厚さ 22〜25',
    value: 2.76,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '26',
    name: '鋼管厚さ 26〜30',
    value: 3.84,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const CorrectionFactorItems: CorrectionFactor[] = CorrectionFactorItemsForHSteelAndSteelSheetPiles.concat(
  CorrectionFactorItemsForSteelPipe
);

export const InjectionMaterialItems: InjectionMaterial[] = [
  {
    key: 'none',
    name: 'なし',
    value: '-',
    rate: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'mortarl',
    name: 'モルタル・ミルク',
    value: 'mortarl',
    rate: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'sand',
    name: '購入砂',
    value: 'sand',
    rate: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const QualitySoilList: QualitySoilType[] = [
  {
    key: 'sandySoil',
    name: '砂質土',
    value: 0.03,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'claySoil',
    name: '粘性土',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'lechySoil',
    name: 'レキ質土',
    value: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'rockBlock',
    name: '岩塊玉石',
    value: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'softRock',
    name: '軟岩',
    value: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'mediumHardRock',
    name: '中硬岩',
    value: 0.06,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'hardRock',
    name: '硬岩',
    value: 0.08,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

/**
 * 諸雑費 %
 */
export const OtherExpensiveRate = [
  { workType: 'hole-hammer', rate: 0.28 },
  { workType: 'auger', exitsPlant: { rate: 0.2 }, noPlant: { rate: 0.1 } },
  { workType: 'pull-out', rate: 0.15 },
  { workType: 'hammer-in', rate: 0.15 },
];

export const AirCompressorItems: MasterData[] = [
  { key: '0', name: '0', value: 0 },
  { key: '1', name: '1', value: 1 },
  { key: '2', name: '2', value: 2 },
  { key: '3', name: '3', value: 3 },
  { key: '4', name: '4', value: 4 },
];
