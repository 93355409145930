import { FC } from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { FormControl, FormHelperText, MenuItem, Select as SelectBase, SelectProps, InputLabel } from '@mui/material';

type Props = SelectProps & {
  name?: string;
  items: SelectItem[];
  // eslint-disable-next-line
  defaultValue?: any;
  // eslint-disable-next-line
  control?: Control<FieldValues, any>;
};

export interface SelectItem {
  key: string;
  label: string;
  value: string | number;
}

export const Select: FC<Props> = (props) => {
  return (
    <Controller
      name={props.name || 'dummy'}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <FormControl required={props.required} fullWidth={props.fullWidth} error={fieldState.invalid}>
          <InputLabel id={props.labelId}>{props.label}</InputLabel>
          <SelectBase {...field} id={props.id} labelId={props.labelId} label={props.label}>
            {props.items.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </SelectBase>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default Select;
