import { FC, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Grid, Typography } from '@mui/material';
import { TextField } from 'component/TextField';
import { RentFeeCalculation } from 'model/RentFeeCalculation';

interface Props {
  rentFee: RentFeeCalculation;
  onClickBack: () => void;
  onClickConfirm: (rentFee: RentFeeCalculation) => void;
  onUpdate?: (rentFee: RentFeeCalculation) => void;
}

const schema = z.object({
  // id: z.string().nullable(),
  no: z.number().nonnegative(),
  category: z.string().trim().min(1),
  specifications: z.string(),
  enginePower: z.string().nullable(),
  weight: z.number().nonnegative().nullable(),
  basicPrice: z.number().nonnegative(),
  standardUsePeriodOfYear: z.number().nonnegative(),
  operationHours: z.number().nonnegative().nullable(),
  operationDays: z.number().nonnegative().nullable(),
  serviceDays: z.number().nonnegative().nullable(),
  maintenanceRepairCostRate: z.number().nonnegative().nullable(),
  annualManagementCostRate: z.number().nonnegative().nullable(),
  survivalRate: z.number().nonnegative().nullable(),
  rentOnOperatingRate: z.number().nonnegative().nullable(),
  amountToRentOnOperating: z.number().nonnegative().nullable(),
  rentOnInServiceRate: z.number().nonnegative().nullable(),
  amountToRentOnService: z.number().nonnegative().nullable(),
  rentOnOperatingRateConversion: z.number().nonnegative().nullable(),
  rentOnOperatingRateConversionUnit: z.string().nullable(),
  amountToRentConversionOnOperating: z.number().nonnegative().nullable(),
  amountToRentConversionOnOperatingUnit: z.string().nullable(),
  rentOnInServiceRateConversion: z.number().nonnegative(),
  amountToRentConversionOnService: z.number().nonnegative(),
  brief: z.string().nullable(),
  amountOfFuelUsedPerHour: z.number().nonnegative().nullable(),
});

const Create: FC<Props> = ({ rentFee, onClickBack, onUpdate, onClickConfirm }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  console.log('error', errors);

  // NOTE:MUIとの連携のため、any型を使用
  // eslint-disable-next-line
  const parse = (data: any): RentFeeCalculation => ({ ...rentFee, ...data });

  const onSubmit = useCallback(
    // NOTE:MUIとの連携のため、any型を使用
    // eslint-disable-next-line
    (data: any) => {
      onClickConfirm(parse(data));
    },
    [onClickConfirm, parse]
  );

  useEffect(() => {
    Object.entries(rentFee).map(([name, value]) => setValue(name, value));
  }, [rentFee, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parse(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, parse, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <TextField
            required
            fullWidth
            id="no"
            name="no"
            label="No"
            variant="standard"
            type="number"
            defaultValue={rentFee.no}
            control={control}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            required
            fullWidth
            id="category"
            name="category"
            label="分類コード"
            variant="standard"
            defaultValue={rentFee.category}
            control={control}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            required
            fullWidth
            id="specifications"
            name="specifications"
            label="規格諸元"
            variant="standard"
            defaultValue={rentFee.specifications}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="enginePower"
            name="enginePower"
            label="規格機関出力"
            variant="standard"
            defaultValue={rentFee.enginePower}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="weight"
            name="weight"
            label="規格機械重量"
            variant="standard"
            type="number"
            defaultValue={rentFee.weight}
            control={control}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            required
            fullWidth
            id="basicPrice"
            name="basicPrice"
            label="基礎価格"
            variant="standard"
            type="number"
            defaultValue={rentFee.basicPrice}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            id="standardUsePeriodOfYear"
            name="standardUsePeriodOfYear"
            label="標準使用年数"
            variant="standard"
            type="number"
            defaultValue={rentFee.standardUsePeriodOfYear}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h6">年間標準</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="operationHours"
            name="operationHours"
            label="運転時間"
            variant="standard"
            type="number"
            defaultValue={rentFee.operationHours}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="operationDays"
            name="operationDays"
            label="運転日数"
            variant="standard"
            type="number"
            defaultValue={rentFee.operationDays}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="serviceDays"
            name="serviceDays"
            label="供用日数"
            variant="standard"
            type="number"
            defaultValue={rentFee.serviceDays}
            control={control}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="maintenanceRepairCostRate"
            name="maintenanceRepairCostRate"
            label="維持修理費率"
            variant="standard"
            type="number"
            defaultValue={rentFee.maintenanceRepairCostRate}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="annualManagementCostRate"
            name="annualManagementCostRate"
            label="年間管理費率"
            variant="standard"
            type="number"
            defaultValue={rentFee.annualManagementCostRate}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h6">運転1時間当り</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="survivalRate"
            name="survivalRate"
            label="残存率"
            variant="standard"
            type="number"
            defaultValue={rentFee.survivalRate}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="rentOnOperatingRate"
            name="rentOnOperatingRate"
            label="損料率"
            variant="standard"
            type="number"
            defaultValue={rentFee.rentOnOperatingRate}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="amountToRentOnOperating"
            name="amountToRentOnOperating"
            label="損料"
            variant="standard"
            type="number"
            defaultValue={rentFee.amountToRentOnOperating}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h6">供用1時間当り</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="rentOnInServiceRate"
            name="rentOnInServiceRate"
            label="損料率"
            variant="standard"
            type="number"
            defaultValue={rentFee.rentOnInServiceRate}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="amountToRentOnService"
            name="amountToRentOnService"
            label="損料"
            variant="standard"
            type="number"
            defaultValue={rentFee.amountToRentOnService}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h3">参考</Typography>
          <Typography component="h6">運転1時間当り換算値</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="rentOnOperatingRateConversion"
            name="rentOnOperatingRateConversion"
            label="損料率"
            variant="standard"
            type="number"
            defaultValue={rentFee.rentOnOperatingRateConversion}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="rentOnOperatingRateConversionUnit"
            name="rentOnOperatingRateConversionUnit"
            label="損料率単位"
            variant="standard"
            defaultValue={rentFee.rentOnOperatingRateConversionUnit}
            control={control}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="amountToRentConversionOnOperating"
            name="amountToRentConversionOnOperating"
            label="損料"
            variant="standard"
            type="number"
            defaultValue={rentFee.amountToRentConversionOnOperating}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="amountToRentConversionOnOperatingUnit"
            name="amountToRentConversionOnOperatingUnit"
            label="損料単位"
            variant="standard"
            defaultValue={rentFee.amountToRentConversionOnOperatingUnit}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h6">供用1日当り換算値</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="rentOnInServiceRateConversion"
            name="rentOnInServiceRateConversion"
            label="損料率"
            variant="standard"
            type="number"
            defaultValue={rentFee.rentOnInServiceRateConversion}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            id="amountToRentConversionOnService"
            name="amountToRentConversionOnService"
            label="損料"
            variant="standard"
            type="number"
            defaultValue={rentFee.amountToRentConversionOnService}
            control={control}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="brief"
            name="brief"
            label="摘要"
            variant="standard"
            defaultValue={rentFee.brief}
            control={control}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="amountOfFuelUsedPerHour"
            name="amountOfFuelUsedPerHour"
            label="消費燃料"
            variant="standard"
            type="number"
            defaultValue={rentFee.amountOfFuelUsedPerHour}
            control={control}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
          戻る
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
          確認
        </Button>
      </Box>
    </form>
  );
};

export default Create;
