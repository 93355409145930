import { FC } from 'react';
import { Button, Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Copyright from 'component/Copyright';
import { TextField } from 'component/TextField';
import { DatePicker } from 'component/DatePicker';
import { Select, SelectItem } from 'component/Select';
import { Project } from 'model/Project';
import { Municipality } from 'model/Master';

interface Props {
  project: Project;
  prefectures: Municipality[];
  onClickConfirm: (project: Project) => void;
  onClickBack: () => void;
  onUpdate: (prop: keyof Project, project: Project) => void;
}

const schema = z.object({
  estimateSubmissionDate: z.date(),
  estimateValidity: z.string().optional(),
  customerName: z.string(),
  requesterName: z.string().optional(),
  constractionName: z.string(),
  constractionSite: z.string().optional(),
  targetPref: z.string(),
});

const Create: FC<Props> = ({ project, prefectures, onClickConfirm, onClickBack }) => {
  const { control, handleSubmit } = useForm({ resolver: zodResolver(schema) });

  // eslint-disable-next-line
  const onSubmit = (e: any) => {
    console.log(e);
    onClickConfirm(e);
  };

  return (
    <Container maxWidth="xl">
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          新しい工事
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                required
                fullWidth
                id="estimate-submission-date"
                name="estimateSubmissionDate"
                mask="____年__月__日"
                inputFormat="yyyy年MM月dd日"
                label="見積提出日"
                variant="standard"
                defaultValue={project.estimateSubmissionDate || new Date()}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="estimate-validity"
                label="見積有効期限"
                variant="standard"
                name="estimateValidity"
                defaultValue={project.estimateValidity}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="customer-name"
                label="見積宛先"
                variant="standard"
                name="customerName"
                defaultValue={project.customerName}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="requester-name"
                label="見積依頼元"
                variant="standard"
                name="requesterName"
                defaultValue={project.requesterName}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="constraction-name"
                label="工事名称"
                variant="standard"
                name="constractionName"
                defaultValue={project.constractionName}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="constraction-site"
                label="工事場所"
                variant="standard"
                name="constractionSite"
                defaultValue={project.constractionSite}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                required
                fullWidth
                id="target-pref"
                label="対象県"
                labelId="target-pref-label"
                name="targetPref"
                items={prefectures.map<SelectItem>((pref) => ({
                  value: pref.value || '',
                  key: pref.key || '',
                  label: pref.name || '',
                }))}
                defaultValue={project.targetPref}
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" sx={{ mt: 3, ml: 1 }} onClick={onClickBack}>
              戻る
            </Button>
            <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
              次へ
            </Button>
          </Box>
        </form>
      </Paper>
      <Copyright />
    </Container>
  );
};

export default Create;
