import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { Errors } from 'model/Errors';

interface ErrorsState {
  errors: Errors | undefined;
}

const errorsState = atom<ErrorsState>({
  key: 'errors',
  default: {
    errors: undefined,
  },
});

export const useErrorsState = () => useRecoilValue(errorsState);
export const useErrorsMutators = () => {
  const setState = useSetRecoilState(errorsState);
  const setErrors = useCallback(
    (errors: Errors | undefined) => {
      return setState((previousState: ErrorsState) => ({
        ...previousState,
        errors,
      }));
    },
    [setState]
  );

  return setErrors;
};
