import {
  Firestore,
  getDocs,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  query,
  QueryConstraint,
  orderBy,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { convertMasterData } from 'model/Master';
import { RentFeeCalculation, RentFeeCalculationNote } from 'model/RentFeeCalculation';

const converter: FirestoreDataConverter<RentFeeCalculation> = {
  toFirestore(rentFee: RentFeeCalculation): DocumentData {
    return rentFee;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): RentFeeCalculation {
    return convertMasterData<RentFeeCalculation>(snapshot.data(options));
  },
};

export const fetchRentFeeCalculations = async (db: Firestore): Promise<RentFeeCalculation[]> => {
  const ref = collection(db, 'rent-fee-calculations').withConverter(converter);
  const q: QueryConstraint[] = [];
  q.push(orderBy('no', 'asc'));
  const ss = await getDocs(query(ref, ...q));
  const ret: RentFeeCalculation[] = [];
  ss.forEach((doc) => ret.push(doc.data()));
  return ret;
};

export const setRentFeeCalculation = async (
  db: Firestore,
  rentFee: RentFeeCalculation
): Promise<RentFeeCalculation> => {
  if (rentFee.id) {
    const docRef = doc(db, 'rent-fee-calculations', rentFee.id).withConverter(converter);
    await updateDoc(docRef, { ...rentFee, updatedAt: serverTimestamp() });
    return rentFee;
  } else {
    const docRef = doc(collection(db, 'rent-fee-calculations')).withConverter(converter);
    await setDoc(docRef, { ...rentFee, id: docRef.id, createdAt: serverTimestamp(), updatedAt: serverTimestamp() });
    return rentFee;
  }
};

export const deleteRentFeeCalculations = async (db: Firestore, ids: string[]): Promise<void[]> =>
  Promise.all(ids.map(async (id) => await deleteDoc(doc(db, 'rent-fee-calculations', id || ''))));

export const deleteRentFeeNotes = async (db: Firestore, ids: string[]): Promise<void[]> =>
  Promise.all(ids.map(async (id) => await deleteDoc(doc(db, 'rent-fee-calculation-notes', id || ''))));

const notesConverter: FirestoreDataConverter<RentFeeCalculationNote> = {
  toFirestore(note: RentFeeCalculationNote): DocumentData {
    return note;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): RentFeeCalculationNote {
    return convertMasterData<RentFeeCalculationNote>(snapshot.data(options));
  },
};

export const fetchRentFeeCalculationNotes = async (db: Firestore): Promise<RentFeeCalculationNote[]> => {
  const ref = collection(db, 'rent-fee-calculation-notes').withConverter(notesConverter);
  const q: QueryConstraint[] = [];
  q.push(orderBy('order', 'asc'));
  const ss = await getDocs(query(ref, ...q));
  const ret: RentFeeCalculationNote[] = [];
  ss.forEach((doc) => ret.push(doc.data()));
  return ret;
};

export const setRentFeeNote = async (db: Firestore, note: RentFeeCalculationNote): Promise<RentFeeCalculationNote> => {
  if (note.id) {
    const docRef = doc(db, 'rent-fee-calculation-notes', note.id);
    await updateDoc(docRef, { ...note, updatedAt: serverTimestamp() });
    return note;
  } else {
    const docRef = doc(collection(db, 'rent-fee-calculation-notes'));
    await setDoc(docRef, { ...note, id: docRef.id, createdAt: serverTimestamp(), updatedAt: serverTimestamp() });
    return note;
  }
};

export const setFiscalYear = async (db: Firestore, value: string): Promise<string> => {
  const docRef = doc(db, 'masters', 'fiscalYear');
  await updateDoc(docRef, { value });
  return value;
};

export const getFiscalYear = async (db: Firestore): Promise<string> => {
  const docRef = doc(db, 'masters', 'fiscalYear');
  const res = await getDoc(docRef);
  return res?.data()?.value || '';
};
