/**
 * 指定した桁数でnumを四捨五入します
 * @param num
 * @param digit 任意の数字。値を指定しない場合デフォルトで2が指定される
 * @returns
 */
export const round = (num: number, digit = 2): number => parseFloat(num.toFixed(digit));

/**
 * 指定した桁数でnumを切り上げます
 * @param num
 * @param digit 任意の数字。値を指定しない場合デフォルトで2が指定される
 * @returns
 */
export const amountRoundUp = (num: number, digit = 2): number => Math.ceil(num / 10 ** digit) * 10 ** digit;
