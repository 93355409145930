import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataList, { TableType } from 'component/DataList';
import MainFrame from 'component/MainFrame';
import { format } from 'date-fns';
import { useFetchUsers } from 'usecase/User';
import {
  useDeleteRentFeeCalculation,
  useDeleteRentFeeNoteCalculation,
  useFetchFiscalYear,
  useFetchRentFeeCalculations,
  useFetchRentFeeNotes,
  useSendFiscalYear,
} from 'usecase/RentFeeCalculation';
import { Link, Typography } from '@mui/material';
import { User } from 'model/User';
import { useErrorsMutators } from 'globalStates/Errors';

const RentFeeIndex: FC = () => {
  const navigate = useNavigate();
  const [rentFeeCalculations, error, reFetch] = useFetchRentFeeCalculations();
  const [notes, noteError, noteReFetch] = useFetchRentFeeNotes();
  const deleteRentFees = useDeleteRentFeeCalculation();
  const deleteNotes = useDeleteRentFeeNoteCalculation();
  const savedFiscalYear = useFetchFiscalYear();
  const sendFiscalYear = useSendFiscalYear();
  const setErrors = useErrorsMutators();
  const [users] = useFetchUsers();
  const [fiscalYear, setFiscalYear] = useState<string>(savedFiscalYear);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        width: 30,
      },
      {
        field: 'category',
        headerName: '分類コード',
        width: 200,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link key={params.id} color="inherit" href={`/rx-machine/rent-fee/${params.id}`}>
            {params.row.category}
          </Link>
        ),
      },
      {
        field: 'specifications',
        headerName: '規格諸元',
        width: 230,
      },
      {
        field: 'enginePower',
        headerName: '規格機関出力',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'weight',
        headerName: '規格機械重量',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'basicPrice',
        headerName: '基礎価格',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'standardUsePeriodOfYear',
        headerName: '標準使用年数',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'operationHours',
        headerName: '年間標準運転時間',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'operationDays',
        headerName: '年間標準運転日数',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'serviceDays',
        headerName: '年間標準供用日数',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'maintenanceRepairCostRate',
        headerName: '維持修理費率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'annualManagementCostRate',
        headerName: '年間管理費率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'survivalRate',
        headerName: '運転1時間当り残存率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'rentOnOperatingRate',
        headerName: '運転1時間当り損料率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'amountToRentOnOperating',
        headerName: '運転1時間当り損料',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'rentOnInServiceRate',
        headerName: '供用1時間当り損料率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'amountToRentOnService',
        headerName: '供用1時間当り損料',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'rentOnOperatingRateConversion',
        headerName: '運転1時間当り換算値損料率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'rentOnOperatingRateConversionUnit',
        headerName: '運転1時間当り換算値損料率単位',
        width: 120,
      },
      {
        field: 'amountToRentConversionOnOperating',
        headerName: '運転1時間当り換算値損料',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'amountToRentConversionOnOperatingUnit',
        headerName: '運転1時間当り換算値損料単位',
        width: 120,
      },
      {
        field: 'rentOnInServiceRateConversion',
        headerName: '供用1日当り換算値損料率',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'amountToRentConversionOnService',
        headerName: '供用1日当り換算値損料',
        width: 120,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'brief',
        headerName: '摘要',
        width: 200,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'amountOfFuelUsedPerHour',
        headerName: '消費燃料',
        width: 100,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },

      {
        field: 'createdAt',
        headerAlign: 'left',
        headerName: '作成日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.createdAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedAt',
        headerAlign: 'left',
        headerName: '最終更新日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.updatedAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedBy',
        headerAlign: 'left',
        headerName: '最終更新者',
        width: 200,
        valueGetter: (params: GridValueGetterParams) =>
          users.find((user: User) => user.id === params.row.updatedBy)?.name,
      },
    ],
    [users]
  );

  const noteColumns: GridColumns = useMemo(
    () => [
      {
        field: 'no',
        headerName: 'No',
        width: 150,
      },
      {
        field: 'reference',
        headerName: '備考内容',
        width: 1200,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link key={params.id} color="inherit" href={`/rx-machine/note/${params.id}`}>
            {params.row.reference}
          </Link>
        ),
      },
      {
        field: 'createdAt',
        headerAlign: 'left',
        headerName: '作成日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.createdAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedAt',
        headerAlign: 'left',
        headerName: '最終更新日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.updatedAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedBy',
        headerAlign: 'left',
        headerName: '最終更新者',
        width: 200,
        valueGetter: (params: GridValueGetterParams) =>
          users.find((user: User) => user.id === params.row.updatedBy)?.name,
      },
    ],
    [users]
  );

  const handleCreate = (type: 'rent-fee' | 'note') => {
    navigate(`/rx-machine/${type}/new`);
  };

  const handleChangeYear = useCallback((year: string) => {
    setFiscalYear(year);
  }, []);
  const handleSaveYear = useCallback(async () => {
    await sendFiscalYear(fiscalYear);
  }, [fiscalYear, sendFiscalYear]);

  const handleDelete = useCallback(
    (type: 'rent-fee' | 'note') => async (selectedItems: TableType[]) => {
      try {
        const ids = selectedItems.map((item) => item.id || '');
        if (!ids.length) return;
        if (type === 'rent-fee') {
          await deleteRentFees(ids);
          reFetch();
        } else {
          await deleteNotes(ids);
          noteReFetch();
        }
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      }
    },
    [deleteNotes, deleteRentFees, noteReFetch, reFetch, setErrors]
  );

  useEffect(() => {
    if (error) {
      if (error instanceof Error) {
        setErrors({ code: 999, message: error.message });
      } else {
        setErrors({ code: 999, message: error as string });
      }
    }
    if (noteError) {
      if (noteError instanceof Error) {
        setErrors({ code: 999, message: noteError.message });
      } else {
        setErrors({ code: 999, message: noteError as string });
      }
    }
  }, [error, noteError, setErrors]);

  useEffect(() => {
    setFiscalYear(savedFiscalYear);
  }, [savedFiscalYear]);

  return (
    <MainFrame title="建設機械等損料算定表">
      <DataList
        columns={columns}
        data={rentFeeCalculations || []}
        onClickNew={() => handleCreate('rent-fee')}
        reportButton="算定表"
        fiscalYear={fiscalYear}
        onChangeYear={handleChangeYear}
        onSaveYear={handleSaveYear}
        onClickDelete={handleDelete('rent-fee')}
      />
      <br />
      <br />
      <Typography variant="h4">備考内容</Typography>
      <br />
      <DataList
        columns={noteColumns}
        data={notes || []}
        onClickNew={() => handleCreate('note')}
        onClickDelete={handleDelete('note')}
      />
    </MainFrame>
  );
};

export default RentFeeIndex;
