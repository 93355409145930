import { GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Link } from '@mui/material';
import { Machine } from 'model/Productivity';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

export const columns: GridColumns = [
  {
    field: 'name',
    headerName: '使用機器名称',
    width: 230,
    renderCell: (params: GridRenderCellParams<string>) => (
      <Link key={params.id} color="inherit" href={`/master/machine/${params.id}`}>
        {params.row.name}
      </Link>
    ),
  },
  {
    field: 'rentFee',
    headerAlign: 'left',
    type: 'number',
    headerName: '単価',
    width: 100,
  },
  {
    field: 'requiredOilAmount',
    headerAlign: 'left',
    type: 'number',
    headerName: '燃料',
    width: 100,
  },
  {
    field: 'order',
    type: 'number',
    headerAlign: 'left',
    headerName: '並び順',
    width: 100,
  },
  {
    field: 'createdAt',
    headerName: '作成日時',
    width: 200,
    valueGetter: (params: GridValueGetterParams) => format(params.row.createdAt, 'yyyy年M月d日'),
  },
  {
    field: 'updatedAt',
    headerName: '更新日時',
    width: 200,
    valueGetter: (params: GridValueGetterParams) => format(params.row.updatedAt, 'yyyy年M月d日'),
  },
];

export const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'order', sort: 'asc' }],
  },
};

export const EmptyMachine: Machine = {
  name: '',
  value: '',
  id: '',
  rentFee: 0,
  requiredOilAmount: 0,
  order: 0,
};
