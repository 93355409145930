import { User } from 'model/User';
import { Location } from 'react-router-dom';
import { useWriteAuditRepository } from 'repository/Audit';

export const useSendAudit = (): ((
  status: 'success' | 'failed',
  location: Location,
  user?: User | null,
  tags?: string[],
  value?: string,
  error?: string
) => Promise<void>) => {
  return useWriteAuditRepository();
};
