import { DocumentData } from 'firebase/firestore';
import { Productivity } from './Productivity';
import { HoleHammer } from './Productivity/HoleHammer';
import { Auger } from './Productivity/Auger';
import { PullOut } from './Productivity/PullOut';
import { TypeIn } from './Productivity/TypeIn';
import { Carry } from './Productivity/Carry';

export interface Project {
  id?: string;
  estimateSubmissionDate: Date;
  estimateValidity: string;
  customerName: string;
  requesterName: string;
  constractionName: string;
  constractionSite: string;
  targetPref: string;
  productivities: Productivity[] | HoleHammer[] | Auger[] | TypeIn[] | PullOut[] | Carry[];
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export const convertProject = (data: DocumentData): Project => ({
  id: data.id,
  estimateSubmissionDate: data.estimateSubmissionDate.toDate(),
  estimateValidity: data.estimateValidity,
  customerName: data.customerName,
  requesterName: data.requesterName,
  constractionName: data.constractionName,
  constractionSite: data.constractionSite,
  targetPref: data.targetPref,
  productivities: data.productivities,
  createdAt: data.createdAt.toDate(),
  updatedAt: data.updatedAt.toDate(),
});

export interface ProjectCondition {
  id?: string;
}
