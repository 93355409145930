import { FC } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Copyright from 'component/Copyright';
import CalculationResults from './CalculationResults';
import Create from './Create';
import { HoleHammer } from 'model/Productivity/HoleHammer';
import { MasterData } from 'model/Master';

interface Props {
  productivity: HoleHammer;
  openCalculate: boolean;
  machines: MasterData[];
  onClickBack: () => void;
  onClickConfirm: (productivity: HoleHammer) => void;
  updateProductivity: (prop: keyof HoleHammer, productivity: HoleHammer) => void;
  onUpdate?: (productivity: HoleHammer) => void;
}

const Index: FC<Props> = ({
  productivity,
  openCalculate,
  machines,
  onClickBack,
  onClickConfirm,
  onUpdate,
  updateProductivity,
}) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {productivity.remark || '新しい歩掛り'}
          </Typography>
          <Create
            productivity={productivity}
            machines={machines}
            onClickConfirm={onClickConfirm}
            updateProductivity={updateProductivity}
            onClickBack={onClickBack}
            onUpdate={onUpdate}
          />
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <CalculationResults productivity={productivity} open={openCalculate} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Index;
