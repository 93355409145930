import { useCallback, useEffect, useState } from 'react';

const KEY = 'role';
type Role = 'admin' | 'user';

export const useRoleState = (): [Role, (updateValue: Role | null, remove?: boolean) => void] => {
  const raw = sessionStorage.getItem(KEY);
  const [value, setValue] = useState<Role>(raw ? JSON.parse(raw) : 'user');
  const updater = useCallback((updateValue: Role | null, remove = false) => {
    setValue(updateValue || 'user');
    if (remove) {
      sessionStorage.removeItem(KEY);
    } else {
      sessionStorage.setItem(KEY, JSON.stringify(updateValue));
    }
  }, []);

  useEffect(() => {
    setValue(raw ? JSON.parse(raw) : 'user' || null);
  }, [setValue, raw]);

  return [value, updater];
};
