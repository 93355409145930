import { useEffect, useState, FC } from 'react';
import { Paper, Divider, Typography, Stack } from '@mui/material';
import { PullOut } from 'model/Productivity/PullOut';
import { round } from 'api/calc/Calc';
import {
  calcPullOutBoldingTime,
  calcPullOutConstructionTimePerUnit,
  calcPullOutSteelSheetTypeConstant,
  calcPullOutWorkingTimePerUnit,
} from 'api/calc/PullOut';
import { WORK_TIME } from 'store/Master';
import { PULL_OUT_BETA, PULL_OUT_T1_PREPARATION_TIME } from 'store/PullOut';

interface Props {
  productivity: PullOut;
  open: boolean;
}

interface ConstructionValue {
  beta: number;
  tc: number;
  t1: number;
  t2: number;
  t3: number;
  k: number;
  f: number;
  t: number;
  days: number;
  quantity: number;
}

const CalculationResults: FC<Props> = ({ productivity, open }) => {
  const [value, setValue] = useState<ConstructionValue>({
    beta: 0,
    tc: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    k: 0,
    f: 0,
    t: 0,
    days: 0,
    quantity: 0,
  });
  useEffect(() => {
    const t3 = calcPullOutBoldingTime(
      productivity.weltingBoltMountingTime,
      productivity.weltingBoltMountingCount,
      productivity.steelMaterialType
    );
    const t1 = PULL_OUT_T1_PREPARATION_TIME;
    const k = calcPullOutSteelSheetTypeConstant(productivity.steelMaterialType);
    const f = round(1 + productivity.factorTotal);
    const t = WORK_TIME;
    const beta = PULL_OUT_BETA;
    const t2 = calcPullOutWorkingTimePerUnit(productivity.drivenLength, beta, k);
    const tc = calcPullOutConstructionTimePerUnit(t1, t2, t3, f);
    const days = round(tc / 60 / t, 3);
    const quantity = round((t * 60) / tc, 2);
    setValue({ beta, tc, t1, t2, t3, k, f, t, days, quantity });
  }, [productivity, setValue]);
  if (!open) {
    return <div />;
  }
  return (
    <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, position: 'sticky', top: 0, maxWidth: 450 }}>
      <Stack spacing={2} m={2}>
        <div>
          <Typography variant="subtitle2">Tc= (T1 + T2 + T3) * F</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2= (α * L * β) * K</Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle2">Tc:1単位当たり施工時間</Typography>
          <Typography variant="body2">{value.tc} min/本</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T1:1単位当たり準備時間</Typography>
          <Typography variant="body2">{value.t1} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2:1単位当たり引抜き時間</Typography>
          <Typography variant="body2">{value.t2} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">β：引抜き定数</Typography>
          <Typography variant="body2">{value.beta}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">K:鋼材形式による係数</Typography>
          <Typography variant="body2">{value.k}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T3:切断時間、ボルト取り外し時間</Typography>
          <Typography variant="body2">{value.t3} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">F:作業係数</Typography>
          <Typography variant="body2">{value.f}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T:作業時間</Typography>
          <Typography variant="body2">{value.t} h/日</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">1単位当たり施工日数 Tc/(T (6.2) * 60)</Typography>
          <Typography variant="body2">{value.days} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">日当たり施工数量</Typography>
          <Typography variant="body2">{value.quantity} (枚・本)/日</Typography>
        </div>
      </Stack>
    </Paper>
  );
};

export default CalculationResults;
