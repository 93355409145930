import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { Project } from 'model/Project';

interface ProjectState {
  project: Project | undefined;
}

const projectState = atom<ProjectState>({
  key: 'project',
  default: {
    project: undefined,
  },
});

export const useProjectState = () => useRecoilValue(projectState);
export const useProjectMutators = () => {
  const setState = useSetRecoilState(projectState);
  const setProject = useCallback(
    (project: Project) => {
      return setState((previousState: ProjectState) => {
        return {
          ...previousState,
          project,
        };
      });
    },
    [setState]
  );

  return [setProject];
};
