import { useState, FC, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { EditableColumns, ItemizedStatementDataGrid } from 'component/ItemizedStatementDataGrid';
import { Productivity } from 'model/Productivity';
import { WorkType } from 'model/WorkType';
import Dialog from 'component/Dialog';
import { User } from 'model/User';

interface Props {
  items: Productivity[];
  users: User[];
  onClickNew?: () => void;
  onClickCopy?: (selectItem: Productivity[]) => void;
  onClickDelete?: (selectItem: Productivity[]) => void;
  onClickExportReport?: (selectItem: Productivity[], type: string) => void;
  onClickExportItemizedReport?: () => void;
  onChangeRow?: (id: string, prop: EditableColumns, value: string | number) => void;
  onSelectedItem?: (item: Productivity) => void;
}

const ItemizedStatement: FC<Props> = ({
  items,
  users,
  onClickNew,
  onClickCopy,
  onClickDelete,
  onClickExportReport,
  onClickExportItemizedReport,
  onChangeRow,
  onSelectedItem,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [existsSelectedItem, setExistsSelectedItem] = useState(false);
  const existsItemizedStatementReport = items.length > 0;

  const isDisabledUnitPriceButton = () => {
    if (selectionModel.length === 1) {
      // 運搬を選択していた場合クリック不可とする
      return items.find((i: Productivity) => i.id && selectionModel.includes(i.id))?.workType === WorkType.CARRY;
    }
    return !existsSelectedItem;
  };

  const handleCheck = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };

  const handleCopy = () => {
    onClickCopy && onClickCopy(items.filter((item: Productivity) => item.id && selectionModel.includes(item.id)));
    setSelectionModel([]);
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCancelDialog = () => setOpenDialog(false);
  const handleDelete = () => {
    onClickDelete && onClickDelete(items.filter((item: Productivity) => item.id && selectionModel.includes(item.id)));
    setSelectionModel([]);
    setOpenDialog(false);
  };
  const handleExportUnitPriceReport = () => {
    onClickExportReport &&
      onClickExportReport(
        items.filter((item: Productivity) => item.id && selectionModel.includes(item.id)),
        'unit-price'
      );
  };
  const handleExportPriceReport = () => {
    onClickExportReport &&
      onClickExportReport(
        items.filter((item: Productivity) => item.id && selectionModel.includes(item.id)),
        'first-price'
      );
  };
  const handleExportItemizedStatementReport = () => {
    onClickExportItemizedReport && onClickExportItemizedReport();
  };

  const handleChangeRow = (id: string, prop: EditableColumns, value: string | number) => {
    onChangeRow && onChangeRow(id, prop, value);
  };
  const handleClickName = (item: Productivity) => {
    onSelectedItem && onSelectedItem(item);
  };
  useEffect(() => setExistsSelectedItem(selectionModel && selectionModel.length > 0), [selectionModel]);

  return (
    <Grid container spacing={3}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        direction="row"
        alignItems="flex-start"
        component="div"
      >
        <Grid item>
          <Button variant="contained" onClick={onClickNew}>
            新規作成
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={handleCopy} disabled={!existsSelectedItem}>
            コピー
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleExportUnitPriceReport}
            disabled={isDisabledUnitPriceButton()}
          >
            単価表
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={handleExportPriceReport} disabled={!existsSelectedItem}>
            代価表
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleExportItemizedStatementReport}
            disabled={!existsItemizedStatementReport}
          >
            積算内訳書
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            sx={{ ml: 1 }}
            onClick={handleOpenDialog}
            disabled={!existsSelectedItem}
          >
            削除
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ItemizedStatementDataGrid
          items={items}
          users={users}
          selectionModel={selectionModel}
          setSelectionModel={handleCheck}
          onChange={handleChangeRow}
          onClickName={handleClickName}
        />
      </Grid>
      <Dialog
        open={openDialog}
        contents="本当に削除しますか？削除するとデータを元に戻せなくなります。"
        agreeName="削除する"
        onAgree={handleDelete}
        cancelName="キャンセル"
        onCancel={handleCancelDialog}
      />
    </Grid>
  );
};

export default ItemizedStatement;
