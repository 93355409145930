import { DrivenPile, InjectionMaterial } from 'model/Master';
import { HydraulicMachine } from 'model/Productivity';

export const DrivenPileItems: DrivenPile[] = [
  {
    name: 'H-150',
    value: 'H-150',
    drillingDiameter: 300,
    designPileDiameter: {
      value: 212,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'H-200',
    value: 'H-200',
    drillingDiameter: 400,
    designPileDiameter: {
      value: 283,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'H-250',
    value: 'H-250',
    drillingDiameter: 450,
    designPileDiameter: {
      value: 354,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'H-300',
    value: 'H-300',
    drillingDiameter: 500,
    designPileDiameter: {
      value: 424,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'H-350',
    value: 'H-350',
    drillingDiameter: 550,
    designPileDiameter: {
      value: 495,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'H-400',
    value: 'H-400',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 566,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'SP Ⅲ型',
    value: 'SP_3',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 600,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'SP Ⅳ型',
    value: 'SP_4',
    drillingDiameter: 600,
    designPileDiameter: {
      value: 600,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: '267.4',
    value: '267_4',
    drillingDiameter: 450,
    designPileDiameter: {
      value: 267.4,
      min: 220,
      max: 270,
    },
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InjectionMaterialItems: InjectionMaterial[] = [
  {
    name: 'なし',
    value: '-',
    rate: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: 'モルタル・ミルク',
    value: 'mortarl',
    rate: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    name: '購入砂',
    value: 'sand',
    rate: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

// FIXME:Excel上「日当たり作業時間」として入力できる項目のため、今後外から設定できるよう修正が必要
// 2024/5/30 お客様からの指示があり6.2→6.1に変更
/**
 * T：作業時間 h/日
 */
export const WORK_TIME = 6.1;

/** 使用機器 RX3300  */
export const MACHINE_RX3300 = 'rx3300';

/** 使用機器 RX2300  */
export const MACHINE_RX2300 = 'rx2300';

export const hydraulicVibroHammer: HydraulicMachine[] = [
  {
    key: MACHINE_RX2300,
    value: MACHINE_RX2300,
    name: '油圧ﾊﾞｲﾌﾞﾛﾊﾝﾏ',
    rentFee: 28400,
    order: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: MACHINE_RX3300,
    value: MACHINE_RX3300,
    name: '油圧ﾊﾞｲﾌﾞﾛﾊﾝﾏ',
    rentFee: 37600,
    order: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const hydraulicAuger: HydraulicMachine[] = [
  {
    key: MACHINE_RX2300,
    value: MACHINE_RX2300,
    name: '油圧オーガ',
    rentFee: 18200,
    order: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: MACHINE_RX3300,
    value: MACHINE_RX3300,
    name: '油圧オーガ',
    rentFee: 24200,
    order: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
