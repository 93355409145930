import { useEffect, useMemo, FC, useCallback, useState } from 'react';
import { Grid, Paper, Typography, InputAdornment, Box, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectItem } from 'component/Select';
import { TextField } from 'component/TextField';
import Copyright from 'component/Copyright';
import { PullOut } from 'model/Productivity/PullOut';
import CalculationResults from './CalculationResults';
import {
  SteelMaterialItems,
  SteelMaterialTypeItems,
  SteelMaterialTypeItemsForHSteel,
  SteelMaterialTypeItemsForSteelSheetPiles,
  WeltingBoltMountingCountItems,
  WeltingBoltMountingTimeItems,
  WorkCorrectionFactorCategory,
} from 'store/PullOut';
import { WorkCorrectionFactorList } from 'store/PullOut';
import WorkCorrection from 'component/Productivity/WorkCorrection';
import { MasterData } from 'model/Master';

interface Props {
  productivity: PullOut;
  openCalculate: boolean;
  machines: MasterData[];
  onClickBack: () => void;
  onClickConfirm: (productivity: PullOut) => void;
  updateProductivity: (prop: keyof PullOut, productivity: PullOut) => void;
  onUpdate?: (productivity: PullOut) => void;
}

const schema = z.object({
  name: z.string().min(1),
  specification: z.string().min(1),
  usedMachine: z.string(),
  steelMaterial: z.string(), // 鋼材種類
  steelMaterialType: z.string(), // 鋼材種類 寸法・型
  steelMaterialLength: z.number().nonnegative(), //`鋼材長
  drivenLength: z.number().nonnegative(), // 打込み長
  weltingBoltMountingTime: z.string(), // 溶接・ボルト取り付け
  weltingBoltMountingCountSelect: z.string(), // 溶接・ボルト取り付け回数
  steelSheetType: z.string(), // K:鋼矢板形式
  otherFactor: z.object({
    name: z.string(),
    value: z.number(),
  }),
});

const Create: FC<Props> = ({ productivity, machines, openCalculate, onClickBack, onClickConfirm, onUpdate }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  console.log('errors:', errors);
  const [correctionFactors, setCorrectionFactors] = useState<string[]>(productivity.correctionFactors);

  // eslint-disable-next-line
  const convertSelectItem = (item: any): SelectItem => ({
    key: item?.key || '',
    label: item?.name,
    value: item?.key || '',
  });
  const machineItems = useMemo(() => machines.map(convertSelectItem), [machines]);
  const steelMaterialItems = useMemo(() => SteelMaterialItems.map(convertSelectItem), []);
  const steelMaterialTypeItems = useMemo(() => SteelMaterialTypeItems.map(convertSelectItem), []);
  const steelMaterialTypeItemsForHSteel = useMemo(() => SteelMaterialTypeItemsForHSteel.map(convertSelectItem), []);
  const steelMaterialTypeItemsForSteelSheetPiles = useMemo(
    () => SteelMaterialTypeItemsForSteelSheetPiles.map(convertSelectItem),
    []
  );
  const weltingBoltMountingTimeItems = useMemo(() => WeltingBoltMountingTimeItems.map(convertSelectItem), []);
  const weltingBoltMountingCountItems = useMemo(() => WeltingBoltMountingCountItems.map(convertSelectItem), []);
  const steelSheetTypeItems = useMemo(() => SteelMaterialTypeItems.map(convertSelectItem), []);
  const [selectableItems, setSelectableItems] = useState(steelMaterialTypeItems);

  // eslint-disable-next-line
  const parseProductivity = (data: any): PullOut => {
    const { weltingBoltMountingCountSelect } = data;
    const weltingBoltMountingCount = Number.parseInt(weltingBoltMountingCountSelect);
    const { otherFactor } = data;
    const factorTotal = calcFactorTotal(correctionFactors) + otherFactor.value;
    return { ...productivity, ...data, weltingBoltMountingCount, factorTotal };
  };

  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    onClickConfirm(parseProductivity(data));
  };

  const calcFactorTotal = (items: string[]): number =>
    WorkCorrectionFactorList.filter((factor) => items.includes(factor.key || '')).reduce(
      (prev, current) => prev + (current.value || 0),
      0
    );

  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      const newItems = ((items: string[]): string[] => {
        const index = items.indexOf(key);
        if (checked && index === -1) {
          return [...items, key];
        } else if (!checked && index !== -1) {
          return items.filter((c) => key !== c);
        }
        return items;
      })(correctionFactors);
      setCorrectionFactors(newItems);
      const factorTotal = calcFactorTotal(newItems) + productivity.otherFactor.value;
      onUpdate &&
        onUpdate({
          ...productivity,
          correctionFactors: newItems,
          factorTotal,
        });
    },
    [correctionFactors, onUpdate, productivity]
  );

  useEffect(() => {
    const subscription = watch((value) => onUpdate && onUpdate(parseProductivity(value)));
    return () => subscription.unsubscribe();
  }, [onUpdate, parseProductivity, watch]);

  useEffect(() => {
    // NOTE: 鋼材種類 選択後の 鋼材種類寸法・型の選択肢変更と選択内容のクリア
    const hSteel = 'h';
    const steelSheetPiles = 'steel';

    if (productivity.steelMaterial === hSteel) {
      setSelectableItems(steelMaterialTypeItemsForHSteel);
      if (!steelMaterialTypeItemsForHSteel.find((item) => item.value === productivity.steelMaterialType)) {
        setValue('steelMaterialType', '');
      }
    } else if (productivity.steelMaterial === steelSheetPiles) {
      setSelectableItems(steelMaterialTypeItemsForSteelSheetPiles);
      if (!steelMaterialTypeItemsForSteelSheetPiles.find((item) => item.value === productivity.steelMaterialType)) {
        setValue('steelMaterialType', '');
      }
    }
  }, [
    productivity.steelMaterial,
    productivity.steelMaterialType,
    setValue,
    steelMaterialTypeItems,
    steelMaterialTypeItemsForHSteel,
    steelMaterialTypeItemsForSteelSheetPiles,
  ]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {productivity.remark || '新しい歩掛り'}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  label="名称"
                  variant="standard"
                  defaultValue={productivity.name}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="specification"
                  name="specification"
                  label="規格"
                  variant="standard"
                  defaultValue={productivity.specification}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="machine"
                  labelId="used-machine-label"
                  label="使用機械"
                  items={machineItems}
                  name="usedMachine"
                  defaultValue={productivity.usedMachine}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="material-kinds"
                  labelId="material-kinds-label"
                  label="鋼材種類"
                  items={steelMaterialItems}
                  name="steelMaterial"
                  defaultValue={productivity.steelMaterial}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="material-size"
                  labelId="material-size-label"
                  label="鋼材種類寸法・型"
                  items={selectableItems}
                  name="steelMaterialType"
                  defaultValue={productivity.steelMaterialType}
                  control={control}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="steel-material-length"
                  name="steelMaterialLength"
                  label="鋼材長"
                  variant="standard"
                  type="number"
                  defaultValue={productivity.steelMaterialLength}
                  control={control}
                  InputProps={{
                    inputProps: { min: 0, step: '0.1' },
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="driven-length"
                  name="drivenLength"
                  label="L:引抜き長"
                  variant="standard"
                  type="number"
                  defaultValue={productivity.drivenLength}
                  control={control}
                  InputProps={{
                    inputProps: { min: 0, step: '0.1' },
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="material-kinds"
                  labelId="material-kinds-label"
                  label="K:鋼材形式"
                  items={steelSheetTypeItems}
                  name="steelSheetType"
                  defaultValue={productivity.steelSheetType}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="welting-bolt-mounting-time"
                  labelId="welting-bolt-mounting-time"
                  label="切断・ボルト取り外し"
                  items={weltingBoltMountingTimeItems}
                  name="weltingBoltMountingTime"
                  defaultValue={productivity.weltingBoltMountingTime}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="welting-bolt-mounting-count"
                  labelId="welting-bolt-mounting-count"
                  label="切断・ボルト取り外し回数"
                  items={weltingBoltMountingCountItems}
                  name="weltingBoltMountingCountSelect"
                  defaultValue={productivity.weltingBoltMountingCountSelect}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <WorkCorrection
                  title="作業補正係数"
                  selected={correctionFactors}
                  workCorrectionCategories={WorkCorrectionFactorCategory}
                  workCorrections={WorkCorrectionFactorList}
                  onChange={handleChangeCheckbox}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  その他（作業条件・制約、使用機器、N値（非常に高い）など別途考慮）
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-name"
                  name="otherFactor.name"
                  label="条件"
                  variant="standard"
                  defaultValue={productivity.otherFactor?.name || ''}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-value"
                  name="otherFactor.value"
                  label="作業係数"
                  variant="standard"
                  type="number"
                  InputProps={{
                    inputProps: { step: '0.1' },
                  }}
                  defaultValue={productivity.otherFactor?.value || 0}
                  control={control}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
                戻る
              </Button>
              <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
                確認
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <CalculationResults productivity={productivity} open={openCalculate} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Create;
