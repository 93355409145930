import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export interface LoginState {
  isLogin: boolean;
}

export const loginState = atom<LoginState>({
  key: 'login',
  default: {
    isLogin: false,
  },
});

export const useLoginState = () => useRecoilValue(loginState);
export const useLoginMutators = () => {
  const setState = useSetRecoilState(loginState);
  const setLogin = useCallback(
    (isLogin: boolean) => {
      return setState((previousState: LoginState) => {
        return {
          ...previousState,
          isLogin,
        };
      });
    },
    [setState]
  );

  return [setLogin];
};
