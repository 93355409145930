import { amountRoundUp, round } from './Calc';
import { calcDaysOfChippingHoles } from './QualitySoil';
import { BondingDaysItems, FillingDaysItems, PreparationDaysItems } from 'store/Productivity';
import { AirCompressor, HoleHammer, HoleHammerSpecification } from 'model/Productivity/HoleHammer';
import { Municipality } from 'model/Master';
import { BondingDaysFactors, HoleHammerSpecifications, AirCompressors, Cranes } from 'store/HoleHammer';
import { calcCraneUnitPrice } from './Crane';
import { createDownTheDriveUnitPriceList } from './UnitPrice';
import { Machine } from 'model/Productivity';
import { RentFeeCalculation } from 'model/RentFeeCalculation';

export const calcInstallationDay = (preparationDays: string, scrwJointTimes: number): number => {
  const preparation = PreparationDaysItems.filter((p) => p.key === preparationDays).reduce((sum, e) => sum + e.n, 0);
  return Number((preparation + scrwJointTimes * 0.1).toFixed(2));
};

export const calcFillingDays = (preparationDays: string): number => {
  const preparation = FillingDaysItems.filter((p) => p.key === preparationDays).reduce((sum, e) => sum + e.n, 0);
  return preparation;
};

export const calcBondingDays = (bonding: string, bondingTimes: number): number => {
  const preparation = BondingDaysItems.filter((p) => p.key === bonding).reduce((sum, e) => sum + e.n, 0);
  return preparation * bondingTimes;
};

export const calcInjectionValume = (drillingDiameter = 0, injectionCastingLength = 0, loss = 0): number => {
  const num = (((((drillingDiameter / 1000) * drillingDiameter) / 1000) * 3.14) / 4) * injectionCastingLength * loss;
  return round(num);
};

export const calcAirCompressorUnitPrice = (municipality: Municipality, airCompressor?: AirCompressor) => {
  if (!airCompressor) {
    return 0;
  }
  const machineSubtotal = round((airCompressor?.quantity || 0) * (airCompressor?.unitPrice || 0));
  const lightOilSubtotal = round((airCompressor?.requiredOilAmount || 0) * (municipality.lightOil || 0));
  return amountRoundUp(machineSubtotal + lightOilSubtotal, 1);
};

export const calcHoleHammerUnitPrice = (
  productivity: HoleHammer,
  rentFeeCalculations: RentFeeCalculation[],
  specification?: HoleHammerSpecification
) => {
  if (!specification) return 0;

  const holeHammerPrice = calcDownTheHoleHammerPrice(rentFeeCalculations, specification) || 0;
  const factors = productivity.correctionFactors;

  if (specification && factors && factors.length > 0) {
    // NOTE: サイクルハンマが選択されている場合は、削孔径によって損料を変更する、なければ既存のホールハンマー損料を使用する
    if (factors.includes('cycle_hanmer')) {
      const res = calcCycleHammerPrice(rentFeeCalculations, specification) || holeHammerPrice;
      return res;
    }
    // NOTE: 拡径ビットが選択されている場合は、削孔径によって損料を変更する、なければ既存のホールハンマー損料を使用する
    if (factors.includes('expanded_diameter_bit')) {
      const res = calcExpandedDiameterBitPrice(rentFeeCalculations, specification) || holeHammerPrice;
      return res;
    }
  }

  return holeHammerPrice;
};

export const calcTc = (productivity: HoleHammer): number => {
  const t1 = calcDaysOfChippingHoles(productivity.qualitySoil);
  const t2 = calcInstallationDay(productivity.preparationDays, productivity.scrwJointTimes);
  const t3 = calcFillingDays(productivity.fillingDays);
  const t4 = calcBondingDays(productivity.bondingDays, productivity.joiningTimes);
  const a = Number(productivity.correctionFactorValue);
  const f = round(1 + productivity.factorTotal);
  return round((t1 + t2 + t3 + t4 * a) * f);
};

export const calcProductivityUnit = (
  productivity: HoleHammer,
  municipality: Municipality,
  machine: Machine,
  rentFeeCalculations: RentFeeCalculation[]
): number => {
  const tc = calcTc(productivity);
  const holeHammerOtherExpensesRate = 0.28;
  const quantity = tc;

  const multiplication = (a: number, b: number) => round(a * b, 0);

  const gCECaretakerSubtotal = multiplication(quantity, municipality.generalCivilEngineeringCaretaker);
  const scaffoldWorkerSubtotal = multiplication(quantity, municipality.scaffoldWorker);
  const specialWorkerSubtotal = multiplication(quantity, municipality.specialWorker);
  const ordinaryWorkersSubtotal = multiplication(quantity, municipality.ordinaryWorkers);

  const weldersQuantityFactor = () => {
    const item = BondingDaysItems.find((bdi) => (bdi.key = productivity.bondingDays));
    if (item?.name.includes('溶接')) {
      return BondingDaysFactors.find((bdf) => bdf.key === productivity.bondingDays)?.value || 0;
    }
    return 0;
  };
  const weldersQuantity = multiplication(quantity, weldersQuantityFactor());
  const weldersSubtotal = multiplication(weldersQuantity, municipality.welders);

  const usedMachineUnitPrice = createDownTheDriveUnitPriceList(municipality, machine);
  const pileDrivingSubtotal = multiplication(quantity, usedMachineUnitPrice?.total || 0);

  const airCompressor7_5 = AirCompressors.find((acp) => acp.key === '7_5m3');
  const airCompressor7_5OperationUnitPrice = calcAirCompressorUnitPrice(municipality, airCompressor7_5);
  const airCompressor7_5OperationQuantity = productivity.airCompressor7_5 * quantity;
  const airCompressor7_5OperationSubtotal = multiplication(
    airCompressor7_5OperationUnitPrice,
    airCompressor7_5OperationQuantity
  );

  const airCompressor18 = AirCompressors.find((acp) => acp.key === '18m3');
  const airCompressor18OperationUnitPrice = calcAirCompressorUnitPrice(municipality, airCompressor18);
  const airCompressor18OperationQuantity = productivity.airCompressor18 * quantity;
  const airCompressor18OperationSubtotal = multiplication(
    airCompressor18OperationUnitPrice,
    airCompressor18OperationQuantity
  );

  const holeHammerSpecification = HoleHammerSpecifications.find((hs) => hs.drivenPile === productivity.drivenPile);
  const holeHammerUnitPrice = calcHoleHammerUnitPrice(productivity, rentFeeCalculations, holeHammerSpecification);
  const holeHammerSubtotal = multiplication(quantity, holeHammerUnitPrice);
  const crane = Cranes.find((cp) => cp.key === productivity.crane);
  const craneUnitPrice = multiplication(quantity, calcCraneUnitPrice(municipality, crane));

  const subTotal =
    gCECaretakerSubtotal +
    scaffoldWorkerSubtotal +
    specialWorkerSubtotal +
    ordinaryWorkersSubtotal +
    weldersSubtotal +
    pileDrivingSubtotal +
    airCompressor7_5OperationSubtotal +
    airCompressor18OperationSubtotal +
    holeHammerSubtotal +
    craneUnitPrice;
  const otherExpensesPrice = multiplication(subTotal, holeHammerOtherExpensesRate);
  const beforeRoundTotal = subTotal + otherExpensesPrice;
  return round(beforeRoundTotal / 100, 0) * 100;
};

const REGEX_MM = /[0-9]*mm/;

/**
 * ダウンザホールハンマの金額計算を行う。
 *
 * 建設機械等損料算定表の中から「ダウンザホールハンマ」と記載あるデータのうち、
 * ダウンザホールハンマ歩掛りの削孔径範囲に等しいダウンザホールハンマ機種を選ぶ。
 *
 * 選んだ機種の「参考 運転1日当たり換算値 損料(円)（13）を参照する
 *
 * @param rentFee
 * @param specification
 * @returns
 */
const calcDownTheHoleHammerPrice = (rentFee: RentFeeCalculation[], specification: HoleHammerSpecification) => {
  const result = rentFee
    .filter((rf) => new RegExp(/^ﾀﾞｳﾝｻﾞﾎｰﾙﾊﾝﾏ|^ダウンザホールハンマ/).test(rf.category))
    .find((ch) => {
      const convertedToHalfWidth = convertToHalfWidth(ch.specifications);
      const mm = new RegExp(REGEX_MM, 'g').exec(convertedToHalfWidth);
      if (!mm || mm.length === 0) {
        return false;
      }
      const cycleHammerDrillingDiameter = mm[0].replaceAll('m', '');
      const spec = convertToHalfWidth(specification.specification.label).replaceAll('m', '').replace(/～/, '~');

      if (spec.includes('~')) {
        const [min, max] = spec.split('~').map(Number);
        return min <= Number(cycleHammerDrillingDiameter) && max >= Number(cycleHammerDrillingDiameter);
      }
      return spec === cycleHammerDrillingDiameter;
    });

  return result?.amountToRentConversionOnOperating;
};

/**
 * 全角英数字を半角英数字に変換する
 *
 * @param string
 * @returns string
 */
const convertToHalfWidth = (withFullWidthString: string) => {
  const fullNum = '０１２３４５６７８９';
  const reFullNum = new RegExp('[' + fullNum + ']', 'g');
  return withFullWidthString
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
    .replace(reFullNum, (m) => fullNum.indexOf(m).toString());
};

/**
 * サイクルハンマーを選択した場合のダウンザホールハンマの金額計算を行う。
 *
 * 建設機械等損料算定表の中から「サイクルハンマ」と記載あるデータのうち、
 * ダウンザホールハンマ歩掛りの削孔径範囲内に収まっているサイクルハンマー機種を選ぶ。
 *
 * 選んだ機種の「参考 供用1日当たり換算値 損料(円)（15）を参照する
 *
 * @param rentFee
 * @param specification
 * @returns
 */
const calcCycleHammerPrice = (rentFee: RentFeeCalculation[], specification: HoleHammerSpecification) => {
  return rentFee
    .filter((rf) => new RegExp(/^サイクルハンマ|^ｻｲｸﾙﾊﾝﾏ/).test(rf.category))
    .find((ch) => {
      const convertedToHalfWidth = convertToHalfWidth(ch.specifications);
      const mm = new RegExp(REGEX_MM, 'g').exec(convertedToHalfWidth);
      if (mm && mm.length === 1) {
        const cycleHammerDrillingDiameter = Number.parseInt(mm[0].replaceAll('m', ''));
        return (
          specification.specification.min <= cycleHammerDrillingDiameter &&
          specification.specification.max >= cycleHammerDrillingDiameter
        );
      }
    })?.amountToRentConversionOnService;
};

/**
 * 拡径ビットを選択した場合のダウンザホールハンマの金額計算を行う。
 *
 * 建設機械等損料算定表の中から「サイクルハンマ」と記載あるデータのうち、
 * ダウンザホールハンマ歩掛りの削孔径範囲内に収まっている拡径ビット機種を選ぶ。
 *
 * 選んだ機種の「参考 供用1日当たり換算値 損料(円)（15）を参照する
 *
 * @param rentFee
 * @param specification
 * @returns
 */
const calcExpandedDiameterBitPrice = (rentFee: RentFeeCalculation[], specification: HoleHammerSpecification) => {
  return rentFee
    .filter((rf) => new RegExp(/φ*.[拡縮|拡径][ビット|ﾋﾞｯﾄ]/).test(rf.specifications))
    .find((edb) => {
      const convertedToHalfWidth = convertToHalfWidth(edb.specifications);
      const mm = new RegExp(/φ.[0-9]*/, 'g').exec(convertedToHalfWidth);
      if (mm && mm.length === 1) {
        const expandedDiameterBitDrillingDiameter = Number.parseInt(mm[0].replace('φ', ''));
        return (
          specification.specification.min <= expandedDiameterBitDrillingDiameter &&
          specification.specification.max >= expandedDiameterBitDrillingDiameter
        );
      }
    })?.amountToRentConversionOnService;
};
