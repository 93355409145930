import { FC } from 'react';
import { Grid, styled, Typography } from '@mui/material';
import { RentFeeCalculation, RentFeeCalculationNote } from 'model/RentFeeCalculation';

const Root = styled('div')(({ theme }) => ({
  breakAfter: 'page',
  width: '297mm',
  height: '210mm',
  padding: theme.spacing(1),
  '@page': {
    size: 'landscape',
    margin: theme.spacing(0),
  },
  table: {
    border: '2px solid',
    borderCollapse: 'collapse',
    whiteSpace: 'pre-wrap',
    fontSize: theme.typography.caption.fontSize,
    thead: {
      border: '2px solid',
    },
    th: {
      fontWeight: 'normal',
      border: '1px solid',
      textAlign: 'center',
    },
    'td:first-of-type': {
      borderRight: '2px solid',
    },
    td: {
      width: 45,
      border: '1px solid',
      verticalAlign: 'bottom',
    },
  },
}));

const Td = styled('td')(() => ({
  textAlign: 'center',
  wordBreak: 'break-all',
}));
const Amount = styled('td')(() => ({
  textAlign: 'right',
  wordBreak: 'break-all',
}));
const Nowrap = styled('span')(() => ({
  whiteSpace: 'nowrap',
}));
const InlineBlock = styled('span')(() => ({
  display: 'inline-block',
}));

interface Props {
  date: string;
  reportDataList: RentFeeCalculation[];
  notes?: RentFeeCalculationNote[];
}

const RXMachineReport: FC<Props> = ({ date, reportDataList, notes }) => {
  return (
    <Root>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Grid item />
        <Grid item>
          <Typography variant="subtitle1" component="span">
            {'建　設　機　械　等　損　料　算　定　表'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span">
            {date}
          </Typography>
        </Grid>
      </Grid>
      <table>
        <thead>
          <tr>
            <th rowSpan={3} style={{ borderRight: '2px solid' }}></th>
            <th rowSpan={3}>分類コード</th>
            <th colSpan={3}>規格</th>
            <th rowSpan={3}>
              (1)
              <br />
              基礎
              <br />
              価格
              <br />
              (千円)
            </th>
            <th rowSpan={3}>
              (2)
              <br />
              標準使用年数
              <br />
              (年)
            </th>
            <th colSpan={3}>年間標準</th>
            <th rowSpan={3}>
              (6)
              <br />
              維持修理費率
              <br />
              (%)
            </th>
            <th rowSpan={3}>
              (7)
              <br />
              年間管理費率
              <br />
              (%)
            </th>
            <th rowSpan={3} style={{ width: 4, borderRight: '0px solid' }}>
              <span
                style={{
                  writingMode: 'vertical-rl',
                  margin: 0,
                  whiteSpace: 'nowrap',
                  lineHeight: '1rem',
                }}
              >
                残存率
              </span>
              <br />
              (%)
            </th>
            <th
              colSpan={2}
              style={{
                borderLeft: '0px solid',
              }}
            >
              運転1時間当り
            </th>
            <th colSpan={2}>供用1時間当り</th>
            <th colSpan={5}>参考</th>
          </tr>
          <tr>
            <th rowSpan={2}>諸元</th>
            <th rowSpan={2}>
              機関出力
              <br />
              <Nowrap>(PS)</Nowrap>
            </th>
            <th rowSpan={2}>
              機械重量
              <br />
              (t)
            </th>
            <th rowSpan={2}>
              (3)
              <br />
              運転
              <br />
              時間
              <br />
              <Nowrap>(時間)</Nowrap>
            </th>
            <th rowSpan={2}>
              (4)
              <br />
              運転日数
              <br />
              (日)
            </th>
            <th rowSpan={2}>
              (5)
              <br />
              供用日数
              <br />
              (日)
            </th>
            <th rowSpan={2}>
              (8)
              <br />
              損料率
              <br />
              <Nowrap>
                (×10<sup>-6</sup>)
              </Nowrap>
            </th>
            <th rowSpan={2}>
              (9)
              <br />
              損料
              <br />
              (円)
            </th>
            <th rowSpan={2}>
              (10)
              <br />
              損料率
              <br />
              <Nowrap>
                (×10<sup>-6</sup>)
              </Nowrap>
            </th>
            <th rowSpan={2}>
              (11)
              <br />
              損料
              <br />
              (円)
            </th>
            <th colSpan={2} style={{ fontSize: 8 }}>
              運転1時間当り<InlineBlock>換算値</InlineBlock>
            </th>
            <th colSpan={2} style={{ fontSize: 8 }}>
              供用1日当り<InlineBlock>換算値</InlineBlock>
            </th>
            <th rowSpan={2}>
              摘要
              <br />
              消費燃料
              <br />
              <Nowrap>ℓ/ｈ</Nowrap>
            </th>
          </tr>
          <tr>
            <th>
              (12)
              <br />
              損料率
              <br />
              <Nowrap>
                (×10<sup>-6</sup>)
              </Nowrap>
            </th>
            <th>
              (13)
              <br />
              損料
              <br />
              (円)
            </th>
            <th>
              (14)
              <br />
              損料率
              <br />
              <Nowrap>
                (×10<sup>-6</sup>)
              </Nowrap>
            </th>
            <th>
              (15)
              <br />
              損料
              <br />
              (円)
            </th>
          </tr>
        </thead>
        <tbody>
          {reportDataList.map((reportData) => (
            <tr key={reportData.no}>
              <Td sx={{ width: 30, textAlign: 'center' }}>{reportData.no}</Td>
              <Td sx={{ minWidth: 150, textAlign: 'left', verticalAlign: 'bottom' }}>{reportData.category}</Td>
              <Td sx={{ minWidth: 140, textAlign: 'left', verticalAlign: 'bottom' }}>{reportData.specifications}</Td>
              <Td>{reportData.enginePower}</Td>
              <Td>{reportData.weight}</Td>
              <Amount>{reportData.basicPrice.toLocaleString()}</Amount>
              <Td>{reportData.standardUsePeriodOfYear}</Td>
              <Td>{reportData.operationHours > 0 && reportData.operationHours}</Td>
              <Td>{reportData.operationDays}</Td>
              <Td>{reportData.serviceDays}</Td>
              <Td>{reportData.maintenanceRepairCostRate}</Td>
              <Td>{reportData.annualManagementCostRate}</Td>
              <Td>{reportData.survivalRate}</Td>
              <Amount>{reportData.rentOnOperatingRate.toLocaleString()}</Amount>
              <Amount>{reportData.amountToRentOnOperating.toLocaleString()}</Amount>
              <Amount>{reportData.rentOnInServiceRate.toLocaleString()}</Amount>
              <Amount>{reportData.amountToRentOnService.toLocaleString()}</Amount>
              <Amount>
                {reportData.rentOnOperatingRateConversionUnit && (
                  <>
                    {reportData.rentOnOperatingRateConversionUnit}
                    <br />
                  </>
                )}
                {reportData.rentOnOperatingRateConversion.toLocaleString()}
              </Amount>
              <Amount>
                {reportData.amountToRentConversionOnOperatingUnit && (
                  <>
                    {reportData.amountToRentConversionOnOperatingUnit}
                    <br />
                  </>
                )}
                {reportData.amountToRentConversionOnOperating.toLocaleString()}
              </Amount>
              <Amount>{reportData.rentOnInServiceRateConversion.toLocaleString()}</Amount>
              <Amount>{reportData.amountToRentConversionOnService.toLocaleString()}</Amount>
              <Td sx={{ minWidth: 90, textAlign: 'left', verticalAlign: 'bottom' }}>
                {reportData.brief}
                {reportData.amountOfFuelUsedPerHour > 0 && (
                  <>
                    <br />
                    {reportData.amountOfFuelUsedPerHour.toFixed(1)}
                  </>
                )}
              </Td>
            </tr>
          ))}
          {notes && notes.length > 0 && (
            <tr style={{ borderTop: '2px solid' }}>
              <td></td>
              <td colSpan={21}>
                {notes.map((n) => (
                  <div key={n.no} style={{ wordBreak: 'break-all' }}>
                    <Typography variant="caption" component="span" sx={{ display: 'inline-block', width: 70 }}>
                      ※{n.no}
                    </Typography>
                    <Typography variant="caption" component="span" sx={{ display: 'inline-block', ml: 1, mr: 1 }}>
                      ・・・
                    </Typography>
                    <Typography variant="caption" component="span">
                      {n.reference}
                    </Typography>
                  </div>
                ))}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Root>
  );
};

export default RXMachineReport;
