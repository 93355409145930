import { createTheme } from '@mui/material';
import { jaJP } from '@mui/x-data-grid';
import { jaJP as coreJaJP } from '@mui/material/locale';

const theme = createTheme(
  {
    typography: {
      subtitle2: {
        fontSize: 16,
      },
      body1: {
        fontSize: 20,
      },
      body2: {
        fontSize: 16,
      },
      button: {
        fontSize: 20,
      },
    },
  },
  jaJP,
  coreJaJP
);

export default theme;
