import { FC } from 'react';
import { Grid, Typography, styled } from '@mui/material';
import { FirstPrices } from 'model/FirstPriceList';

const Root = styled('div')(() => ({
  breakAfter: 'page',
  width: '297mm',
  height: '210mm',
  '@page': {
    size: 'landscape',
    m: 0,
  },
  table: {
    border: '1px solid',
    borderCollapse: 'collapse',
    whiteSpace: 'pre-wrap',
    width: '100%',
    thead: {
      border: '1px solid',
    },
    th: {
      fontWeight: 'normal',
      border: '1px solid',
      textAlign: 'center',
      height: '1.7rem',
    },
    td: {
      border: '1px solid',
      textAlign: 'center',
      height: '1.7rem',
    },
  },
}));

const EmptyRows: FC = () => {
  return (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};

interface Props {
  data: FirstPrices;
  title: string;
  productName?: string;
  specification?: string;
}

const FirstPriceListReport: FC<Props> = ({ data, title, productName, specification }) => {
  const A4_PRINT_MAX_ROWS = 19;
  const incidentalsRowsCount = data.incidentals ? 2 : 1;
  const notRowsCount = data.note ? 2 : 1;
  const showEmptyRowsCount = A4_PRINT_MAX_ROWS - data.details.length - incidentalsRowsCount - notRowsCount;
  return (
    <Root>
      <Grid container direction="row" justifyContent="center" alignItems="center" marginBottom={2.5}>
        <Grid item>
          <Typography variant="h6" sx={{ textDecoration: 'underline', textUnderlineOffset: 5 }}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      {(productName || specification) && (
        <div>
          {productName && `名称:${productName}`} {specification && `規格:${specification}`}
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th colSpan={7}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component="span">{data.reportType}</Typography>
                <Typography component="span" textAlign="right">
                  {data.standard}
                  {data.unit}当たり
                </Typography>
              </div>
            </th>
          </tr>
          <tr>
            <th>名称</th>
            <th>規格</th>
            <th>単位</th>
            <th>数量</th>
            <th>単価</th>
            <th>金額</th>
            <th>摘要</th>
          </tr>
        </thead>
        <tbody>
          {data.details.map((detail, index) => (
            <tr key={index + detail.name}>
              <td style={{ textAlign: 'left' }}>{detail.name}</td>
              <td style={{ textAlign: 'left' }}>{detail.specification}</td>
              <td>{detail.unit}</td>
              <td style={{ textAlign: 'right' }}>{detail.quantity.toFixed(2)}</td>
              <td style={{ textAlign: 'right' }}>{detail.unitPrice && detail.unitPrice.toLocaleString()}</td>
              <td style={{ textAlign: 'right' }}>{detail.subtotal.toLocaleString()}</td>
              <td style={{ textAlign: 'left' }}>{detail.remark}</td>
            </tr>
          ))}
          <EmptyRows />
          {data.incidentals && (
            <tr>
              <td style={{ textAlign: 'left' }}>{data.incidentals.name}</td>
              <td style={{ textAlign: 'left' }}>{data.incidentals.specification}</td>
              <td>{data.incidentals.unit}</td>
              <td style={{ textAlign: 'right' }}>{data.incidentals.quantity}</td>
              <td style={{ textAlign: 'right' }}>
                {data.incidentals.unitPrice && data.incidentals.unitPrice.toLocaleString()}
              </td>
              <td style={{ textAlign: 'right' }}>{data.incidentals.subtotal.toLocaleString()}</td>
              <td style={{ textAlign: 'left' }}>{data.incidentals.remark}</td>
            </tr>
          )}
          <EmptyRows />
          {data.note && (
            <tr>
              <td colSpan={7} style={{ textAlign: 'left' }}>
                <Typography variant="body2" mt={1.5} mb={1.5}>
                  {data.note}
                </Typography>
              </td>
            </tr>
          )}
          {[...Array(showEmptyRowsCount)].map((_, index) => (
            <EmptyRows key={index} />
          ))}
          <tr>
            <td>計</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right' }}>{data.total.toLocaleString()}</td>
            <td></td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>1{data.unit}当たり</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right' }}>{data.pricePerUnit.toLocaleString()}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
};

export default FirstPriceListReport;
