import { Project, ProjectCondition } from 'model/Project';
import { db } from 'api/firebase';
import { getProjects, setProject, setProjects, deleteProjects } from 'api/Project';

export const useReadProjectRepository =
  (): ((condition?: ProjectCondition) => Promise<Project[]>) =>
  async (condition?: ProjectCondition): Promise<Project[]> =>
    await getProjects(db, condition);

export const useWriteProjectRepository =
  (): ((project: Project) => Promise<Project>) =>
  async (project: Project): Promise<Project> => {
    return await setProject(db, project);
  };

export const useWriteProjectsRepository = (): ((projects: Project[]) => void) => async (projects: Project[]) => {
  await setProjects(db, projects);
};
export const useDeleteProjectsRepository = (): ((projects: Project[]) => void) => async (projects: Project[]) => {
  await deleteProjects(db, projects);
};
