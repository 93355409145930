import { useCallback, useEffect, useState, FC } from 'react';
import { Box, Checkbox, Grid, FormControlLabel, Typography } from '@mui/material';
import { WorkCorrectionCategory, WorkCorrectionFactor } from 'model/Productivity';

interface Props {
  title: string;
  selected: string[];
  workCorrectionCategories?: WorkCorrectionCategory[];
  workCorrections?: WorkCorrectionFactor[];
  onChange: (key: string, checked: boolean) => void;
}

const WorkCorrection: FC<Props> = ({ title, selected, workCorrectionCategories, workCorrections, onChange }) => {
  const [checked, setChecked] = useState<string[]>(selected);
  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      onChange(key, checked);
    },
    [onChange]
  );
  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <>
      <Box>
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      {workCorrectionCategories?.map((category) => (
        <Box key={category.key}>
          <Typography variant="subtitle2">{category.name}</Typography>
          <Grid container>
            {workCorrections
              ?.filter((item) => item.category === category.key)
              .map((item) => (
                <Grid item xs={12} sm={item.name.length > 10 ? 6 : 4} key={item.key}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.includes(item.key || '')}
                        name={item.key}
                        onChange={(e) => handleChangeCheckbox(item.key || '', e.target.checked)}
                      />
                    }
                    label={`${item.name}(${item.value?.toFixed(2)})`}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default WorkCorrection;
