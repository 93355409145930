import { MasterData } from 'model/Master';
import { Carry, WorkExpenseRate, WorkProductionRates } from 'model/Productivity/Carry';
import { WorkType } from 'model/WorkType';

export const EmptyCarry: Carry = {
  projectId: '',
  name: '',
  usedMachine: '',
  workType: WorkType.CARRY,
  distance: '',
  work: '',
  unit: '往復',
  quantity: 1,
  injection: false,
};

export const distances: MasterData[] = [
  { id: '100_km', key: '100_km', name: '100km以内', value: '100_km' },
  { id: '200_km', key: '200_km', name: '200km以内', value: '200_km' },
  { id: '300_km', key: '300_km', name: '300km以内', value: '300_km' },
];

export const workContents: MasterData[] = [
  {
    id: 'pre_hole_hammer',
    key: 'pre_hole_hammer',
    name: '先行堀り（ダウンザホールハンマー）+バイブロ',
    value: 'pre_hole_hammer',
  },
  { id: 'pre_auger', key: 'pre_auger', name: '先行堀り（オーガー）+バイブロ', value: 'pre_auger' },
  { id: 'pre', key: 'pre', name: '先行堀り', value: 'pre' },
  { id: 'vibro_hammer_in', key: 'vibro_hammer_in', name: 'バイブロ打込み', value: 'vibro_hammer_in' },
  { id: 'vibro_pull_out', key: 'vibro_pull_out', name: 'バイブロ引抜き', value: 'vibro_pull_out' },
  { id: 'relocation', key: 'relocation', name: '移設', value: 'relocation' },
];

export const workProductionRates: WorkProductionRates[] = [
  {
    id: 'pre_hole_hammer',
    key: 'pre_hole_hammer',
    name: 'pre_hole_hammer',
    value: 2.5,
  },
  {
    id: 'pre_auger',
    key: 'pre_auger',
    name: 'pre_auger',
    value: 2.5,
  },
  {
    id: 'pre',
    key: 'pre',
    name: 'pre',
    value: 2.0,
  },
  {
    id: 'vibro_hammer_in',
    key: 'vibro_hammer_in',
    name: 'vibro_hammer_in',
    value: 2.0,
  },
  {
    id: 'vibro_pull_out',
    key: 'vibro_pull_out',
    name: 'vibro_pull_out',
    value: 1.5,
  },
  {
    id: 'relocation',
    key: 'relocation',
    name: 'relocation',
    value: 1.0,
  },
];

export const workExpenseRates: WorkExpenseRate[] = [
  {
    id: 'pre_hole_hammer',
    key: 'pre_hole_hammer',
    name: 'pre_hole_hammer',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 280 },
      { id: '200_km', key: '200_km', name: '200_km', value: 380 },
      { id: '300_km', key: '300_km', name: '300_km', value: 480 },
    ],
  },
  {
    id: 'pre_auger',
    key: 'pre_auger',
    name: 'pre_auger',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 220 },
      { id: '200_km', key: '200_km', name: '200_km', value: 320 },
      { id: '300_km', key: '300_km', name: '300_km', value: 420 },
    ],
  },
  {
    id: 'pre',
    key: 'pre',
    name: 'pre',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 220 },
      { id: '200_km', key: '200_km', name: '200_km', value: 320 },
      { id: '300_km', key: '300_km', name: '300_km', value: 420 },
    ],
  },
  {
    id: 'vibro_hammer_in',
    key: 'vibro_hammer_in',
    name: 'vibro_hammer_in',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 220 },
      { id: '200_km', key: '200_km', name: '200_km', value: 320 },
      { id: '300_km', key: '300_km', name: '300_km', value: 420 },
    ],
  },
  {
    id: 'vibro_pull_out',
    key: 'vibro_pull_out',
    name: 'vibro_pull_out',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 220 },
      { id: '200_km', key: '200_km', name: '200_km', value: 320 },
      { id: '300_km', key: '300_km', name: '300_km', value: 420 },
    ],
  },
  {
    id: 'relocation',
    key: 'relocation',
    name: 'relocation',
    value: 0,
    rates: [
      { id: '100_km', key: '100_km', name: '100_km', value: 350 },
      { id: '200_km', key: '200_km', name: '200_km', value: 350 },
      { id: '300_km', key: '300_km', name: '300_km', value: 350 },
    ],
  },
];
