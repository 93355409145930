import { useState, useEffect } from 'react';
import {
  useDeleteRentFeeCalculationsRepository,
  useDeleteRentFeeNotesRepository,
  useReadFiscalYearRepository,
  useReadRentFeeCalculationsRepository,
  useReadRentFeeNoteRepository,
  useWriteFiscalYearRepository,
  useWriteRentFeeCalculationsRepository,
  useWriteRentFeeNoteRepository,
} from 'repository/RentFeeCalculations';
import { RentFeeCalculation, RentFeeCalculationNote } from 'model/RentFeeCalculation';

export const useFetchRentFeeCalculations = (): [RentFeeCalculation[], unknown | null, () => void] => {
  const [rentFeeCalculations, setRentFeeCalculations] = useState<RentFeeCalculation[]>([]);
  const [error, setError] = useState<unknown | null>(null);
  const readRentFeeCalculation = useReadRentFeeCalculationsRepository();

  const getRentFee = async () => {
    try {
      const data = await readRentFeeCalculation();
      setRentFeeCalculations(data);
    } catch (e: unknown) {
      setError(e);
    }
  };

  useEffect(() => {
    getRentFee();
    // NOTE: fetch処理は初回実行時と、refetchメソッドを呼んだときのみとするのでLinterのチェックを無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [rentFeeCalculations, error, getRentFee];
};

export const useSendRentFeeCalculation = (): ((rentFee: RentFeeCalculation) => Promise<RentFeeCalculation>) =>
  useWriteRentFeeCalculationsRepository();
export const useDeleteRentFeeCalculation = (): ((ids: string[]) => Promise<void[]>) =>
  useDeleteRentFeeCalculationsRepository();

export const useFetchRentFeeNotes = (): [RentFeeCalculationNote[], unknown | null, () => void] => {
  const [notes, setNotes] = useState<RentFeeCalculationNote[]>([]);
  const [error, setError] = useState<unknown | null>(null);
  const readRentFeeNotes = useReadRentFeeNoteRepository();
  const fetch = async () => {
    try {
      const data = await readRentFeeNotes();
      setNotes(data);
    } catch (e: unknown) {
      setError(e);
    }
  };
  useEffect(() => {
    fetch();
    // NOTE: fetch処理は初回実行時と、refetchメソッドを呼んだときのみとするのでLinterのチェックを無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [notes, error, fetch];
};

export const useSendRentFeeCalculationNote = (): ((note: RentFeeCalculationNote) => Promise<RentFeeCalculationNote>) =>
  useWriteRentFeeNoteRepository();

export const useDeleteRentFeeNoteCalculation = (): ((ids: string[]) => Promise<void[]>) =>
  useDeleteRentFeeNotesRepository();

export const useFetchFiscalYear = (): string => {
  const [fiscalYear, setFiscalYear] = useState('');
  const [, setError] = useState<unknown | null>(null);
  const readFiscalYear = useReadFiscalYearRepository();

  useEffect(() => {
    (async () => {
      try {
        const data = await readFiscalYear();
        setFiscalYear(data);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // NOTE: fetch処理は初回実行時のみとするのでLinterのチェックを無効化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fiscalYear;
};

export const useSendFiscalYear = (): ((year: string) => Promise<string>) => useWriteFiscalYearRepository();
