import { useEffect, useState, FC } from 'react';
import { Paper, Divider, Typography, Stack } from '@mui/material';
import { TypeIn } from 'model/Productivity/TypeIn';
import {
  calcHammerInConstant,
  calcHammerInSteelSheetTypeConstant,
  calcHammerInPreparationTime,
  calcHammerInBoldingTime,
  calcHammerInWorkingTimePerUnit,
  calcHammerInConstructionTimePerUnit,
} from 'api/calc/HammerIn';
import { round } from 'api/calc/Calc';
import { WORK_TIME } from 'store/Master';
import { HAMMER_IN_DRIVING_AND_PULLING_TIME } from 'store/TypeIn';

interface Props {
  productivity: TypeIn;
  open: boolean;
}

interface ConstructionValue {
  tc: number;
  t1: number;
  t2: number;
  t3: number;
  k: number;
  beta: number;
  f: number;
  t: number;
  days: number;
  quantity: number;
  steelMaterialLength: number;
  l: number;
}

const CalculationResults: FC<Props> = ({ productivity, open }) => {
  const [value, setValue] = useState<ConstructionValue>({
    tc: 0,
    t1: 0,
    t2: 0,
    t3: 0,
    k: 0,
    beta: 0,
    f: 0,
    t: 0,
    days: 0,
    quantity: 0,
    steelMaterialLength: 0,
    l: 0,
  });

  useEffect(() => {
    const t3 = calcHammerInBoldingTime(
      productivity.bondingTime,
      productivity.bondingCount,
      productivity.steelMaterialType
    );
    const t1 = calcHammerInPreparationTime(productivity.preparationTime);
    const k = calcHammerInSteelSheetTypeConstant(productivity.steelMaterialType);
    const beta = calcHammerInConstant(productivity.drivenConstant);
    const f = round(1 + productivity.factorTotal);
    const t = WORK_TIME;
    const t2 = calcHammerInWorkingTimePerUnit(productivity.drivenLength, beta, k);
    const tc = calcHammerInConstructionTimePerUnit(t1, t2, t3, f);
    const days = round(tc / 60 / t, 3);
    const quantity = tc ? round((t * 60) / tc, 2) : 0; // NOTE: 初期表示時など tc が 0 のときは「日当たり施工数量 0 枚・本/日」として表示する
    const steelMaterialLength = productivity.steelMaterialLength;
    const l = productivity.drivenLength;
    setValue({ tc, t1, t2, t3, k, beta, f, t, days, quantity, steelMaterialLength, l });
  }, [productivity, setValue]);

  if (!open) {
    return <div />;
  }
  return (
    <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, position: 'sticky', top: 0, maxWidth: 450 }}>
      <Stack spacing={2} m={2}>
        <div>
          <Typography variant="subtitle2">Tc= (T1 + T2 + T3) * F</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2= (α * L * β) * K</Typography>
        </div>
        <Divider />
        <div>
          <Typography variant="subtitle2">Tc:1単位当たり施工時間</Typography>
          <Typography variant="body2">{value.tc} min/本</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T1:1単位当たり準備時間</Typography>
          <Typography variant="body2">{value.t1} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T2:1単位当たり打込み時間</Typography>
          <Typography variant="body2">{value.t2} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">a:1ｍあたり打込み時間</Typography>
          <Typography variant="body2">{HAMMER_IN_DRIVING_AND_PULLING_TIME}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">鋼材長</Typography>
          <Typography variant="body2">{value.steelMaterialLength} m</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">L:打込み長</Typography>
          <Typography variant="body2">{value.l} m</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">β:打込み定数</Typography>
          <Typography variant="body2">{value.beta || '-'}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">K:鋼材形式による係数</Typography>
          <Typography variant="body2">{value.k}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T3:溶接、ボルト取り付け時間</Typography>
          <Typography variant="body2">{value.t3} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">F:作業係数</Typography>
          <Typography variant="body2">{value.f}</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">T:作業時間</Typography>
          <Typography variant="body2">{value.t} h/日</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">1単位当たり施工日数 Tc/(T (6.2) * 60)</Typography>
          <Typography variant="body2">{value.days} min</Typography>
        </div>
        <div>
          <Typography variant="subtitle2">日当たり施工数量</Typography>
          <Typography variant="body2">{value.quantity} (枚・本)/日</Typography>
        </div>
      </Stack>
    </Paper>
  );
};

export default CalculationResults;
