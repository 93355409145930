import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { MasterTypes } from 'model/Master';

interface MasterState {
  masters: MasterTypes[] | undefined;
}

const masterState = atom<MasterState>({
  key: 'master',
  default: {
    masters: undefined,
  },
});

export const useMasterState = () => useRecoilValue(masterState);
export const useMasterMutators = () => {
  const setState = useSetRecoilState(masterState);
  const setMaster = useCallback(
    (masters: MasterTypes[]) => {
      return setState((previousState: MasterState) => {
        return {
          ...previousState,
          masters,
        };
      });
    },
    [setState]
  );

  return setMaster;
};
