import { FC } from 'react';
import * as z from 'zod';
import { TextField } from 'component/TextField';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button, Grid, Paper } from '@mui/material';
import Password from './Password';

interface Props {
  onUpdate?: (email: string, password: string) => void;
  onClickConfirm: (email: string, password: string) => void;
}

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
});

const Login: FC<Props> = ({ onClickConfirm }) => {
  const { control, handleSubmit } = useForm({ resolver: zodResolver(schema) });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    onClickConfirm(data.email, data.password);
  };
  return (
    <Grid container sx={{ height: '100vh' }} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, px: { xs: 2, md: 3 }, py: 5, textAlign: 'center' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  label="メールアドレス"
                  variant="standard"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Password
                  required
                  fullWidth
                  id="password"
                  name="password"
                  label="パスワード"
                  variant="standard"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" sx={{ my: 3 }} type="submit">
                  ログイン
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
