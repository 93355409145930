import {
  BondingCount,
  BondingTime,
  DrivenConstant,
  PreparationTime,
  SteelMaterialType,
  WorkCorrectionCategory,
  WorkCorrectionFactor,
} from 'model/Productivity';
import { QualitySoil, SteelMaterial } from 'model/Productivity';
import { MaterialWorkingTimePerUnit, TypeIn } from 'model/Productivity/TypeIn';

/** a:1ｍあたり打込み引抜き時間 min  */
export const HAMMER_IN_DRIVING_AND_PULLING_TIME = 0.8;

/** 基準の数量 */
export const HAMMER_IN_STANDARD_QUANTITY = 10;

export const EmptyQualitySoil: QualitySoil = {
  sandySoil: 0,
  lechySoil: 0,
  claySoil: 0,
  rockBlock: 0,
  softRock: 0,
  mediumHardRock: 0,
  hardRock: 0,
};

export const EmptyTypeIn: TypeIn = {
  projectId: '',
  name: '',
  usedMachine: '',
  workType: 'hammer-in',
  specification: '',
  unit: '枚',
  quantity: 10,
  unitPrice: 0,
  subtotal: 0,
  remark: '',
  steelMaterial: '',
  steelMaterialType: '',
  steelMaterialLength: 0,
  drivenLength: 0,
  drivenConstant: '',
  bondingTime: '',
  bondingCount: 0,
  bondingCountSelect: '0',
  preparationDays: '',
  fillingDays: '',
  preparationTime: '',
  correctionFactors: [],
  factorTotal: 0,
  otherFactor: {
    name: '',
    value: 0,
  },
};
export const WorkCorrectionFactorCategory: WorkCorrectionCategory[] = [
  {
    key: 'construction-quantity',
    name: '施工数量',
  },
  {
    key: 'work-constraints',
    name: '作業制約',
  },
];

export const WorkCorrectionFactorList: WorkCorrectionFactor[] = [
  {
    key: 'less_than_100',
    category: 'construction-quantity',
    name: '100枚以下',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_30',
    category: 'construction-quantity',
    name: '30本以下',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_50',
    category: 'construction-quantity',
    name: '50枚以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_20',
    category: 'construction-quantity',
    name: '20本以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_10',
    category: 'construction-quantity',
    name: '10本以下',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'night_work',
    category: 'work-constraints',
    name: '夜間作業',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'narrow',
    category: 'work-constraints',
    name: '狭隘',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'claySoil',
    category: 'work-constraints',
    name: '粘性土',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'small_impact_work_restrictions',
    category: 'work-constraints',
    name: '作業制限影響小（狭隘・上空制限）',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'big_impact_work_restrictions',
    category: 'work-constraints',
    name: '作業制限影響大（狭隘・上空制限）',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialItems: SteelMaterial[] = [
  {
    key: 'h',
    name: 'Ｈ鋼',
    value: 'h',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'steel',
    name: '鋼矢板',
    value: 'steel',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialTypeItems: SteelMaterialType[] = [
  {
    key: 'H-150',
    name: 'H-150',
    value: 'H-150',
    factor: 0.9,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-200',
    name: 'H-200',
    value: 'H-200',
    factor: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-250',
    name: 'H-250',
    value: 'H-250',
    factor: 1.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-300',
    name: 'H-300',
    value: 'H-300',
    factor: 1.4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-350',
    name: 'H-350',
    value: 'H-350',
    factor: 1.7,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-400',
    name: 'H-400',
    value: 'H-400',
    factor: 2.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: 'Ⅱ型',
    value: '2',
    factor: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: 'Ⅲ型',
    value: '3',
    factor: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: 'Ⅳ型',
    value: '4',
    factor: 1.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'VL',
    name: 'ⅤL型',
    value: 'VL',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2w',
    name: 'Ⅱw型',
    value: '2w',
    factor: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3w',
    name: 'Ⅲw型',
    value: '3w',
    factor: 1.3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4w',
    name: 'Ⅳw型',
    value: '4w',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '10H',
    name: '10H',
    value: '10H',
    factor: 1.3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '25H',
    name: '25H',
    value: '25H',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialTypeItemsForHSteel: SteelMaterialType[] = [
  // NOTE: #701 鋼材種類で「H鋼」を選択したときの「鋼材種類寸法・型」の選択肢
  {
    key: 'H-150',
    name: 'H-150',
    value: 'H-150',
    factor: 0.9,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-200',
    name: 'H-200',
    value: 'H-200',
    factor: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-250',
    name: 'H-250',
    value: 'H-250',
    factor: 1.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-300',
    name: 'H-300',
    value: 'H-300',
    factor: 1.4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-350',
    name: 'H-350',
    value: 'H-350',
    factor: 1.7,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-400',
    name: 'H-400',
    value: 'H-400',
    factor: 2.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialTypeItemsForSteelSheetPiles: SteelMaterialType[] = [
  // NOTE: #701 鋼材種類で「鋼矢板」を選択したときの「鋼材種類寸法・型」の選択肢
  {
    key: '2',
    name: 'Ⅱ型',
    value: '2',
    factor: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: 'Ⅲ型',
    value: '3',
    factor: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: 'Ⅳ型',
    value: '4',
    factor: 1.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'VL',
    name: 'ⅤL型',
    value: 'VL',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2w',
    name: 'Ⅱw型',
    value: '2w',
    factor: 1.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3w',
    name: 'Ⅲw型',
    value: '3w',
    factor: 1.3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4w',
    name: 'Ⅳw型',
    value: '4w',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '10H',
    name: '10H',
    value: '10H',
    factor: 1.3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '25H',
    name: '25H',
    value: '25H',
    factor: 1.5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const DrivenConstantItems: DrivenConstant[] = [
  {
    key: 'placing',
    name: '打込',
    value: 'placing',
    factor: 1.0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'pre',
    name: '先行掘り+打込',
    value: 'pre',
    factor: 0.85,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingTimeItems: BondingTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'welding_j',
    name: '溶接接合',
    value: 'welding_j',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'bolt_j',
    name: 'ボルト接合',
    value: 'bolt_j',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const BondingCountItems: BondingCount[] = [
  {
    key: '0',
    name: '0',
    value: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '1',
    name: '1',
    value: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: '2',
    value: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: '3',
    value: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: '4',
    value: 4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const PreparationTimeItems: PreparationTime[] = [
  {
    key: 'h',
    name: 'Ｈ鋼',
    value: 'h',
    time: 12,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'steel',
    name: '鋼矢板',
    value: 'steel',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialWorkingTimePerUnit: MaterialWorkingTimePerUnit[] = [
  {
    key: 'H-150',
    name: 'H-150',
    value: 'H-150',
    weldingTime: 10,
    boltJoiningTime: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-200',
    name: 'H-200',
    value: 'H-200',
    weldingTime: 15,
    boltJoiningTime: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-250',
    name: 'H-250',
    value: 'H-250',
    weldingTime: 20,
    boltJoiningTime: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-300',
    name: 'H-300',
    value: 'H-300',
    weldingTime: 30,
    boltJoiningTime: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-350',
    name: 'H-350',
    value: 'H-350',
    weldingTime: 45,
    boltJoiningTime: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H-400',
    name: 'H-400',
    value: 'H-400',
    weldingTime: 60,
    boltJoiningTime: 35,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: 'Ⅱ型',
    value: '2',
    weldingTime: 18,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: 'Ⅲ型',
    value: '3',
    weldingTime: 22.0,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: 'Ⅳ型',
    value: '4',
    weldingTime: 25.0,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'vl',
    name: 'ⅤL型',
    value: 'VL',
    weldingTime: 60,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2w',
    name: 'Ⅱw型',
    value: '2w',
    weldingTime: 25.0,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3w',
    name: 'Ⅲw型',
    value: '3w',
    weldingTime: 30.0,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4w',
    name: 'Ⅳw型',
    value: '4w',
    weldingTime: 60.0,
    boltJoiningTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];
