import {
  Firestore,
  getDocs,
  collection,
  query,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  doc,
  getDoc,
} from 'firebase/firestore';
import { User, convertUser } from 'model/User';

const converter: FirestoreDataConverter<User> = {
  toFirestore(user: User): DocumentData {
    return user;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
    return convertUser(snapshot.data(options));
  },
};

export const fetchUsers = async (db: Firestore): Promise<User[]> => {
  const ref = collection(db, 'users');
  const results = await getDocs(query(ref).withConverter(converter));
  return results.docs.flatMap((doc) => doc.data());
};

export const fetchUser = async (db: Firestore, id: string): Promise<User | undefined> => {
  const ref = await getDoc(doc(db, 'users', id).withConverter(converter));
  return ref.data();
};
