import { doc, Firestore, setDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Audit } from 'model/Audit';

export const setAudit = async (db: Firestore, audit: Audit): Promise<void> => {
  const docRef = doc(collection(db, 'audits'));
  await setDoc(docRef, {
    ...audit,
    id: docRef.id,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
};
