import { useLoginState } from 'globalStates/Login';
import { useUserState } from 'globalStates/User';
import { useEffect, useRef } from 'react';
import { Location, Outlet, useLocation } from 'react-router-dom';
import { useSendAudit } from 'usecase/Audit';

const Layout = () => {
  const location = useLocation();
  const refLocation = useRef<Location | null>(null);
  const user = useUserState();
  const auth = useLoginState();
  const postAudit = useSendAudit();

  useEffect(() => {
    const event = location.pathname === '/login' ? 'login' : 'transition';
    try {
      if (auth.isLogin && refLocation.current?.pathname !== location.pathname) {
        postAudit('success', location, user.user, [event], undefined, undefined);
      }
    } catch (e: unknown) {
      postAudit('failed', location, user.user, [event], String(e));
    } finally {
      refLocation.current = location;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <Outlet />;
};
export default Layout;
