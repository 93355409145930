import { Municipality } from 'model/Master';
import { TypeIn } from 'model/Productivity/TypeIn';
import { OtherExpensiveRate } from 'store/Productivity';
import {
  DrivenConstantItems,
  SteelMaterialTypeItems,
  PreparationTimeItems,
  SteelMaterialWorkingTimePerUnit,
  BondingTimeItems,
  HAMMER_IN_DRIVING_AND_PULLING_TIME,
  HAMMER_IN_STANDARD_QUANTITY,
} from 'store/TypeIn';
import { amountRoundUp, round } from './Calc';
import { WORK_TIME } from 'store/Master';
import { calcHydraulicVibroUnitPrice } from './UnitPrice';
import { Machine } from 'model/Productivity';

/**
 * β：定数 の値を求める
 * @param drivenConstant 打込み定数 [打込/先行堀り+打込]
 * @returns 定数
 */
export const calcHammerInConstant = (drivenConstant: string): number => {
  return DrivenConstantItems.find((dc) => dc.value === drivenConstant)?.factor || 0;
};

/**
 * K：鋼矢板形式による係数 を求める
 * @param steelMaterialType 鋼材種類 寸法・型
 * @returns 鋼矢板形式による係数
 */
export const calcHammerInSteelSheetTypeConstant = (steelMaterialType: string): number => {
  return SteelMaterialTypeItems.find((smt) => smt.value === steelMaterialType)?.factor || 0;
};

/**
 * T1：１単位当たり準備時間 min を求める
 * @param steelMaterial 鋼材種類
 * @returns 単位当たり準備時間 min
 */
export const calcHammerInPreparationTime = (steelMaterial: string): number => {
  const pt = PreparationTimeItems.find((pt) => pt.key === steelMaterial);
  return pt?.time || 0;
};

/**
 * T3：溶接時間、ボルト取り付け時間 を求める
 * @param bondingTime 切断・ボルト取り外し種類
 * @param bondingCount 回数
 * @param steelMaterialType  鋼材種類 寸法・型
 * @returns 溶接時間、ボルト取り付け時間 min
 */
export const calcHammerInBoldingTime = (
  bondingTime: string,
  bondingCount: number,
  steelMaterialType: string
): number => {
  const BoldingTimePerUnit = (): number => {
    const weldingBolt = BondingTimeItems.find((bt) => bt.name === '溶接接合')?.value;
    const bolt = BondingTimeItems.find((bt) => bt.name === 'ボルト接合')?.value;

    if (bondingTime === weldingBolt) {
      // FIXME: valueの値が大文字か小文字か統一されていないため、比較が正しく行われていなかった
      // 今後修正を求む
      return SteelMaterialWorkingTimePerUnit.find((wt) => wt.value == steelMaterialType)?.weldingTime || 0;
    }
    if (bondingTime === bolt) {
      return SteelMaterialWorkingTimePerUnit.find((wt) => wt.value === steelMaterialType)?.boltJoiningTime || 0;
    }
    return 0;
  };
  return BoldingTimePerUnit() * bondingCount;
};

/**
 * T2：１単位当たり打込時間 を求める
 * @param drivenLength L：打込み長 m
 * @param betaConstant β：定数
 * @param steelSheetTypeConstant K：鋼矢板形式による係数
 * @returns １単位当たり打込時間
 */
export const calcHammerInWorkingTimePerUnit = (
  drivenLength: number,
  betaConstant: number,
  steelSheetTypeConstant: number
) => {
  return round(HAMMER_IN_DRIVING_AND_PULLING_TIME * drivenLength * betaConstant * steelSheetTypeConstant);
};

/**
 * Tｃ：１単位当たり施工時間 を求める
 * @param preparationTime T1：１単位当たり準備時間 min
 * @param workingTimePerUnit T2：１単位当たり打込時間
 * @param boldingTime T3：溶接時間、ボルト取り付け時間 min
 * @param factorTotal 作業係数
 * @returns １単位当たり施工時間 min/本
 */
export const calcHammerInConstructionTimePerUnit = (
  preparationTime: number,
  workingTimePerUnit: number,
  boldingTime: number,
  factorTotal: number
) => {
  return round((preparationTime + workingTimePerUnit + boldingTime) * factorTotal, 2);
};

/**
 * 歩掛りから算出打込み 1単位当たり施工日数  を求める
 * @param productivity 打込歩掛り
 * @returns 日当たり施工数量 （枚・本）/日
 *
 * 参考資料：[●研究会積算M R04.4版 221017](https://securecycle.sharepoint.com/:x:/s/D06910/EYYrmEKxrkxKniZ8bM3WqyYBa9J0uejPXV9LGSlTfugjxw?e=w01ksa)
 * シート：●算出打込 F11
 */
export const calcConstructionDaysPerUnit = (productivity: TypeIn) => {
  // T1：１単位当たり準備時間 min
  const t1 = calcHammerInPreparationTime(productivity.preparationTime);
  // β：定数
  const beta = calcHammerInConstant(productivity.drivenConstant);
  // K：鋼矢板形式による係数
  const k = calcHammerInSteelSheetTypeConstant(productivity.steelMaterialType);
  // T2：１単位当たり打込時間
  const t2 = calcHammerInWorkingTimePerUnit(productivity.drivenLength, beta, k);
  // T3：溶接時間、ボルト取り付け時間 min
  const t3 = calcHammerInBoldingTime(
    productivity.bondingTime,
    productivity.bondingCount,
    productivity.steelMaterialType
  );
  // Ｆ：作業係数
  const f = round(1 + productivity.factorTotal);
  // Tｃ：１単位当たり施工時間 min/本
  const tc = calcHammerInConstructionTimePerUnit(t1, t2, t3, f);
  // T：作業時間 h/日 一旦6.2で固定
  // FIXME:Excel上「日当たり作業時間」として入力できる項目のため、今後外から設定できるよう修正が必要
  const workTime = WORK_TIME;
  return round(tc / 60 / workTime, 3);
};

/**
 * 単価を求める
 * @param productivity 打込歩掛り
 * @param municipality 県単価
 * @returns
 */
export const calcHammerInUnit = (productivity: TypeIn, municipality: Municipality, machine: Machine): number => {
  const constructionDaysPerUnit = calcConstructionDaysPerUnit(productivity);
  const otherExpensesRate = OtherExpensiveRate.find((oer) => oer.workType === productivity.workType)?.rate || 0;
  const quantity = HAMMER_IN_STANDARD_QUANTITY * constructionDaysPerUnit;

  const multiplication = (a: number, b: number) => round(a * b, 0);

  const gCECaretakerSubtotal = multiplication(quantity, municipality.generalCivilEngineeringCaretaker);
  const scaffoldWorkerSubtotal = multiplication(quantity, municipality.scaffoldWorker);
  const specialWorkerSubtotal = multiplication(quantity, municipality.specialWorker);
  const ordinaryWorkersSubtotal = multiplication(quantity, municipality.ordinaryWorkers);

  const weldersQuantityFactor = () => {
    const bondingTime = BondingTimeItems.find((bt) => bt.value === (productivity as TypeIn).bondingTime);
    if (bondingTime?.name === '溶接接合') {
      return 1;
    }
    return 0;
  };
  const weldersQuantity = round(quantity * weldersQuantityFactor());
  const weldersSubtotal = multiplication(weldersQuantity, municipality.welders);

  const usedMachineUnitPrice = calcHydraulicVibroUnitPrice(municipality, machine);
  const pileDrivingSubtotal = multiplication(quantity, usedMachineUnitPrice || 0);

  const subTotal =
    gCECaretakerSubtotal +
    scaffoldWorkerSubtotal +
    specialWorkerSubtotal +
    ordinaryWorkersSubtotal +
    weldersSubtotal +
    pileDrivingSubtotal;
  const otherExpensesPrice = round(subTotal * otherExpensesRate, 2);
  const beforeRoundTotal = subTotal + otherExpensesPrice;
  const total = amountRoundUp(beforeRoundTotal);
  const beforeRoundTotalPerUnit = Math.floor(total / HAMMER_IN_STANDARD_QUANTITY);

  return beforeRoundTotalPerUnit;
};
