import { FC, useState } from 'react';
import { DataGrid as Base, GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import { defaultPageSize, rowsPerPage } from 'store/Pagination';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: GridColumns<any>;
  initialState?: GridInitialStateCommunity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  selectionModel?: GridSelectionModel;
  setSelectionModel?: (selectionModel: GridSelectionModel) => void;
}

export const DataGrid: FC<Props> = ({ columns, initialState, data, selectionModel, setSelectionModel }) => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  return (
    <div style={{ height: 500, width: '100%' }}>
      <Base
        rows={data}
        columns={columns}
        initialState={initialState}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPage}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={setSelectionModel}
        selectionModel={selectionModel}
      />
    </div>
  );
};
