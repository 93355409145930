import { QualitySoil } from 'model/Productivity';
import { QualitySoilList } from 'store/Productivity';

type QualitySoilKey = keyof QualitySoil;

export const calc = (key: QualitySoilKey, value: number) => {
  const qs = QualitySoilList.find((q) => q.key === key);
  if (!qs) {
    return 0;
  }
  return qs.value * value;
};

export const calcDaysOfChippingHoles = (qualitySoil: QualitySoil) =>
  Object.keys(qualitySoil)
    .map((key) => calc(key as QualitySoilKey, qualitySoil[key as QualitySoilKey]))
    .reduce((sum, e) => sum + e, 0);
