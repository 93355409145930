export interface RentFeeCalculation {
  id: string;
  no: number;
  category: string;
  // 規格
  specifications: string;
  enginePower: string;
  weight: number;

  basicPrice: number;
  standardUsePeriodOfYear: number;
  // 年間標準
  operationHours: number;
  operationDays: number;
  serviceDays: number;

  maintenanceRepairCostRate: number;
  annualManagementCostRate: number;

  // 運転1時間当り
  survivalRate: number;
  rentOnOperatingRate: number;
  amountToRentOnOperating: number;

  // 供用1日当り
  rentOnInServiceRate: number;
  amountToRentOnService: number;

  // 参考
  rentOnOperatingRateConversion: number;
  rentOnOperatingRateConversionUnit: string;
  amountToRentConversionOnOperating: number;
  amountToRentConversionOnOperatingUnit: string;
  rentOnInServiceRateConversion: number;
  amountToRentConversionOnService: number;
  // 摘要
  brief: string;
  amountOfFuelUsedPerHour: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export interface RentFeeCalculationNote {
  id: string;
  no: string;
  reference: string;
  order?: number;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export const emptyRentFeeCalculation: RentFeeCalculation = {
  id: '',
  no: 0,
  category: '',
  specifications: '',
  enginePower: '',
  weight: 0,
  basicPrice: 0,
  standardUsePeriodOfYear: 0,
  operationHours: 0,
  operationDays: 0,
  serviceDays: 0,
  maintenanceRepairCostRate: 0,
  annualManagementCostRate: 0,
  survivalRate: 0,
  rentOnOperatingRate: 0,
  amountToRentOnOperating: 0,
  rentOnInServiceRate: 0,
  amountToRentOnService: 0,
  rentOnOperatingRateConversionUnit: '',
  rentOnOperatingRateConversion: 0,
  amountToRentConversionOnOperatingUnit: '',
  amountToRentConversionOnOperating: 0,
  rentOnInServiceRateConversion: 0,
  amountToRentConversionOnService: 0,
  brief: '',
  amountOfFuelUsedPerHour: 0,
};

export const emptyRentFeeCalculationNote = {
  id: '',
  no: '',
  reference: '',
  order: 0,
};
