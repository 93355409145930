import { FC, useCallback } from 'react';
import { CssBaseline } from '@mui/material';
import Routes from 'route';
import { useErrorsState, useErrorsMutators } from 'globalStates/Errors';
import { Toast } from 'component/Toast';

const App: FC = () => {
  const errors2 = useErrorsState();
  const { errors } = errors2;
  const setErrors = useErrorsMutators();
  const handlerClose = useCallback(() => setErrors(undefined), [setErrors]);

  return (
    <>
      <CssBaseline />
      <Routes />
      <Toast severity="error" message={errors?.message || ''} open={Boolean(errors?.message)} onClose={handlerClose} />
    </>
  );
};

export default App;
