import { FC } from 'react';
import Base from '@mui/material/Dialog';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface Props {
  open: boolean;
  contents: string;
  title?: string;
  agreeName?: string;
  cancelName?: string;
  onAgree?: () => void;
  onCancel?: () => void;
}

const Dialog: FC<Props> = ({ open, contents, title, agreeName, cancelName, onAgree, onCancel }) => {
  return (
    <Base open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText fontSize="body2" id="dialog-description">
          {contents}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {onCancel && <Button onClick={onCancel}>{cancelName}</Button>}
        {onAgree && (
          <Button onClick={onAgree} color="primary" variant="contained">
            {agreeName}
          </Button>
        )}
      </DialogActions>
    </Base>
  );
};

export default Dialog;
