import { FC } from 'react';
import { Link } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';

type Props = TypographyProps;

const Copyright: FC<Props> = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright ©'}
      <Link color="inherit" href="https://www.shigiharakiso.co.jp/">
        S・RXリーダーレス工法研究会
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
