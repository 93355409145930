import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';
import NewProject from 'pages/Project/Create';
import EditProject from 'pages/Project/Edit';
import NewProductivity from 'pages/Productivity/Create';
import EditProductivity from 'pages/Productivity/Edit';
import RxMachine from 'pages/Report/RxMachine';
import ItemizedStatementReport from 'pages/Report/ItemizedStatement';
import ItemizedStatement from 'pages/ItemizedStatement';
import PriceList from 'pages/Report/PriceList';
import Login from 'pages/Login';
import PrivateRoute from './Private';
import AuthCheck from './AuthCheck';
import Setting from './Setting';
import Master from 'pages/Master';
import MasterCreate from 'pages/Master/Create';
import MasterEdit from 'pages/Master/Edit';
import UnitPriceList from 'pages/Report/UnitPriceList';
import RentFee from 'pages/RentFeeCalculation/index';
import RentFeeCreate from 'pages/RentFeeCalculation/Create';
import NoteCreate from 'pages/RentFeeCalculation/CreateNote';
import Layout from 'pages/Layout/index';

const Index: FC = () => (
  <Router>
    <Routes>
      <Route element={<AuthCheck />}>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route element={<Setting />}>
              <Route index element={<Dashboard />} />
              <Route path=":id" element={<ItemizedStatement />} />
              <Route path="projects">
                <Route path="new" element={<NewProject />} />
                <Route path=":id" element={<EditProject />} />
                <Route path=":projectId/:productId" element={<EditProductivity />} />
              </Route>
              <Route path="productivities">
                <Route path="new" element={<NewProductivity />} />
              </Route>
              <Route path="reports">
                <Route path="first-price" element={<PriceList />} />
                <Route path="unit-price" element={<UnitPriceList />} />
                <Route path="itemized-statement" element={<ItemizedStatementReport />} />
                <Route path="rx-machine" element={<RxMachine />} />
              </Route>
              <Route path="rx-machine">
                <Route index element={<RentFee />} />
                <Route path="rent-fee/new" element={<RentFeeCreate />} />
                <Route path="rent-fee/:id" element={<RentFeeCreate />} />
                <Route path="note/new" element={<NoteCreate />} />
                <Route path="note/:id" element={<NoteCreate />} />
              </Route>
              <Route path="master">
                <Route index element={<Master />} />
                <Route path=":collectionId" element={<Master />} />
                <Route path=":collectionId/new" element={<MasterCreate />} />
                <Route path=":collectionId/:documentId" element={<MasterEdit />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  </Router>
);

export default Index;
