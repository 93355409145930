import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FirstPriceListReport from 'component/Report/FirstPriceListReport';
import { Productivity } from 'model/Productivity';
import { Project } from 'model/Project';
import { FirstPrices, UnitPrices } from 'model/FirstPriceList';
import { useFetchProjects } from 'usecase/Project';
import { useErrorsMutators } from 'globalStates/Errors';
import { useFetchMaster } from 'usecase/Master';
import { Masters } from 'model/Master';
import { createFirstPriceReportData } from 'api/calc/UnitPrice';
import { AirCompressors, Cranes } from 'store/HoleHammer';

const UnitPriceList: FC = () => {
  const location = useLocation();
  const projectId = location.search.split('&')[0].replace('?projectId=', '');
  const [projects, projectsError] = useFetchProjects({ id: projectId });
  const [firstPrice, setFirstPrices] = useState<FirstPrices[]>();
  const [municipalities, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const [machines, machinesError] = useFetchMaster({ collectionId: Masters.Machine });
  const setErrors = useErrorsMutators();

  /**
   * 作成した歩掛りから工種絞り込み、単価表を出力する
   */
  const createFirstPriceReport = useCallback(
    (project: Project, productivities: Productivity[]): UnitPrices[] => {
      return createFirstPriceReportData(municipalities, machines, AirCompressors, Cranes, project, productivities);
    },
    [machines, municipalities]
  );

  useEffect(() => {
    if (!projects || projects.length === 0) {
      return;
    }
    const project = projects[0];
    if (!project) {
      return;
    }
    const productIds = location.search.split('&')[1].replace('prodId=', '').split(',');
    const productivities = project.productivities.filter((p) => productIds.includes(p.id || ''));

    setFirstPrices(createFirstPriceReport(project, productivities));
  }, [createFirstPriceReport, location.search, projects]);

  useEffect(() => {
    const error = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    error([projectsError, municipalitiesError, machinesError]);
  }, [projectsError, municipalitiesError, machinesError, setErrors]);

  return (
    <div>
      {firstPrice &&
        firstPrice.map((item, index) => {
          return (
            <FirstPriceListReport
              key={index}
              data={item}
              title={item.title}
              productName={item.productName}
              specification={item.specification}
            />
          );
        })}
    </div>
  );
};

export default UnitPriceList;
