import { useCallback, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainFrame from 'component/MainFrame';
import CreateComponent from 'component/Project/Create';
import { Project } from 'model/Project';
import { EmptyProject } from 'store/Project';
import { useProjectMutators } from 'globalStates/Project';
import { useErrorsMutators } from 'globalStates/Errors';
import { useFetchMaster } from 'usecase/Master';
import { Masters, Municipality } from 'model/Master';
import { useUserState } from 'globalStates/User';

const Create: FC = () => {
  const navigate = useNavigate();
  const userState = useUserState();
  const [setProject] = useProjectMutators();
  const [municipalities, municipalitiesError] = useFetchMaster({ collectionId: Masters.Municipality });
  const setErrors = useErrorsMutators();

  const handleClickConfirm = useCallback(
    (project: Project) => {
      setProject({ ...project, createdBy: userState.user?.id, updatedBy: userState.user?.id });
      navigate('/productivities/new');
    },
    [navigate, setProject, userState.user?.id]
  );
  const handleClickBack = useCallback(() => navigate('/'), [navigate]);
  const updateProject = useCallback((prop: keyof Project, project: Project) => setProject(project), [setProject]);

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([municipalitiesError]);
  }, [municipalitiesError, setErrors]);
  return (
    <MainFrame title="見積新規作成">
      <CreateComponent
        project={EmptyProject}
        onClickConfirm={handleClickConfirm}
        onClickBack={handleClickBack}
        onUpdate={updateProject}
        prefectures={municipalities as Municipality[]}
      />
    </MainFrame>
  );
};

export default Create;
