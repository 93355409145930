import { DocumentData } from 'firebase/firestore';
import { Machine } from './Productivity';

export interface MasterData {
  id?: string;
  key?: string;
  name: string;
  value: string | number | null;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}

export interface PileDriving {
  key: string;
  designHangTrailMin: number;
  designHangTrailMax: number;
  cuttingHoleDiameter: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Municipality extends MasterData {
  specialWorker: number; // 特殊作業員
  ordinaryWorkers: number; // 普通作業員
  scaffoldWorker: number; // とび工
  welders: number; // 溶接工
  specialDriver: number; // 特殊運転手
  generalDriver: number; // 一般運転手
  generalCivilEngineeringCaretaker: number; // 土木一般世話役
  bridgeCaretaker: number; // 橋りょう世話役
  bridgeSpecialWorkers: number; // 橋りょう特殊工
  lightOil: number; // 軽油
  cement: number; // セメント
  crane16: number; // 16tラクタ
  crane25: number; // 25tラクタ
  order: number;
}
export type MasterTypes = Municipality | Machine;

export const Masters = { Municipality: 'municipality', Machine: 'machine' } as const;
type Masters = (typeof Masters)[keyof typeof Masters];

export interface Crane extends MasterData {
  quantity: number;
  requiredOilAmount: number;
  basePrice: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertMasterData = <T>(data: any): T => {
  return {
    ...(data as T),
    createdAt: data.createdAt.toDate(),
    updatedAt: data.updatedAt.toDate(),
  };
};

export interface DesignPileDiameter {
  value: number;
  min?: number;
  max?: number;
}

export interface DrivenPile extends MasterData {
  value: string;
  drillingDiameter: number;
  designPileDiameter: DesignPileDiameter;
}

export interface InjectionMaterial extends MasterData {
  value: string;
  rate: number;
}

export const convertDrillingHoleLength = (data: DocumentData): DesignPileDiameter => ({
  value: data.value,
  min: data.min,
  max: data.max,
});
