import { ItemizedStatementList } from 'model/ItemizedStatement';
import { Productivity } from 'model/Productivity';

export const statement: ItemizedStatementList = {
  details: [
    {
      name: '直接工事費',
      details: [
        {
          name: 'ダウンザホールハンマー工',
          specification: '',
          unit: '式',
          quantity: 10,
          unitPrice: 2620230,
          subtotal: 26202300,
          remark: '第1号代価表',
        },
        {
          name: 'ダウンザホールハンマー工',
          specification: '',
          unit: '式',
          quantity: 10,
          unitPrice: 2620230,
          subtotal: 26202300,
          remark: '第2号代価表',
        },
      ],
    },
  ],
  total: 26202300 * 2,
};

export const statementDetails: Productivity[] = [
  {
    projectId: '9p8ZvYNumU2XUTIfmi9f',
    id: '1',
    workType: 'hole-hammer',
    name: 'ダウンザホールハンマー工',
    usedMachine: 'rx3300',
    specification: '',
    unit: '式',
    quantity: 10,
    unitPrice: 2620230,
    subtotal: 26202300,
    remark: '第1号代価表',
  },
  {
    projectId: '7p8ZvYNumU2XUTIfmi9f',
    id: '2',
    workType: 'hole-hammer',
    name: 'ダウンザホールハンマー工',
    usedMachine: 'rx2300',
    specification: '',
    unit: '式',
    quantity: 10,
    unitPrice: 2620230,
    subtotal: 26202300,
    remark: '第2号代価表',
  },
];

export const EmptyItemizedStatement: ItemizedStatementList = {
  details: [
    {
      name: '',
      details: [
        {
          name: '',
          specification: '',
          unit: '',
          quantity: 0,
          unitPrice: 0,
          subtotal: 0,
          remark: '',
        },
      ],
    },
  ],
  total: 0,
};
