import { Municipality } from 'model/Master';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

export const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'order', sort: 'asc' }],
  },
};

export const EmptyMunicipality: Municipality = {
  specialWorker: 0,
  ordinaryWorkers: 0,
  scaffoldWorker: 0,
  welders: 0,
  specialDriver: 0,
  generalDriver: 0,
  generalCivilEngineeringCaretaker: 0,
  bridgeCaretaker: 0,
  bridgeSpecialWorkers: 0,
  lightOil: 0,
  cement: 0,
  crane16: 0,
  crane25: 0,
  name: '',
  value: null,
  id: '',
  key: '',
  order: 0,
};
