import { FC, useCallback, useEffect, useState } from 'react';
import MainFrame from 'component/MainFrame';
import CreateFrame from 'component/Master/CreateFrame';
import { RentFeeCalculation, emptyRentFeeCalculation } from 'model/RentFeeCalculation';
import { useErrorsMutators } from 'globalStates/Errors';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchRentFeeCalculations, useSendRentFeeCalculation } from 'usecase/RentFeeCalculation';
import CreateForm from 'component/RentFee/Create';
import { useUserState } from 'globalStates/User';

const Create: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [rentFeeCalculations] = useFetchRentFeeCalculations();
  const sendRentFee = useSendRentFeeCalculation();
  const [rentFee, setRentFee] = useState<RentFeeCalculation>(emptyRentFeeCalculation);
  const setErrors = useErrorsMutators();
  const userState = useUserState();

  useEffect(() => {
    const values = rentFeeCalculations.find((rentFee) => rentFee.id === params.id);
    if (values) {
      setRentFee(values);
    }
  }, [params.id, rentFeeCalculations]);

  const handleClickBack = () => {
    navigate(`/rx-machine`);
  };

  const handleClickConfirm = useCallback(
    async (data: RentFeeCalculation) => {
      const saveData = { ...data, createdBy: userState.user?.id, updatedBy: userState.user?.id };
      try {
        await sendRentFee(saveData);
        navigate(`/rx-machine`);
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      }
    },
    [navigate, sendRentFee, setErrors, userState.user?.id]
  );

  const handleUpdate = useCallback((data: RentFeeCalculation) => {
    setRentFee(data);
  }, []);

  return (
    <MainFrame title="建設機械等損料算定表">
      <CreateFrame title={rentFee.id ? '編集' : '作成'}>
        <CreateForm
          rentFee={rentFee}
          onClickBack={handleClickBack}
          onClickConfirm={handleClickConfirm}
          onUpdate={handleUpdate}
        />
      </CreateFrame>
    </MainFrame>
  );
};

export default Create;
