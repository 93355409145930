import { useCallback, useEffect, useState, useMemo, FC } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  TextField as ReadLabel,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectItem } from 'component/Select';
import { TextField } from 'component/TextField';
import GridEmptySpace from 'component/GridEmptySpace';
import { HoleHammer } from 'model/Productivity/HoleHammer';
import { DesignPileDiameter, MasterData } from 'model/Master';
import {
  AirCompressorItems,
  BondingDaysItems,
  BondingDaysItemsForHSteel,
  BondingDaysItemsForSteelPiles,
  BondingDaysItemsForSteelSheetPiles,
  CorrectionFactorItems,
  CorrectionFactorItemsForHSteelAndSteelSheetPiles,
  CorrectionFactorItemsForSteelPipe,
  DrivenPileItems,
  FillingDaysItems,
  FillingDaysItemsForHSteelAndSteelSheetPiles,
  FillingDaysItemsForSteelPipes,
  JointingItems,
  PreparationDaysItems,
  PreparationDaysItemsForHSteelAndSteelSheetPiles,
  PreparationDaysItemsForSteelPipe,
  ScrewJointItems,
  SpDrillingDiameterItems,
} from 'store/Productivity';
import {
  CraneItems,
  AirCompressorInitialValues,
  WorkCorrectionFactorList,
  WorkCorrectionFactorCategory,
} from 'store/HoleHammer';
import WorkCorrection from 'component/Productivity/WorkCorrection';

interface Props {
  productivity: HoleHammer;
  machines: MasterData[];
  onClickBack: () => void;
  onClickConfirm: (productivity: HoleHammer) => void;
  updateProductivity: (prop: keyof HoleHammer, productivity: HoleHammer) => void;
  onUpdate?: (productivity: HoleHammer) => void;
}

const schema = z.object({
  name: z.string().min(1),
  specification: z.string().min(1),
  usedMachine: z.string(),
  drivenPile: z.string().min(1),
  //drillingHoleLength: DesignPileDiameter; // 設計杭径
  drivenPileLength: z.number().nonnegative(), // 打設杭長
  //drillingDiameter: z.number(), // 削孔径
  preparationDays: z.string(), // 準備・建込み等日数
  scrwJointTimesSelect: z.string(), // スクリュージョイント回数
  fillingDays: z.string(), // 充填日数
  bondingDays: z.string(), // 溶接・ボルト接合日数
  joiningTimesSelect: z.string(), // 溶接・ボルト接合回数
  correctionFactor: z.string(), // 鋼管厚板
  //injectionCastingLength: z.number(), // 根固め長
  //injectionMaterial: z.string(), // 注入材
  //injectionRate: z.number(), // 注入率
  //injectionVolume: z.number(), // 注入量
  //qualitySoil: QualitySoil; // 土質
  sandySoil: z.number().nonnegative(),
  lechySoil: z.number().nonnegative(),
  claySoil: z.number().nonnegative(),
  rockBlock: z.number().nonnegative(),
  softRock: z.number().nonnegative(),
  mediumHardRock: z.number().nonnegative(),
  hardRock: z.number().nonnegative(),
  //totalQualitySoil: z.number(), // 削孔長計
  //correctionFactors: string[]; // 作業補正係数
  //factorTotal: z.number(), // 作業補正係数の合計
  //additionConstraints: WorkCorrectionAdditionItems[]; //作業制約 その他 自由入力項目
  otherFactor: z.object({
    name: z.string(),
    value: z.number(),
  }),
  crane: z.string(), // クレーンありなし
});

const Create: FC<Props> = ({ productivity, machines, onClickBack, onClickConfirm, onUpdate }) => {
  const { control, handleSubmit, watch, setValue } = useForm({ resolver: zodResolver(schema) });
  const [drillingDiameterInputType, setDrillingDiameterInputType] = useState<'textfield' | 'select'>('textfield');
  const [correctionFactors, setCorrectionFactors] = useState<string[]>(productivity.correctionFactors);

  // eslint-disable-next-line
  const convertSelectItem = (item: any): SelectItem => ({
    key: item?.key || '',
    label: item?.name,
    value: item?.key || '',
  });
  const machineItems = useMemo(() => machines.map(convertSelectItem), [machines]);
  const drivenPileItems = useMemo(() => DrivenPileItems.map(convertSelectItem), []);
  const spDrillingDiameterItems = useMemo(() => SpDrillingDiameterItems.map(convertSelectItem), []);
  const preparationDaysItems = useMemo(() => PreparationDaysItems.map(convertSelectItem), []);
  const preparationDaysItemsForSteelPipe = useMemo(() => PreparationDaysItemsForSteelPipe.map(convertSelectItem), []);
  const preparationDaysItemsForHSteelAndSteelSheetPiles = useMemo(
    () => PreparationDaysItemsForHSteelAndSteelSheetPiles.map(convertSelectItem),
    []
  );
  const bondingDaysItems = useMemo(() => BondingDaysItems.map(convertSelectItem), []);
  const jointingItems = useMemo(() => JointingItems.map(convertSelectItem), []);
  const fillingDaysItems = useMemo(() => FillingDaysItems.map(convertSelectItem), []);
  const screwJointItems = useMemo(() => ScrewJointItems.map(convertSelectItem), []);
  const correctionFactorItems = useMemo(() => CorrectionFactorItems.map(convertSelectItem), []);
  const correctionFactorItemsForHSteelAndSteelSheetPiles = useMemo(
    () => CorrectionFactorItemsForHSteelAndSteelSheetPiles.map(convertSelectItem),
    []
  );
  const correctionFactorItemsForSteelPipe = useMemo(() => CorrectionFactorItemsForSteelPipe.map(convertSelectItem), []);
  const craneItems = useMemo(() => CraneItems.map(convertSelectItem), []);
  const airCompressorItems = useMemo(() => AirCompressorItems.map(convertSelectItem), []);

  // 準備・建込み等日数
  const [selectablePreparationDaysItems, setSelectablePreparationDaysItems] = useState(preparationDaysItems);
  // 鋼管厚板
  const [selectableCorrectionFactorItems, setSelectableCorrectionFactorItems] = useState(correctionFactorItems);
  // 溶接・ボルト接合日数
  const [selectableBondingDaysItems, setSelectableBondingDaysItems] = useState(bondingDaysItems);
  // 充填日数
  const [selectableFillingDaysItems, setSelectableFillingDaysItems] = useState(fillingDaysItems);

  const formatDesignPileDiameter = (dpd: DesignPileDiameter): string => {
    if (dpd?.min && dpd?.max) {
      return `${dpd.min}超え${dpd.max}以下`;
    }
    return `${dpd?.value || 0}`;
  };

  const calcFactorTotal = (items: string[]): number =>
    WorkCorrectionFactorList.filter((factor) => items.includes(factor.key || '')).reduce(
      (prev, current) => prev + (current.value || 0),
      0
    );

  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      const newItems = ((items: string[]): string[] => {
        const index = items.indexOf(key);
        if (checked && index === -1) {
          return [...items, key];
        } else if (!checked && index !== -1) {
          return items.filter((c) => key !== c);
        }
        return items;
      })(correctionFactors);
      setCorrectionFactors(newItems);
      const factorTotal = calcFactorTotal(newItems) + productivity.otherFactor.value;
      onUpdate &&
        onUpdate({
          ...productivity,
          correctionFactors: newItems,
          factorTotal,
        });
    },
    [setCorrectionFactors, correctionFactors, productivity, onUpdate]
  );

  // eslint-disable-next-line
  const parseProductivity = (data: any): HoleHammer => {
    const { sandySoil, lechySoil, claySoil, rockBlock, softRock, mediumHardRock, hardRock } = data;
    const totalQualitySoil =
      Number(sandySoil) +
      Number(lechySoil) +
      Number(claySoil) +
      Number(rockBlock) +
      Number(softRock) +
      Number(mediumHardRock) +
      Number(hardRock);
    const { drivenPile } = data;
    const drivenPileItem = DrivenPileItems.find((item) => item.key === drivenPile);
    const drillingHoleLength = drivenPileItem?.designPileDiameter;
    const drillingDiameter = drivenPileItem?.drillingDiameter;
    const { correctionFactor } = data;
    const correctionFactorValue = CorrectionFactorItems.find((item) => item.key === correctionFactor)?.value || 0;
    const { scrwJointTimesSelect } = data;
    const scrwJointTimes = Number.parseInt(scrwJointTimesSelect);
    const { joiningTimesSelect } = data;
    const joiningTimes = Number.parseInt(joiningTimesSelect);
    const { otherFactor } = data;
    const factorTotal = calcFactorTotal(correctionFactors) + otherFactor.value;
    return {
      ...productivity,
      ...data,
      drillingDiameter,
      drillingHoleLength,
      correctionFactorValue,
      totalQualitySoil,
      qualitySoil: { sandySoil, lechySoil, claySoil, rockBlock, softRock, mediumHardRock, hardRock },
      scrwJointTimes,
      joiningTimes,
      factorTotal,
    };
  };

  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    const productivity = parseProductivity(data);
    onClickConfirm(productivity);
  };

  useEffect(() => {
    if (!productivity.drivenPile) {
      return;
    }
    if (
      productivity.drivenPile === 'SP_3' ||
      productivity.drivenPile === 'SP_4' ||
      productivity.drivenPile === 'SP_6'
    ) {
      setDrillingDiameterInputType('select');
      setValue('drillingDiameter', '');
    } else {
      setDrillingDiameterInputType('textfield');
      setValue('drillingDiameter', productivity.drillingDiameter);
    }
    if (!productivity.id) {
      const airCompressor = AirCompressorInitialValues.find((acival) => acival.drivenPile === productivity.drivenPile);

      const airCompressor7_5 = airCompressor?.airCompressor7_5;
      const airCompressor18 = airCompressor?.airCompressor18;
      setValue('airCompressor7_5', airCompressor7_5);
      setValue('airCompressor18', airCompressor18);
    }
  }, [productivity.drillingDiameter, productivity.drivenPile, productivity.id, setValue]);

  useEffect(() => {
    const hSteel = ['H-150', 'H-200', 'H-250', 'H-300', 'H-350', 'H-400'];
    const steelSheetPilesKeys = ['SP_3', 'SP_4', 'SP_6'];
    const steelPipeKeys = ['216_3', '267_4', '318_5', '406_4', '550'];

    // H鋼を選択した場合
    if (hSteel.includes(productivity.drivenPile)) {
      setSelectableBondingDaysItems(BondingDaysItemsForHSteel.map(convertSelectItem));
    } else if (steelSheetPilesKeys.includes(productivity.drivenPile)) {
      // 鋼矢板を選択した場合
      setSelectableBondingDaysItems(BondingDaysItemsForSteelSheetPiles.map(convertSelectItem));
    }

    if (hSteel.includes(productivity.drivenPile) || steelSheetPilesKeys.includes(productivity.drivenPile)) {
      // H鋼または鋼矢板を選択した場合
      setSelectablePreparationDaysItems(preparationDaysItemsForHSteelAndSteelSheetPiles);
      setSelectableCorrectionFactorItems(correctionFactorItemsForHSteelAndSteelSheetPiles);
      setSelectableFillingDaysItems(FillingDaysItemsForHSteelAndSteelSheetPiles.map(convertSelectItem));
    } else if (steelPipeKeys.includes(productivity.drivenPile)) {
      // 銅管を選択した場合
      setSelectablePreparationDaysItems(preparationDaysItemsForSteelPipe);
      setSelectableCorrectionFactorItems(correctionFactorItemsForSteelPipe);
      setSelectableFillingDaysItems(FillingDaysItemsForSteelPipes.map(convertSelectItem));
      setSelectableBondingDaysItems(BondingDaysItemsForSteelPiles.map(convertSelectItem));
    } else {
      // NOTE:「手入力」選択時
      setSelectablePreparationDaysItems(preparationDaysItems);
      setSelectableCorrectionFactorItems(correctionFactorItems);
      setSelectableBondingDaysItems(bondingDaysItems);
      setSelectableFillingDaysItems(fillingDaysItems);
    }

    // NOTE: 変更対象のデータも検知引数とすると無限ループになるため、eslint-disable-next-lineを使用
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bondingDaysItems,
    correctionFactorItems,
    correctionFactorItemsForHSteelAndSteelSheetPiles,
    correctionFactorItemsForSteelPipe,
    fillingDaysItems,
    preparationDaysItems,
    preparationDaysItemsForHSteelAndSteelSheetPiles,
    preparationDaysItemsForSteelPipe,
    productivity.drivenPile,
    setValue,
  ]);

  useEffect(() => {
    // 選択項目の初期化
    if (!selectablePreparationDaysItems.find((item) => item.key === productivity.preparationDays)) {
      setValue('preparationDays', '');
    }
    if (!selectableCorrectionFactorItems.find((item) => item.key === productivity.correctionFactor)) {
      setValue('correctionFactor', '');
    }
    if (!selectableBondingDaysItems.find((item) => item.key === productivity.bondingDays)) {
      setValue('bondingDays', '');
    }
    if (!selectableFillingDaysItems.find((item) => item.key === productivity.fillingDays)) {
      setValue('fillingDays', '');
    }
  }, [
    productivity.bondingDays,
    productivity.correctionFactor,
    productivity.fillingDays,
    productivity.preparationDays,
    selectableBondingDaysItems,
    selectableCorrectionFactorItems,
    selectableFillingDaysItems,
    selectablePreparationDaysItems,
    setValue,
  ]);

  useEffect(() => {
    const subscription = watch((value) => onUpdate && onUpdate(parseProductivity(value)));
    return () => subscription.unsubscribe();
  }, [onUpdate, parseProductivity, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            label="名称"
            variant="standard"
            defaultValue={productivity.name}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            id="specification"
            name="specification"
            label="規格"
            variant="standard"
            defaultValue={productivity.specification}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            required
            fullWidth
            id="machine"
            labelId="used-machine-label"
            label="使用機械"
            name="usedMachine"
            items={machineItems}
            defaultValue={productivity.usedMachine}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            required
            fullWidth
            id="driven-pile"
            labelId="driven-pile-label"
            data-testid="select driven pile"
            label="打設杭"
            name="drivenPile"
            items={drivenPileItems}
            defaultValue={productivity.drivenPile}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ReadLabel
            fullWidth
            id="drilling-hole-length-value"
            name="drillingHoleLengthValue"
            label="設計杭径"
            variant="standard"
            type="number"
            value={productivity.drillingHoleLength?.value}
            //defaultValue={productivity.drillingHoleLength?.value}
            InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            id="driven-pile-length"
            name="drivenPileLength"
            label="打設杭長"
            variant="standard"
            type="number"
            defaultValue={productivity.drivenPileLength}
            control={control}
            InputProps={{
              inputProps: { min: 0, step: '0.1' },
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReadLabel
            fullWidth
            id="drilling-hole-length"
            name="drillingHoleLength"
            label="設計杭径範囲"
            variant="standard"
            value={formatDesignPileDiameter(productivity.drillingHoleLength)}
            //defaultValue={formatDesignPileDiameter(productivity.drillingHoleLength)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {drillingDiameterInputType === 'select' && (
            <Select
              required
              fullWidth
              id="drilling-diameter"
              labelId="drillingDiameter-label"
              label="削孔径"
              name="drillingDiameter"
              items={spDrillingDiameterItems}
              defaultValue={productivity.drillingDiameter}
              control={control}
            />
          )}
          {drillingDiameterInputType === 'textfield' && (
            <TextField
              fullWidth
              id="drilling-diameter"
              name="drillingDiameter"
              label="削孔径"
              variant="standard"
              defaultValue={productivity.drillingDiameter}
              control={control}
              InputProps={{ readOnly: true, endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            />
          )}
        </Grid>
        <Grid item xs={8} sm={4}>
          <Select
            required
            fullWidth
            id="preparation-days"
            labelId="preparation-days-label"
            label="準備・建込み等日数"
            name="preparationDays"
            items={selectablePreparationDaysItems}
            defaultValue={productivity.preparationDays}
            control={control}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Select
            required
            fullWidth
            id="screw-joint"
            labelId="scrw-joint-label"
            label="スクリュージョイント回数"
            name="scrwJointTimesSelect"
            items={screwJointItems}
            defaultValue={productivity.scrwJointTimesSelect}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="filling-days"
            labelId="filling-days-label"
            label="充填日数"
            name="fillingDays"
            items={selectableFillingDaysItems}
            defaultValue={productivity.fillingDays}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="bonding-days"
            label="溶接・ボルト接合日数"
            labelId="bonding-days-label"
            name="bondingDays"
            items={selectableBondingDaysItems}
            defaultValue={productivity.bondingDays}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="joining-times"
            labelId="scrw-joint-label"
            label="溶接・ボルト接合回数"
            name="joiningTimesSelect"
            items={jointingItems}
            defaultValue={productivity.joiningTimesSelect}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="correction-factor"
            label="鋼管厚板"
            labelId="correction-factor-label"
            name="correctionFactor"
            items={selectableCorrectionFactorItems}
            defaultValue={productivity.correctionFactor}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="air-compressor-7_5"
            label="7.5～7.6m3/min 台"
            labelId="air-compressor-7_5-label"
            name="airCompressor7_5"
            items={airCompressorItems}
            defaultValue={productivity.airCompressor7_5}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="air-compressor-18"
            label="18～19m3/min 台"
            labelId="air-compressor-18-label"
            name="airCompressor18"
            items={airCompressorItems}
            defaultValue={productivity.airCompressor18}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            required
            fullWidth
            id="crane"
            label="クレーン"
            labelId="crane-label"
            name="crane"
            items={craneItems}
            defaultValue={productivity.crane}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">{'土質長'}</FormLabel>
            <FormGroup>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="sandy-soil"
                    name="sandySoil"
                    label="砂質土"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.sandySoil}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="lechy-soil"
                    name="lechySoil"
                    label="レキ質土"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.lechySoil}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="clay-soil"
                    name="claySoil"
                    label="粘性土"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.claySoil}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="rock-block"
                    name="rockBlock"
                    label="岩塊玉石"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.rockBlock}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="soft-rock"
                    name="softRock"
                    label="軟岩"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.softRock}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="medium-hard-rock"
                    name="mediumHardRock"
                    label="中硬岩"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.mediumHardRock}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="hard-rock"
                    name="hardRock"
                    label="硬岩"
                    variant="standard"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, step: '0.1' },
                      endAdornment: <InputAdornment position="end">m</InputAdornment>,
                    }}
                    defaultValue={productivity.qualitySoil.hardRock}
                    control={control}
                  />
                </Grid>
                <GridEmptySpace item xs="hidden" sm={6} md={3} />
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <WorkCorrection
            title="作業補正係数"
            selected={correctionFactors}
            workCorrectionCategories={WorkCorrectionFactorCategory}
            workCorrections={WorkCorrectionFactorList}
            onChange={handleChangeCheckbox}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">その他（作業条件・制約、使用機器、N値（非常に高い）など別途考慮）</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="other-work-correction-name"
            name="otherFactor.name"
            label="条件"
            variant="standard"
            defaultValue={productivity.otherFactor?.name || ''}
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="other-work-correction-value"
            name="otherFactor.value"
            label="作業係数"
            variant="standard"
            type="number"
            InputProps={{
              inputProps: { step: '0.1' },
            }}
            defaultValue={productivity.otherFactor?.value || 0}
            control={control}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
          戻る
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
          確認
        </Button>
      </Box>
    </form>
  );
};

export default Create;
