import { useEffect, useState } from 'react';
import { Project, ProjectCondition } from 'model/Project';
import {
  useDeleteProjectsRepository,
  useReadProjectRepository,
  useWriteProjectRepository,
  useWriteProjectsRepository,
} from 'repository/Project';

export const useFetchProjects = (condition?: ProjectCondition): [Project[], unknown | null, () => Promise<void>] => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState<unknown | null>(null);
  const readProject = useReadProjectRepository();
  const fetch = async () => {
    const projects = await readProject(condition);
    setProjects(projects);
  };
  useEffect(() => {
    (async () => {
      setError(null);
      try {
        fetch();
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line
  }, [setProjects]);

  return [projects, error, fetch];
};

export const useSendProject = (): ((project: Project) => Promise<Project>) => {
  const saveProject = useWriteProjectRepository();
  return saveProject;
};

export const useSendProjects = (): ((projects: Project[]) => void) => {
  const saveProjects = useWriteProjectsRepository();
  return saveProjects;
};

export const useDeleteProjects = (): ((projects: Project[]) => void) => {
  const deleteProjects = useDeleteProjectsRepository();
  return deleteProjects;
};
