import { setAudit } from 'api/Audit';
import { db } from 'api/firebase';
import { User } from 'model/User';
import { Location } from 'react-router-dom';

export const useWriteAuditRepository =
  (): ((
    status: 'success' | 'failed',
    location: Location,
    user?: User | null,
    tags?: string[],
    value?: string,
    error?: string
  ) => Promise<void>) =>
  async (
    status: 'success' | 'failed',
    location: Location,
    user?: User | null,
    tags?: string[],
    value?: string,
    error?: string
  ): Promise<void> => {
    return await setAudit(db, {
      status,
      locationKey: location.key || '',
      locationHash: location.hash || '',
      locationPathname: location.pathname || '',
      locationSearch: location.search || '',
      locationState: location.state || '',
      userAgent: window.navigator.userAgent || '',
      userId: user?.id || '',
      tags: tags || [],
      value: value || '',
      error: error || '',
    });
  };
