import {
  doc,
  Firestore,
  setDoc,
  getDocs,
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  collection,
  serverTimestamp,
  deleteDoc,
  query,
  QueryConstraint,
  orderBy,
  updateDoc,
} from 'firebase/firestore';
import { convertMasterData, Municipality } from 'model/Master';
import { Machine } from 'model/Productivity';

const municipalityConverter: FirestoreDataConverter<Municipality> = {
  toFirestore(municipality: Municipality): DocumentData {
    if (!municipality.createdAt) {
      return {
        ...municipality,
        key: municipality.id,
        value: municipality.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
    } else {
      return {
        ...municipality,
        updatedAt: serverTimestamp(),
      };
    }
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Municipality {
    return convertMasterData<Municipality>(snapshot.data(options));
  },
};

export const fetchMunicipalities = async (db: Firestore): Promise<Municipality[]> => {
  const ref = collection(db, 'municipalities').withConverter(municipalityConverter);

  const q: QueryConstraint[] = [];
  q.push(orderBy('order', 'asc'));
  const ss = await getDocs(query(ref, ...q));
  const ret: Municipality[] = [];
  ss.forEach((doc) => ret.push(doc.data()));
  return ret;
};

export const setMunicipality = async (db: Firestore, municipality: Municipality): Promise<Municipality> => {
  if (municipality.id) {
    const docRef = doc(db, 'municipalities', municipality.id).withConverter(municipalityConverter);
    await updateDoc(docRef, { ...municipality, updatedAt: serverTimestamp() });
    return municipality;
  } else {
    const docRef = doc(collection(db, 'municipalities')).withConverter(municipalityConverter);
    await setDoc(docRef, { ...municipality, id: docRef.id });
    return municipality;
  }
};

export const deleteMunicipality = async (db: Firestore, municipalities: Municipality[]): Promise<void[]> => {
  return Promise.all(municipalities.map(async (m) => await deleteDoc(doc(db, 'municipalities', m.id || ''))));
};

const machinesConverter: FirestoreDataConverter<Machine> = {
  toFirestore(machine: Machine): DocumentData {
    if (!machine.createdAt) {
      return {
        ...machine,
        key: machine.id,
        value: machine.id,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
    }
    return {
      ...machine,
      updatedAt: serverTimestamp(),
    };
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Machine {
    return convertMasterData<Machine>(snapshot.data(options));
  },
};

export const fetchMachines = async (db: Firestore): Promise<Machine[]> => {
  const ref = collection(db, 'machines').withConverter(machinesConverter);

  const q: QueryConstraint[] = [];
  q.push(orderBy('order', 'asc'));
  const ss = await getDocs(query(ref, ...q));
  const ret: Machine[] = [];
  ss.forEach((doc) => ret.push(doc.data()));
  return ret;
};

export const setMachine = async (db: Firestore, machine: Machine): Promise<Machine> => {
  if (machine.id) {
    const ref = doc(db, 'machines', machine.id).withConverter(machinesConverter);
    await setDoc(ref, machine);
    return machine;
  } else {
    const docRef = doc(collection(db, 'machines')).withConverter(machinesConverter);
    await setDoc(docRef, { ...machine, id: docRef.id });
    return machine;
  }
};

export const deleteMachine = async (db: Firestore, machines: Machine[]): Promise<void[]> => {
  return Promise.all(machines.map(async (m) => await deleteDoc(doc(db, 'machines', m.id || ''))));
};
