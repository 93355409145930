import { DownloadFile } from 'model/File';
import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

interface DownloadFileState {
  files: DownloadFile[] | undefined;
}

const filesState = atom<DownloadFileState>({
  key: 'files',
  default: {
    files: undefined,
  },
});

export const useDownloadFileState = () => useRecoilValue(filesState);
export const useDownloadFileMutators = () => {
  const setState = useSetRecoilState(filesState);
  const setDownloadFile = useCallback(
    (files: DownloadFile[]) => {
      return setState((previousState: DownloadFileState) => {
        return {
          ...previousState,
          files,
        };
      });
    },
    [setState]
  );

  return [setDownloadFile];
};
