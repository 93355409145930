import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import MasterComponent, { TableType } from 'component/DataList';
import MainFrame from 'component/MainFrame';
import { useDeleteMachine, useDeleteMunicipality, useFetchMaster } from 'usecase/Master';
import { Masters, Municipality } from 'model/Master';
import { useMasterMutators, useMasterState } from 'globalStates/Master';
import { useErrorsMutators } from 'globalStates/Errors';
import { columns as machineColumns, initialState as machineState } from 'store/Machine';
import { initialState as municipalityState } from 'store/Municipality';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { Machine } from 'model/Productivity';
import { format } from 'date-fns';
import { Link } from '@mui/material';
import { useFetchUsers } from 'usecase/User';

const Master: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [masterData, error] = useFetchMaster({ collectionId: params.collectionId || 'municipality' });
  const setErrors = useErrorsMutators();
  const setMasterState = useMasterMutators();
  const masterState = useMasterState();
  const deleteMunicipality = useDeleteMunicipality();
  const deleteMachine = useDeleteMachine();
  const [users] = useFetchUsers();

  const municipalityColumns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: '県名',
        width: 130,
        renderCell: (params: GridRenderCellParams<string>) => (
          <Link key={params.id} color="inherit" href={`/master/municipality/${params.id}`}>
            {params.row.name}
          </Link>
        ),
      },
      {
        field: 'specialWorker',
        headerName: '特殊作業員単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'ordinaryWorkers',
        headerName: '普通作業員単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'scaffoldWorker',
        headerName: 'とび工単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'welders',
        headerName: '溶接工単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'specialDriver',
        headerName: '特殊運転手単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'generalDriver',
        headerName: '一般運転手単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'generalCivilEngineeringCaretaker',
        headerName: '土木一般世話役単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'bridgeCaretaker',
        headerName: '橋りょう世話役単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'bridgeSpecialWorkers',
        headerName: '橋りょう特殊工単価',
        width: 160,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'lightOil',
        headerName: '軽油単価',
        width: 110,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'cement',
        headerName: 'セメント単価',
        width: 110,
        headerAlign: 'left',
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'crane16',
        headerName: '16tラクター',
        width: 110,
        type: 'number',
        headerAlign: 'left',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'crane25',
        headerName: '25tラクター',
        width: 110,
        type: 'number',
        headerAlign: 'left',
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toLocaleString(),
      },
      {
        field: 'order',
        type: 'number',
        headerAlign: 'left',
        headerName: '並び順',
        width: 100,
      },
      {
        field: 'createdAt',
        headerAlign: 'left',
        headerName: '作成日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.createdAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedAt',
        headerAlign: 'left',
        headerName: '最終更新日時',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => format(params.row.updatedAt, 'yyyy年M月d日'),
      },
      {
        field: 'updatedBy',
        headerAlign: 'left',
        headerName: '最終更新者',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => users.find((user) => user.id === params.row.updatedBy)?.name,
      },
    ],
    [users]
  );

  const columns = useMemo((): GridColumns => {
    switch (params.collectionId) {
      case Masters.Municipality:
        return municipalityColumns;
      case Masters.Machine:
        return machineColumns;
      default:
        return [];
    }
  }, [municipalityColumns, params.collectionId]);

  const initialState = useMemo((): GridInitialStateCommunity | undefined => {
    switch (params.collectionId) {
      case Masters.Municipality:
        return municipalityState;
      case Masters.Machine:
        return machineState;
      default:
        return undefined;
    }
  }, [params.collectionId]);

  const handleCreate = () => {
    navigate('new');
  };

  const handleDelete = useCallback(
    async (selectedItems: TableType[]) => {
      try {
        const newItems = masterState.masters?.filter((m) => !selectedItems.includes(m));
        setMasterState(newItems || []);
        switch (params.collectionId) {
          case Masters.Machine:
            await deleteMachine(selectedItems as Machine[]);
            break;
          case Masters.Municipality:
            await deleteMunicipality(selectedItems as Municipality[]);
            break;
        }
      } catch (e) {
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      }
    },
    [deleteMachine, deleteMunicipality, masterState.masters, params.collectionId, setErrors, setMasterState]
  );

  const title = useMemo(() => {
    switch (params.collectionId) {
      case Masters.Machine:
        return '使用機器管理';
      case Masters.Municipality:
        return '公共工事設計労務単価';
      default:
        return '';
    }
  }, [params.collectionId]);

  useEffect(() => {
    if (error) {
      if (error instanceof Error) {
        setErrors({ code: 999, message: error.message });
      } else {
        setErrors({ code: 999, message: error as string });
      }
    }
  }, [error, setErrors]);

  useEffect(() => {
    setMasterState(masterData);
  }, [masterData, setMasterState]);

  return (
    <MainFrame title={title}>
      <MasterComponent
        columns={columns}
        initialState={initialState}
        data={masterState.masters || []}
        onClickNew={handleCreate}
        onClickDelete={handleDelete}
      />
    </MainFrame>
  );
};

export default Master;
