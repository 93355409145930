import { FC, ReactNode } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Copyright from 'component/Copyright';

interface Props {
  title: string;
  children?: ReactNode;
}

const CreateFrame: FC<Props> = ({ title, children }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {title}
          </Typography>
          {children}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default CreateFrame;
