import { db } from 'api/firebase';
import {
  deleteRentFeeCalculations,
  deleteRentFeeNotes,
  fetchRentFeeCalculationNotes,
  fetchRentFeeCalculations,
  getFiscalYear,
  setFiscalYear,
  setRentFeeCalculation,
  setRentFeeNote,
} from 'api/RentFeeCalculations';
import { RentFeeCalculation, RentFeeCalculationNote } from 'model/RentFeeCalculation';

export const useReadRentFeeCalculationsRepository =
  (): (() => Promise<RentFeeCalculation[]>) => async (): Promise<RentFeeCalculation[]> =>
    await fetchRentFeeCalculations(db);

export const useWriteRentFeeCalculationsRepository =
  (): ((rentFee: RentFeeCalculation) => Promise<RentFeeCalculation>) =>
  async (rentFee: RentFeeCalculation): Promise<RentFeeCalculation> =>
    await setRentFeeCalculation(db, rentFee);

export const useDeleteRentFeeCalculationsRepository =
  (): ((ids: string[]) => Promise<void[]>) =>
  async (ids: string[]): Promise<void[]> =>
    await deleteRentFeeCalculations(db, ids);

export const useReadRentFeeNoteRepository =
  (): (() => Promise<RentFeeCalculationNote[]>) => async (): Promise<RentFeeCalculationNote[]> =>
    await fetchRentFeeCalculationNotes(db);

export const useWriteRentFeeNoteRepository =
  (): ((note: RentFeeCalculationNote) => Promise<RentFeeCalculationNote>) =>
  async (note: RentFeeCalculationNote): Promise<RentFeeCalculationNote> =>
    await setRentFeeNote(db, note);

export const useDeleteRentFeeNotesRepository =
  (): ((ids: string[]) => Promise<void[]>) =>
  async (ids: string[]): Promise<void[]> =>
    await deleteRentFeeNotes(db, ids);

export const useReadFiscalYearRepository = (): (() => Promise<string>) => async (): Promise<string> =>
  await getFiscalYear(db);
export const useWriteFiscalYearRepository =
  (): ((year: string) => Promise<string>) =>
  async (year: string): Promise<string> =>
    await setFiscalYear(db, year);
