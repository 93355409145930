import { Crane, Municipality } from 'model/Master';
import { amountRoundUp, round } from './Calc';

export const calcCraneUnitPrice = (municipality: Municipality, crane?: Crane) => {
  if (!crane) {
    return 0;
  }
  const multiplication = (a: number, b: number) => round(a * b, 0);
  const specialDriverPrice = multiplication(municipality.specialDriver, 1);
  const lightOilPrice = multiplication(municipality.lightOil, crane.requiredOilAmount);
  const cranePrice = multiplication(crane.quantity, crane.basePrice);

  const subTotal = specialDriverPrice + lightOilPrice + cranePrice;
  const roundUpTotal = amountRoundUp(subTotal, 1);
  const expenses = roundUpTotal - subTotal;

  const total = subTotal + expenses;

  return total;
};
