import React, { FC } from 'react';
import { Typography, styled } from '@mui/material';
import { ItemizedStatementList } from 'model/ItemizedStatement';
import { MAX_DISPLAYED_ROWS } from 'store/ItemizedStatementReport';

const Root = styled('div')(() => ({
  breakAfter: 'page',
  width: '297mm',
  height: '210mm',
  '@page': {
    size: 'landscape',
    marginTop: '4mm',
    marginBottom: '0mm',
  },
  table: {
    border: '1px solid',
    borderCollapse: 'collapse',
    whiteSpace: 'pre-wrap',
    width: '100%',
    thead: {
      border: '1px solid',
    },
    th: {
      fontWeight: 'normal',
      border: '1px solid',
      height: '1.7rem',
    },
    td: {
      border: '1px solid',
      height: '1.7rem',
    },
  },
}));

const EmptyRows: FC = () => {
  return (
    <tr style={{ height: 33 }}>
      <td style={{ padding: 8, borderRight: 'none', minHeight: 33 }}></td>
      <td style={{ borderLeft: 'none' }}></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
};

interface Props {
  data: ItemizedStatementList;
}

const ItemizedStatementReport: FC<Props> = ({ data }) => {
  const emptyRowsCount = MAX_DISPLAYED_ROWS - data.details[0].details.length;
  return (
    <Root>
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          mb: 2.5,
          textDecoration: 'underline',
          textUnderlineOffset: 5,
        }}
      >
        積算内訳書
      </Typography>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>名称</th>
            <th>規格</th>
            <th>単位</th>
            <th>数量</th>
            <th>単価</th>
            <th>金額</th>
            <th>摘要</th>
          </tr>
        </thead>
        <tbody>
          {data.details.map((detail) => (
            <React.Fragment key={detail.name}>
              {detail.name.length > 0 ? (
                <tr>
                  <td colSpan={2}>{detail.name}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                <tr></tr>
              )}
              {detail.details.map((detail, index) => {
                return (
                  <tr key={`${detail.name}_${index}`}>
                    <td style={{ borderRight: 'none', width: '21px' }}></td>
                    <td style={{ borderLeft: 'none' }}>{detail.name}</td>
                    <td>{detail.specification}</td>
                    <td style={{ textAlign: 'center' }}>{detail.unit}</td>
                    <td style={{ textAlign: 'right' }}>{detail.quantity}</td>
                    <td style={{ textAlign: 'right' }}>{detail.unitPrice && detail.unitPrice.toLocaleString()}</td>
                    <td style={{ textAlign: 'right' }}>{detail.subtotal.toLocaleString()}</td>
                    <td style={{ textAlign: 'center' }}>{detail.remark}</td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
          {[...Array(emptyRowsCount)].map((_, index) => (
            <EmptyRows key={index} />
          ))}
          <tr>
            <td style={{ padding: 8, borderRight: 'none' }}></td>
            <td style={{ borderLeft: 'none' }}>計</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right' }}>{data.total.toLocaleString()}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Root>
  );
};

export default ItemizedStatementReport;
