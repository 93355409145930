import { MasterData } from 'model/Master';
import {
  Casing,
  ClaySoilN,
  CobbleStoneN,
  DrillingDiameter,
  DrivenTime,
  HardRockN,
  InjectionTimeGrout,
  InstallationCount,
  InstallationTime,
  LechySoilN,
  QualitySoil,
  SandFillingTime,
  SandySoilN,
  ScrewJointTime,
  SoftRockN,
  SteelMaterial,
  WorkCorrectionCategory,
  WorkCorrectionFactor,
} from 'model/Productivity';
import { Auger, Soils, SteelMaterialWithTime } from 'model/Productivity/Auger';

/** 基準の数量 */
export const AUGER_STANDARD_QUANTITY = 10;

export const EmptyQualitySoil: QualitySoil = {
  sandySoil: 0,
  lechySoil: 0,
  claySoil: 0,
  rockBlock: 0,
  softRock: 0,
  mediumHardRock: 0,
  hardRock: 0,
};

export const EmptySoils: Soils[] = [
  {
    id: 0,
    soil: '',
    nValue: '',
    value: 0,
  },
  {
    id: 1,
    soil: '',
    nValue: '',
    value: 0,
  },
  {
    id: 2,
    soil: '',
    nValue: '',
    value: 0,
  },
  {
    id: 3,
    soil: '',
    nValue: '',
    value: 0,
  },
];
export const EmptyAuger: Auger = {
  projectId: '',
  name: '',
  usedMachine: '',
  workType: 'auger',
  specification: '',
  unit: '枚',
  quantity: 10,
  unitPrice: 0,
  subtotal: 0,
  remark: '',
  drillingHoleLength: {
    value: 0,
  },
  steelMaterial: '',
  casing: '',
  sandFillingTime: '',
  sandySoilN: '',
  claySoilN: '',
  lechySoilN: '',
  softRockN: '',
  hardRockN: '',
  cobbleStoneN: '',
  screwJointTimes: 0,
  screwJointTimesSelect: '0',
  injectionTimeGrout: '',
  drivenTime: '',
  installationTime: '',
  installationCount: 0,
  installationCountSelect: '0',
  drillingDiameter: '',
  lengthSandySoil: 0,
  lengthClaySoil: 0,
  lengthLechySoil: 0,
  lengthSoftRock: 0,
  lengthHardRock: 0,
  lengthCobbleStone: 0,
  correctionFactors: [],
  factorTotal: 0,
  otherFactor: {
    name: '',
    value: 0,
  },
  soils: EmptySoils,
};

export const WorkCorrectionFactorCategory: WorkCorrectionCategory[] = [
  {
    key: 'construction-quantity',
    name: '施工数量',
  },
  {
    key: 'work-constraints',
    name: '作業制約',
  },
];

export const WorkCorrectionFactorList: WorkCorrectionFactor[] = [
  {
    key: 'less_than_30_bolt',
    category: 'construction-quantity',
    name: '30本以下',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_100',
    category: 'construction-quantity',
    name: '100枚以下',
    value: 0.05,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_20',
    category: 'construction-quantity',
    name: '20本以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_50',
    category: 'construction-quantity',
    name: '50本以下',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_10',
    category: 'construction-quantity',
    name: '10本以下',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'less_than_30',
    category: 'construction-quantity',
    name: '30枚以下',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'night_work',
    category: 'work-constraints',
    name: '夜間作業',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'small_impact_work_restrictions',
    category: 'work-constraints',
    name: '作業制限影響小（狭隘・上空制限）',
    value: 0.1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'big_impact_work_restrictions',
    category: 'work-constraints',
    name: '作業制限影響大（狭隘・上空制限）',
    value: 0.2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

const NValue = [
  {
    key: 'N20',
    name: '20未満',
    value: 'N20',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'N20-40',
    name: '20以上~40未満',
    value: 'N20-40',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'N40-50',
    name: '40以上~50未満',
    value: 'N40-50',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'N50-70',
    name: '50以上~70未満',
    value: 'N50-70',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'N70-100',
    name: '70以上~100未満',
    value: 'N70-100',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'N100-150',
    name: '100以上~150未満',
    value: 'N100',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const NValuesPerDrillingDiameter = [
  {
    drillingDiameter: 'D280',
    nValues: [
      {
        key: 'N20',
        value: 2,
      },
      {
        key: 'N20-40',
        value: 2.4,
      },
      {
        key: 'N40-50',
        value: 3.2,
      },
      {
        key: 'N50-70',
        value: 4.8,
      },
      {
        key: 'N70-100',
        value: 8,
      },
      {
        key: 'N100-150',
        value: 16,
      },
    ],
  },
  {
    drillingDiameter: 'D500',
    nValues: [
      {
        key: 'N20',
        value: 2.5,
      },
      {
        key: 'N20-40',
        value: 3,
      },
      {
        key: 'N40-50',
        value: 4,
      },
      {
        key: 'N50-70',
        value: 6,
      },
      {
        key: 'N70-100',
        value: 10,
      },
      {
        key: 'N100-150',
        value: 20,
      },
    ],
  },
  {
    drillingDiameter: 'D600',
    nValues: [
      {
        key: 'N20',
        value: 3,
      },
      {
        key: 'N20-40',
        value: 3.6,
      },
      {
        key: 'N40-50',
        value: 4.8,
      },
      {
        key: 'N50-70',
        value: 7.2,
      },
      {
        key: 'N70-100',
        value: 12,
      },
      {
        key: 'N100-150',
        value: 24,
      },
    ],
  },
];

export const SteelMaterialItems: SteelMaterial[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h',
    name: 'Ｈ鋼',
    value: 'h',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'steel',
    name: '鋼矢板',
    value: 'steel',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SteelMaterialPreparationTimes: SteelMaterialWithTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    preparationTime: 5,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'h',
    name: 'Ｈ鋼',
    value: 'h',
    preparationTime: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'steel',
    name: '鋼矢板',
    value: 'steel',
    preparationTime: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const CasingItems: Casing[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    preparationTime: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '6',
    name: '6mまで',
    value: '6',
    preparationTime: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '12',
    name: '12mまで',
    value: '12',
    preparationTime: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SandFillingTimeItems: SandFillingTime[] = [
  {
    key: '_',
    name: '充填なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'SF170-8',
    name: '170mm以上320mm以下 ℓ≦8',
    value: 'SF170-8',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'SF170-12',
    name: '170mm以上320mm以下 8<ℓ≦12',
    value: 'SF170-12',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-8',
    name: '320mm超え580mm以下 ℓ≦8',
    time: 15,
    value: 'I320-8',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-12',
    name: '320mm超え580mm以下 8<ℓ≦12',
    time: 25,
    value: 'I320-12',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const DrillingDiameterItems: DrillingDiameter[] = [
  {
    key: 'D280',
    name: '450mm以下',
    value: 'D280',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'D500',
    name: '500-550(mm)',
    value: 'D500',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'D600',
    name: '600(mm)',
    value: 'D600',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const SandySoilNItems: SandySoilN[] = NValue;

export const ClaySoilNItems: ClaySoilN[] = NValue;

export const LechySoilNItems: LechySoilN[] = NValue;

export const SoftRockNItems: SoftRockN[] = NValue;

export const HardRockNItems: HardRockN[] = NValue;

export const CobbleStoneNItems: CobbleStoneN[] = NValue;

export const SoilItems: MasterData[] = [
  {
    key: 'sandy-soil',
    name: '砂質土',
    value: 'sandy-soil',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'clay-soil',
    name: '粘性土',
    value: 'clay-soil',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'lechy-soil',
    name: 'レキ質土',
    value: 'lechy-soil',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'soft-rock',
    name: '軟岩',
    value: 'soft-rock',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'hard-rock',
    name: '硬岩',
    value: 'hard-rock',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'cobble-stone',
    name: '玉石',
    value: 'cobble-stone',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const ScrewJointTimeItems: ScrewJointTime[] = [
  {
    key: '0',
    name: '0',
    value: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '1',
    name: '1',
    value: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: '2',
    value: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: '3',
    value: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: '4',
    value: 4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InjectionTimeGroutItems: InjectionTimeGrout[] = [
  {
    key: '_',
    name: '注入なし',
    time: 0,
    value: '_',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I170-8',
    name: '170mm以上320mm以下 ℓ≦8',
    time: 15,
    value: 'I170-8',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I170-12',
    name: '170mm以上320mm以下 8<ℓ≦12',
    time: 20,
    value: 'I170-12',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-8',
    name: '320mm超え580mm以下 ℓ≦8',
    time: 20,
    value: 'I320-8',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-12',
    name: '320mm超え580mm以下 8<ℓ≦12',
    time: 30,
    value: 'I320-12',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const DrivenTimeItems: DrivenTime[] = [
  {
    key: '_',
    name: '打込なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I170-8',
    name: '170mm以上320mm以下 ℓ≦8',
    value: 'I170-8',
    time: 8,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I170-12',
    name: '170mm以上320mm以下 8<ℓ≦12',
    value: 'I170-12',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-8',
    name: '320mm超え580mm以下 ℓ≦8',
    value: 'I320-8',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'I320-12',
    name: '320mm超え580mm以下 8<ℓ≦12',
    value: 'I320-12',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationTimeItems: InstallationTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H150',
    name: 'H150〜175形鋼溶接',
    value: 'H150',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H200',
    name: 'H200形鋼溶接',
    value: 'H200',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H250',
    name: 'H250形鋼溶接',
    value: 'H250',
    time: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H300',
    name: 'H300形鋼溶接',
    value: 'H300',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H350',
    name: 'H350形鋼溶接',
    value: 'H350',
    time: 45,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H400',
    name: 'H400形鋼溶接',
    value: 'H400',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB150',
    name: 'H150～175形鋼ボルト取付',
    value: 'HB150',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB200',
    name: 'H200形鋼ボルト取付',
    value: 'HB200',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB250',
    name: 'H250形鋼ボルト取付',
    value: 'HB250',
    time: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB300',
    name: 'H300形鋼ボルト取付',
    value: 'HB300',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB350',
    name: 'H350形鋼ボルト取付',
    value: 'HB350',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB400',
    name: 'H400形鋼ボルト取付',
    value: 'HB400',
    time: 35,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S2',
    name: '鋼矢板溶接Ⅱ型',
    value: 'S2',
    time: 18,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S3',
    name: '鋼矢板溶接Ⅲ型',
    value: 'S3',
    time: 22,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S4',
    name: '鋼矢板溶接Ⅳ型',
    value: 'S4',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S5',
    name: '鋼矢板溶接VL型',
    value: 'S5',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S2W',
    name: '鋼矢板溶接Ⅱw型',
    value: 'S2W',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S3W',
    name: '鋼矢板溶接Ⅲw型',
    value: 'S3W',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S4W',
    name: '鋼矢板溶接Ⅳw型',
    value: 'S4W',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationTimeItemNone: InstallationTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationTimeItemsForHSteel: InstallationTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H150',
    name: 'H150〜175形鋼溶接',
    value: 'H150',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H200',
    name: 'H200形鋼溶接',
    value: 'H200',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H250',
    name: 'H250形鋼溶接',
    value: 'H250',
    time: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H300',
    name: 'H300形鋼溶接',
    value: 'H300',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H350',
    name: 'H350形鋼溶接',
    value: 'H350',
    time: 45,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'H400',
    name: 'H400形鋼溶接',
    value: 'H400',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB150',
    name: 'H150～175形鋼ボルト取付',
    value: 'HB150',
    time: 10,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB200',
    name: 'H200形鋼ボルト取付',
    value: 'HB200',
    time: 15,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB250',
    name: 'H250形鋼ボルト取付',
    value: 'HB250',
    time: 20,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB300',
    name: 'H300形鋼ボルト取付',
    value: 'HB300',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB350',
    name: 'H350形鋼ボルト取付',
    value: 'HB350',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'HB400',
    name: 'H400形鋼ボルト取付',
    value: 'HB400',
    time: 35,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationTimeItemsForSteelSheetPiles: InstallationTime[] = [
  {
    key: '_',
    name: 'なし',
    value: '_',
    time: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S2',
    name: '鋼矢板溶接Ⅱ型',
    value: 'S2',
    time: 18,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S3',
    name: '鋼矢板溶接Ⅲ型',
    value: 'S3',
    time: 22,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S4',
    name: '鋼矢板溶接Ⅳ型',
    value: 'S4',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S5',
    name: '鋼矢板溶接VL型',
    value: 'S5',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S2W',
    name: '鋼矢板溶接Ⅱw型',
    value: 'S2W',
    time: 25,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S3W',
    name: '鋼矢板溶接Ⅲw型',
    value: 'S3W',
    time: 30,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: 'S4W',
    name: '鋼矢板溶接Ⅳw型',
    value: 'S4W',
    time: 60,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationCountItems: InstallationCount[] = [
  {
    key: '0',
    name: '0',
    value: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '1',
    name: '1',
    value: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '2',
    name: '2',
    value: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '3',
    name: '3',
    value: 3,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    key: '4',
    name: '4',
    value: 4,
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const InstallationTimeFactors = [
  {
    key: '_',
    name: 'なし',
    value: 0,
  },
  {
    key: 'H150',
    name: 'H形鋼溶接150～175',
    value: 1,
  },
  {
    key: 'H200',
    name: 'H形鋼溶接200',
    value: 1,
  },
  {
    key: 'H250',
    name: 'H形鋼溶接250',
    value: 1,
  },
  {
    key: 'H300',
    name: 'H形鋼溶接300',
    value: 1,
  },
  {
    key: 'H350',
    name: 'H形鋼溶接350',
    value: 1,
  },
  {
    key: 'H400',
    name: 'H形鋼溶接400',
    value: 1,
  },
  {
    key: 'HB150',
    name: 'H形鋼ボルト取付150～175',
    value: 0,
  },
  {
    key: 'HB200',
    name: 'H形鋼ボルト取付200',
    value: 0,
  },
  {
    key: 'HB250',
    name: 'H形鋼ボルト取付250',
    value: 0,
  },
  {
    key: 'HB300',
    name: 'H形鋼ボルト取付300',
    value: 0,
  },
  {
    key: 'HB350',
    name: 'H形鋼ボルト取付350',
    value: 0,
  },
  {
    key: 'HB400',
    name: 'H形鋼ボルト取付400',
    value: 0,
  },
  {
    key: 'S2',
    name: '鋼矢板溶接Ⅱ型',
    value: 1,
  },
  {
    key: 'S3',
    name: '鋼矢板溶接Ⅲ型',
    value: 1,
  },
  {
    key: 'S4',
    name: '鋼矢板溶接Ⅳ型',
    value: 1,
  },
  {
    key: 'S5',
    name: '鋼矢板溶接Ⅴ型',
    value: 1,
  },
  {
    key: 'S2W',
    name: '鋼矢板溶接Ⅱw型',
    value: 1,
  },
  {
    key: 'S3W',
    name: '鋼矢板溶接Ⅲw型',
    value: 1,
  },
  {
    key: 'S4W',
    name: '鋼矢板溶接Ⅳw型',
    value: 1,
  },
];
