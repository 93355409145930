import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MainFrame from 'component/MainFrame';
import MunicipalityForm from 'component/Master/Municipality/Create';
import MachineForm from 'component/Master/UsedMachine/Create';
import { EmptyMunicipality } from 'store/Municipality';
import CreateFrame from 'component/Master/CreateFrame';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Masters, MasterTypes, Municipality } from 'model/Master';
import { useSendMunicipality, useSendMachine } from 'usecase/Master';
import { useErrorsMutators } from 'globalStates/Errors';
import { EmptyMachine } from 'store/Machine';
import { Machine } from 'model/Productivity';
import { useMasterState } from 'globalStates/Master';
import { useUserState } from 'globalStates/User';
import { useSendAudit } from 'usecase/Audit';

const Create: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const sendMunicipality = useSendMunicipality();
  const sendMachine = useSendMachine();
  const masterState = useMasterState();
  const userState = useUserState();
  const [masterData, setMasterData] = useState<MasterTypes>();
  const setErrors = useErrorsMutators();
  const postAudit = useSendAudit();
  const location = useLocation();

  const handleClickBack = () => {
    navigate(`/master/${params.collectionId}`);
  };

  const handleClickConfirm = useCallback(
    async (data: MasterTypes) => {
      const saveData = { ...data, createdBy: userState.user?.id, updatedBy: userState.user?.id };
      try {
        switch (params.collectionId) {
          case Masters.Machine:
            await sendMachine(saveData as Machine);
            break;
          case Masters.Municipality:
            await sendMunicipality(saveData as Municipality);
            break;
          default:
            break;
        }
        postAudit(
          'success',
          location,
          userState.user,
          ['create', params.collectionId || 'master'],
          JSON.stringify(saveData)
        );
        navigate(`/master/${params.collectionId}`);
      } catch (e) {
        postAudit(
          'success',
          location,
          userState.user,
          ['create', params.collectionId || 'master'],
          JSON.stringify(saveData),
          JSON.stringify(e)
        );
        if (e instanceof Error) {
          setErrors({ code: 999, message: e.message });
        } else {
          setErrors({ code: 999, message: e as string });
        }
      }
    },
    [location, navigate, params.collectionId, postAudit, sendMachine, sendMunicipality, setErrors, userState.user]
  );

  const handleUpdate = (data: MasterTypes) => {
    setMasterData(data);
  };

  const title = useMemo(() => {
    switch (params.collectionId) {
      case 'machine':
        return '使用機器管理';
      default:
        return '公共工事設計労務単価';
    }
  }, [params.collectionId]);

  useEffect(() => {
    if (masterState.masters && !masterData) {
      const maxOrder = Math.max(...masterState.masters.map((d) => d.order));
      setMasterData({ ...(masterData || EmptyMunicipality), order: maxOrder + 1 });
    }
  }, [masterState, masterData]);

  const renderEditComponent = (cid?: string) => {
    switch (cid) {
      case 'municipality':
        return (
          <CreateFrame title="単価新規作成">
            <MunicipalityForm
              municipality={(masterData as Municipality) || EmptyMunicipality}
              onClickBack={handleClickBack}
              onClickConfirm={handleClickConfirm}
              onUpdate={handleUpdate}
            />
          </CreateFrame>
        );
      case 'machine':
        return (
          <CreateFrame title="使用機器新規作成">
            <MachineForm
              machine={(masterData as Machine) || EmptyMachine}
              onClickBack={handleClickBack}
              onClickConfirm={handleClickConfirm}
              onUpdate={(machine) => console.log(machine)}
            />
          </CreateFrame>
        );
      default:
        return <div />;
    }
  };
  return <MainFrame title={title}>{renderEditComponent(params.collectionId)}</MainFrame>;
};

export default Create;
