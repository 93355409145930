import { FC, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from 'component/TextField';
import { Municipality } from 'model/Master';
import GridEmptySpace from 'component/GridEmptySpace';

interface Props {
  municipality: Municipality;
  onClickBack: () => void;
  onClickConfirm: (municipality: Municipality) => void;
  onUpdate?: (municipality: Municipality) => void;
}

const schema = z.object({
  name: z.string(),
  specialWorker: z.number().nonnegative(),
  ordinaryWorkers: z.number().nonnegative(),
  scaffoldWorker: z.number().nonnegative(),
  welders: z.number().nonnegative(),
  specialDriver: z.number().nonnegative(),
  generalDriver: z.number().nonnegative(),
  generalCivilEngineeringCaretaker: z.number().nonnegative(),
  bridgeCaretaker: z.number().nonnegative(),
  bridgeSpecialWorkers: z.number().nonnegative(),
  lightOil: z.number().nonnegative(),
  cement: z.number().nonnegative(),
  crane16: z.number().nonnegative(),
  crane25: z.number().nonnegative(),
  order: z.number().nonnegative(),
});

const Create: FC<Props> = ({ municipality, onClickBack, onUpdate, onClickConfirm }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });
  console.log('error', errors);

  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    onClickConfirm(parse(data));
  };
  // eslint-disable-next-line
  const parse = (data: any): Municipality => {
    return { ...municipality, ...data };
  };

  useEffect(() => {
    Object.entries(municipality).map(([name, value]) => setValue(name, value));
  }, [municipality, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parse(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, parse, watch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item container xs={12} spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="name"
              name="name"
              label="県名"
              type="text"
              variant="standard"
              defaultValue={municipality.name}
              control={control}
            />
          </Grid>
          <GridEmptySpace />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="specialWorker"
              name="specialWorker"
              label="特殊作業員単価"
              type="number"
              variant="standard"
              defaultValue={municipality.specialWorker}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="ordinaryWorkers"
              name="ordinaryWorkers"
              label="普通作業員単価"
              type="number"
              variant="standard"
              defaultValue={municipality.ordinaryWorkers}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="scaffoldWorker"
              name="scaffoldWorker"
              label="とび工単価"
              type="number"
              variant="standard"
              defaultValue={municipality.scaffoldWorker}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="welders"
              name="welders"
              label="溶接工単価"
              type="number"
              variant="standard"
              defaultValue={municipality.welders}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="specialDriver"
              name="specialDriver"
              label="特殊運転手単価"
              type="number"
              variant="standard"
              defaultValue={municipality.specialDriver}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="generalDriver"
              name="generalDriver"
              label="一般運転手単価"
              type="number"
              variant="standard"
              defaultValue={municipality.generalDriver}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="generalCivilEngineeringCaretaker"
              name="generalCivilEngineeringCaretaker"
              label="土木一般世話役単価"
              type="number"
              variant="standard"
              defaultValue={municipality.generalCivilEngineeringCaretaker}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="bridgeCaretaker"
              name="bridgeCaretaker"
              label="橋りょう世話役単価"
              type="number"
              variant="standard"
              defaultValue={municipality.bridgeCaretaker}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={3} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="bridgeSpecialWorkers"
              name="bridgeSpecialWorkers"
              label="橋りょう特殊工単価"
              type="number"
              variant="standard"
              defaultValue={municipality.bridgeSpecialWorkers}
              control={control}
            />
          </Grid>
          <GridEmptySpace />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="lightOil"
              name="lightOil"
              label="軽油単価"
              type="number"
              variant="standard"
              defaultValue={municipality.lightOil}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="cement"
              name="cement"
              label="セメント単価"
              type="number"
              variant="standard"
              defaultValue={municipality.cement}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="crane16"
              name="crane16"
              label="16tラクター"
              type="number"
              variant="standard"
              defaultValue={municipality.crane16}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="crane25"
              name="crane25"
              label="25tラクター"
              type="number"
              variant="standard"
              defaultValue={municipality.crane25}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="order"
              name="order"
              label="並び順"
              type="number"
              variant="standard"
              defaultValue={municipality.order}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
          戻る
        </Button>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
          確認
        </Button>
      </Box>
    </form>
  );
};

export default Create;
