import { FC, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  styled,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useDownloadFileState } from 'globalStates/Files';
import DescriptionIcon from '@mui/icons-material/Description';
import { useMenuState } from 'globalStates/Menu';
import { useSendAudit } from 'usecase/Audit';
import { useUserState } from 'globalStates/User';

const drawerWidth = 240;

interface Props {
  open: boolean;
  onCloseMenu?: () => void;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const SideMenu: FC<Props> = ({ open, onCloseMenu }) => {
  const files = useDownloadFileState();
  const navigate = useNavigate();
  const menu = useMenuState().menu;
  const postAudit = useSendAudit();
  const user = useUserState();
  const location = useLocation();

  const handleDownload = useCallback(
    (path: string, fileName: string) => {
      postAudit('success', location, user.user, ['download', fileName], undefined, undefined);
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = path;
      link.download = fileName + '.pdf';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
    [location, postAudit, user.user]
  );

  return (
    <Drawer
      sx={{
        width: 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          ...(open && {
            width: drawerWidth,
          }),
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={onCloseMenu}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menu.map(({ text, icon, to }) => (
          <ListItem key={to}>
            <ListItemButton onClick={() => navigate(to)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
        {files.files &&
          files.files.map((file) => (
            <ListItem key={file.sourceName}>
              <ListItemButton onClick={() => handleDownload(file.path, file.fileName)}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={file.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
