import { FC } from 'react';
import { Avatar, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
  name: string;
  email: string;
}

const AccountCard: FC<Props> = ({ name, email }) => {
  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item sx={{ mr: 2 }}>
        <Avatar sx={{ width: 48, height: 48 }}>
          <PersonIcon fontSize="large" />
        </Avatar>
      </Grid>
      <Grid item>
        <div>
          <Typography variant="body2" component="div">
            {name}
          </Typography>
          <Typography variant="body2" component="div">
            {email}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default AccountCard;
