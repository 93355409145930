import { useState, useEffect, useCallback } from 'react';
import { Masters, MasterTypes, Municipality } from 'model/Master';
import { Machine } from 'model/Productivity';
import {
  useReadMachineRepository,
  useReadMunicipalityRepository,
  useWriteMachineRepository,
  useWriteMunicipalityRepository,
  useDeleteMunicipalityRepository,
  useDeleteMachineRepository,
} from 'repository/Master';

export interface masterCondition {
  collectionId: string;
  documentId?: string;
}

export const useFetchMaster = (condition: masterCondition): [MasterTypes[], unknown | null] => {
  const [masterData, setMasterData] = useState<MasterTypes[]>([]);
  const [error, setError] = useState<unknown | null>(null);
  const readMunicipality = useReadMunicipalityRepository();
  const readMachine = useReadMachineRepository();

  const fetchData = useCallback(
    async (condition: masterCondition): Promise<MasterTypes[]> => {
      switch (condition.collectionId) {
        case Masters.Municipality:
          return await readMunicipality();
        case Masters.Machine:
          return await readMachine();
        default:
          return [];
      }
    },
    [readMachine, readMunicipality]
  );

  useEffect(() => {
    (async () => {
      setError(null);
      try {
        const data = await fetchData(condition);
        setMasterData(data);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // URL変更のみを検知するため、依存関数を制限しています
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition.collectionId]);

  return [masterData, error];
};

export const useSendMunicipality = (): ((municipality: Municipality) => Promise<Municipality>) => {
  return useWriteMunicipalityRepository();
};
export const useDeleteMunicipality = (): ((municipalities: Municipality[]) => Promise<void[]>) => {
  return useDeleteMunicipalityRepository();
};

export const useSendMachine = (): ((machine: Machine) => Promise<Machine>) => {
  return useWriteMachineRepository();
};
export const useDeleteMachine = (): ((machines: Machine[]) => Promise<void[]>) => {
  return useDeleteMachineRepository();
};
