/* eslint-disable  @typescript-eslint/no-explicit-any */
import { styled } from '@mui/system';
import { Grid } from '@mui/material';

const HIDDEN = 'hidden';
const GridSupportingHidingAtSpecifiedBreakpoints = styled(Grid)`
  @media (min-width: ${(p) => p.theme.breakpoints.values.xs}px) {
    display: ${(p: any) => (p.xs === HIDDEN ? 'none' : 'flex')};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.values.sm}px) {
    display: ${(p: any) => (p.sm === HIDDEN ? 'none' : 'flex')};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.values.md}px) {
    display: ${(p: any) => (p.md === HIDDEN ? 'none' : 'flex')};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.values.lg}px) {
    display: ${(p: any) => (p.lg === HIDDEN ? 'none' : 'flex')};
  }
  @media (min-width: ${(p) => p.theme.breakpoints.values.xl}px) {
    display: ${(p: any) => (p.xl === HIDDEN ? 'none' : 'flex')};
  }
`;

const GridEmptySpace: React.FC<any> = (props) => <GridSupportingHidingAtSpecifiedBreakpoints item {...props} />;

export default GridEmptySpace;
