import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ItemizedStatementReport from 'component/Report/ItemizedStatementReport';
import { Productivity } from 'model/Productivity';
import { ItemizedStatementDetail, ItemizedStatementList } from 'model/ItemizedStatement';
import { EmptyItemizedStatement } from 'store/test/ItemizedStatement';
import { useFetchProjects } from 'usecase/Project';
import { MAX_DISPLAYED_ROWS } from 'store/ItemizedStatementReport';
import { useErrorsMutators } from 'globalStates/Errors';
import { amountRoundUp, round } from 'api/calc/Calc';
import { WorkType } from 'model/WorkType';

const Report: FC = () => {
  const location = useLocation();
  const projectId = location.search.replace('?projectId=', '');
  const [projects, error] = useFetchProjects({ id: projectId });
  const [itemized, setItemized] = useState<ItemizedStatementList[]>();
  const setErrors = useErrorsMutators();

  const createItemizedStatementReport = useCallback((productivities: Productivity[]): ItemizedStatementList[] => {
    const pageNum = Math.ceil(productivities.length / MAX_DISPLAYED_ROWS);

    const paginatedProd = [...Array(pageNum)]
      .map((_, i) => i)
      .map((index) => {
        const min = index * MAX_DISPLAYED_ROWS;
        const max = (index + 1) * MAX_DISPLAYED_ROWS;
        return productivities.slice(min, max);
      });

    const filteredItemizedDetails = (productivities: Productivity[]): ItemizedStatementDetail[] => {
      return productivities
        .filter((prod) => prod.workType !== WorkType.CARRY)
        .map((prod: Productivity): ItemizedStatementDetail => {
          const total = () => {
            const unit = prod.unitPrice || 0;
            const quantity = prod.quantity || 0;
            if (prod.workType === WorkType.HOLE_HAMMER) {
              return round(unit * quantity);
            }
            return amountRoundUp(unit * quantity);
          };
          return {
            name: prod.name || '',
            specification: prod.specification || '',
            unit: prod.unit || '式',
            quantity: prod.quantity || 0,
            unitPrice: prod.unitPrice,
            subtotal: total(),
            remark: `第${parseInt(prod.id || '1') + 1}号代価表`, // FIXME: 本来prodにidは存在するが、現状任意指定していたため初期値1を指定してます
          };
        });
    };

    const filteredCarryDetails = (productivities: Productivity[]): ItemizedStatementDetail[] => {
      return productivities
        .filter((prod) => prod.workType === WorkType.CARRY)
        .map((prod: Productivity): ItemizedStatementDetail => {
          const total = () => {
            const unit = prod.unitPrice || 0;
            const quantity = prod.quantity || 0;
            return amountRoundUp(unit * quantity);
          };
          return {
            name: prod.name || '',
            specification: prod.specification || '',
            unit: prod.unit || '式',
            quantity: prod.quantity || 0,
            unitPrice: prod.unitPrice,
            subtotal: total(),
            remark: `第${parseInt(prod.id || '1') + 1}号代価表`, // FIXME: 本来prodにidは存在するが、現状任意指定していたため初期値1を指定してます
          };
        });
    };

    const calcTotal = (productivities: Productivity[]): number => {
      const total = (prod: Productivity) => {
        const unit = prod.unitPrice || 0;
        const quantity = prod.quantity || 0;
        if (prod.workType === WorkType.CARRY) {
          return unit * quantity;
        }
        if (prod.workType === WorkType.HOLE_HAMMER) {
          return round(unit * quantity);
        }
        return amountRoundUp(unit * quantity);
      };
      return productivities.reduce((initial, prod) => initial + total(prod), 0);
    };

    return paginatedProd.map((prod) => {
      const carryDetail =
        filteredCarryDetails(prod).length > 0
          ? [
              {
                name: ' ', // NOTE: 運搬費を表示する場合は直前に空行を挿入する
                details: [],
              },
              {
                name: '運搬費',
                details: filteredCarryDetails(prod),
              },
            ]
          : [];
      return {
        total: calcTotal(prod),
        details: [
          {
            name: '直接工事費',
            details: filteredItemizedDetails(prod),
          },
        ].concat(carryDetail),
      };
    });
  }, []);

  useEffect(() => {
    if (!projects || projects.length === 0) {
      return;
    }
    const project = projects[0];
    if (!project) {
      return;
    }
    setItemized(createItemizedStatementReport(project.productivities));
  }, [createItemizedStatementReport, projectId, projects]);

  useEffect(() => {
    const showError = (errors: unknown[]) => {
      errors.map((e) => {
        if (e) {
          if (e instanceof Error) {
            setErrors({ code: 999, message: e.message });
          } else {
            setErrors({ code: 999, message: e as string });
          }
        }
      });
    };
    showError([error]);
  }, [error, setErrors]);
  return (
    <div>
      {itemized &&
        itemized.map((item, index) => <ItemizedStatementReport key={index} data={item || EmptyItemizedStatement} />)}
    </div>
  );
};

export default Report;
