import { FC } from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import Base, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & {
  name: string;
  // eslint-disable-next-line
  defaultValue?: any;
  // eslint-disable-next-line
  control?: Control<FieldValues, any>;
};

export const TextField: FC<Props> = (props) => {
  const transform = (
    (tp?: string) =>
    (value: string): string | number => {
      if (tp !== 'number') {
        return value;
      }

      const output = parseFloat(value);
      return isNaN(output) ? 0 : output;
    }
  )(props.type);
  //const transform = tf(props.type);
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={props.defaultValue}
      render={({ field, fieldState }) => (
        <Base
          {...props}
          {...field}
          onChange={(e) => field.onChange(transform(e.target.value))}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
};
