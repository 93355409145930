import { FC } from 'react';
import { Box, Button } from '@mui/material';
import { WorkType } from 'model/WorkType';
import { Container } from '@mui/system';

interface Props {
  onSelected?: (wt: WorkType) => void;
  onBack?: () => void;
}

const SelectWorkType: FC<Props> = ({ onSelected, onBack }) => {
  const handleClick = (wt: WorkType) => () => {
    onSelected && onSelected(wt);
  };
  return (
    <Container sx={{ mt: 8, justifyContent: 'center' }}>
      <Box maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 'auto' }}>
        <Button
          fullWidth
          variant="contained"
          data-testid="button down the hole hammer"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick(WorkType.HOLE_HAMMER)}
        >
          ダウンザホールハンマー工
        </Button>
        <Button
          fullWidth
          variant="contained"
          data-testid="button auger"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick(WorkType.AUGER)}
        >
          オーガ工
        </Button>
        <Button
          fullWidth
          variant="contained"
          data-testid="button hammer in"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick(WorkType.HAMMER_IN)}
        >
          打込
        </Button>
        <Button
          fullWidth
          variant="contained"
          data-testid="button pull out"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick(WorkType.PULL_OUT)}
        >
          引抜
        </Button>
        <Button
          fullWidth
          variant="contained"
          data-testid="button carry"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick(WorkType.CARRY)}
        >
          運搬
        </Button>
        <Button
          fullWidth
          variant="outlined"
          data-testid="button back"
          sx={{ mt: 8, mb: 2 }}
          onClick={() => onBack && onBack()}
        >
          戻る
        </Button>
      </Box>
    </Container>
  );
};

export default SelectWorkType;
