/* eslint-disable react/jsx-key */
import { FC, useState } from 'react';
import { DataGrid, GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import { Project } from 'model/Project';
import { defaultPageSize, rowsPerPage } from 'store/Pagination';

interface Props {
  columns: GridColumns<Project>;
  projects: Project[];
  selectionModel?: GridSelectionModel;
  setSelectionModel?: (selectionModel: GridSelectionModel) => void;
}

export const ProjectDataGrid: FC<Props> = ({ columns, projects, selectionModel, setSelectionModel }) => {
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={projects}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={rowsPerPage}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={setSelectionModel}
        selectionModel={selectionModel}
      />
    </div>
  );
};
