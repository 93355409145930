import { useLoginState } from 'globalStates/Login';
import { Navigate, Outlet } from 'react-router-dom';

const Private = () => {
  const auth = useLoginState();

  if (auth.isLogin) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace />;
};
export default Private;
