import { useDownloadFileMutators, useDownloadFileState } from 'globalStates/Files';
import { useMenuMutators } from 'globalStates/Menu';
import { useRoleState } from 'globalStates/Role';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useReadFileRepository } from 'repository/File';
import { DownloadItems } from 'store/Files';
import { AdminMenuItem, MainMenuItem } from 'store/MainMenu';

const Setting = () => {
  const readFile = useReadFileRepository();
  const [setDownloadFileState] = useDownloadFileMutators();
  const files = useDownloadFileState();
  const setMenu = useMenuMutators();
  const [role] = useRoleState();

  useEffect(() => {
    (() => {
      if (!files.files || files.files.length === 0) {
        try {
          Promise.all(
            DownloadItems.map(async (di) => {
              const path = await readFile(di.sourceName);
              return { ...di, path };
            })
          ).then((data) => setDownloadFileState(data));
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [files.files, readFile, setDownloadFileState]);

  useEffect(() => {
    setMenu(role === 'admin' ? AdminMenuItem : MainMenuItem);
  }, [role, setMenu]);

  return <Outlet />;
};

export default Setting;
