import { useState, FC } from 'react';
import { IconButton, Menu, MenuItem, Toolbar, Typography, styled, ListItemIcon, Button } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { User } from 'model/User';
import AccountCard from './AccountCard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  title: string;
  user?: User;
  open?: boolean;
  onClickMenu?: () => void;
  onClickLogout?: () => void;
}

const MenuAppBar: FC<Props> = ({ title, user, open, onClickMenu, onClickLogout }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <AppBar position="static" open={open}>
      <Toolbar>
        {!open && (
          <Button variant="contained" aria-label="menu" sx={{ mr: 2 }} onClick={onClickMenu}>
            <Typography color="white" component="span">
              メニュー
            </Typography>
          </Button>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {user && (
          <div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem>
                <AccountCard name={user.id} email={user.email} />
              </MenuItem>
              <MenuItem sx={{ px: 4, py: 2 }} onClick={onClickLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <Typography>ログアウト</Typography>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MenuAppBar;
