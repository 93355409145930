import { ChangeEvent, useCallback, useEffect, useMemo, FC, useState } from 'react';
import { Grid, Paper, Typography, InputAdornment, Button, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectItem } from 'component/Select';
import { TextField } from 'component/TextField';
import Copyright from 'component/Copyright';
import { Auger, Soils } from 'model/Productivity/Auger';
import {
  CasingItems,
  DrillingDiameterItems,
  DrivenTimeItems,
  InjectionTimeGroutItems,
  InstallationCountItems,
  InstallationTimeItems,
  InstallationTimeItemNone,
  InstallationTimeItemsForHSteel,
  InstallationTimeItemsForSteelSheetPiles,
  SandFillingTimeItems,
  SandySoilNItems,
  ScrewJointTimeItems,
  SoilItems,
  SteelMaterialItems,
  WorkCorrectionFactorCategory,
} from 'store/Auger';
import CalculationResults from './CalculationResults';
import { WorkCorrectionFactorList } from 'store/Auger';
import WorkCorrection from '../WorkCorrection';
import { MasterData } from 'model/Master';

interface Props {
  productivity: Auger;
  openCalculate: boolean;
  machines: MasterData[];
  onClickBack: () => void;
  onClickConfirm: (productivity: Auger) => void;
  updateProductivity: (prop: keyof Auger, productivity: Auger) => void;
  onUpdate?: (productivity: Auger) => void;
}

const schema = z.object({
  name: z.string().min(1),
  specification: z.string().min(1),
  usedMachine: z.string(), // 使用機械
  drillingDiameter: z.string(), //削孔径
  steelMaterial: z.string(), // 鋼材
  casing: z.string(), // ケーシング
  sandFillingTime: z.string(), // 1本あたり砂充填時間
  screwJointTimesSelect: z.string(), // スクリュージョイント回数
  injectionTimeGrout: z.string(), // 1本当たりグラウト注入時間
  drivenTime: z.string(), // 1本あたり打込時間
  installationTime: z.string(), // 溶接・ボルト取り付け時間
  installationCountSelect: z.string(), // 溶接・ボルト取り付け回数
  otherFactor: z.object({
    name: z.string(),
    value: z.number(),
  }),
});

const Create: FC<Props> = ({
  productivity,
  openCalculate,
  machines,
  onClickBack,
  updateProductivity,
  onClickConfirm,
  onUpdate,
}) => {
  const [correctionFactors, setCorrectionFactors] = useState<string[]>(productivity.correctionFactors);
  const { control, handleSubmit, watch, setValue } = useForm({ resolver: zodResolver(schema) });

  // eslint-disable-next-line
  const convertSelectItem = (item: any): SelectItem => ({
    key: item?.key || '',
    label: item?.name,
    value: item?.key || '',
  });
  const machineItems = useMemo(() => machines.map(convertSelectItem), [machines]);
  const drillingDiameterItems = useMemo(() => DrillingDiameterItems.map(convertSelectItem), []);
  const steelMaterialItems = useMemo(() => SteelMaterialItems.map(convertSelectItem), []);
  const casingItems = useMemo(() => CasingItems.map(convertSelectItem), []);
  const soilItems = useMemo(() => SoilItems.map(convertSelectItem), []);
  const sandySoilNItems = useMemo(() => SandySoilNItems.map(convertSelectItem), []);
  const screwJointTimeItems = useMemo(() => ScrewJointTimeItems.map(convertSelectItem), []);
  const injectionTimeGroutItems = useMemo(() => InjectionTimeGroutItems.map(convertSelectItem), []);
  const drivenTimeItems = useMemo(() => DrivenTimeItems.map(convertSelectItem), []);
  const installationTimeItems = useMemo(() => InstallationTimeItems.map(convertSelectItem), []);
  const installationTimeItemNone = useMemo(() => InstallationTimeItemNone.map(convertSelectItem), []);
  const installationTimeItemsForHSteel = useMemo(() => InstallationTimeItemsForHSteel.map(convertSelectItem), []);
  const installationTimeItemsForSteelSheetPiles = useMemo(
    () => InstallationTimeItemsForSteelSheetPiles.map(convertSelectItem),
    []
  );
  const installationCountItems = useMemo(() => InstallationCountItems.map(convertSelectItem), []);
  const sandFillingTimeItems = useMemo(() => SandFillingTimeItems.map(convertSelectItem), []);

  const [selectableInstallationTimeItems, setSelectableInstallationTimeItems] = useState(installationTimeItems);

  // eslint-disable-next-line
  const parseProductivity = (data: any): Auger => {
    const { screwJointTimesSelect } = data;
    const screwJointTimes = Number.parseInt(screwJointTimesSelect);
    const { installationCountSelect } = data;
    const installationCount = Number.parseInt(installationCountSelect);
    const { otherFactor } = data;
    const factorTotal = calcFactorTotal(correctionFactors) + otherFactor.value;

    if (data['soils']) {
      const inputSoils = [
        data['soils']['soil0'],
        data['soils']['soil1'],
        data['soils']['soil2'],
        data['soils']['soil3'],
      ];
      const convertSoils = (soils: Soils, index: number) => ({
        id: index,
        soil: soils.soil,
        nValue: soils.nValue,
        value: soils.value,
      });
      const newSoils = inputSoils?.map((s, i) => convertSoils(s, i));
      return { ...productivity, ...data, screwJointTimes, installationCount, factorTotal, soils: newSoils };
    }

    return { ...productivity, ...data, screwJointTimes, installationCount, factorTotal };
  };
  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    onClickConfirm(parseProductivity(data));
  };

  // eslint-disable-next-line
  const updateValue = useCallback(
    (prop: keyof Auger) => (e: ChangeEvent<HTMLInputElement>) =>
      updateProductivity(prop, { ...productivity, [prop]: e.target.value }),
    [updateProductivity, productivity]
  );

  const calcFactorTotal = (items: string[]): number =>
    WorkCorrectionFactorList.filter((factor) => items.includes(factor.key || '')).reduce(
      (prev, current) => prev + (current.value || 0),
      0
    );

  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      const newItems = ((items: string[]): string[] => {
        const index = items.indexOf(key);
        if (checked && index === -1) {
          return [...items, key];
        } else if (!checked && index !== -1) {
          return items.filter((c) => key !== c);
        }
        return items;
      })(correctionFactors);
      setCorrectionFactors(newItems);
      const factorTotal = calcFactorTotal(newItems) + productivity.otherFactor.value;
      onUpdate &&
        onUpdate({
          ...productivity,
          correctionFactors: newItems,
          factorTotal,
        });
    },
    [correctionFactors, onUpdate, productivity]
  );

  useEffect(() => {
    const subscription = watch((value) => {
      onUpdate && onUpdate(parseProductivity(value));
    });
    return () => subscription.unsubscribe();
  }, [onUpdate, watch, parseProductivity]);

  useEffect(() => {
    const hSteel = 'h';
    const steelSheetPiles = 'steel';
    const none = '_';

    if (productivity.steelMaterial === hSteel) {
      setSelectableInstallationTimeItems(installationTimeItemsForHSteel);
      if (!installationTimeItemsForHSteel.find((item) => item.value === productivity.installationTime)) {
        setValue('installationTime', '');
      }
    } else if (productivity.steelMaterial === steelSheetPiles) {
      setSelectableInstallationTimeItems(installationTimeItemsForSteelSheetPiles);
      if (!installationTimeItemsForSteelSheetPiles.find((item) => item.value === productivity.installationTime)) {
        setValue('installationTime', '');
      }
    } else if (productivity.steelMaterial === none) {
      setSelectableInstallationTimeItems(installationTimeItemNone);
      if (!installationTimeItemNone.find((item) => item.value === productivity.installationTime)) {
        setValue('installationTime', '_');
      }
    }
  }, [
    productivity.steelMaterial,
    productivity.installationTime,
    setValue,
    installationTimeItems,
    installationTimeItemNone,
    installationTimeItemsForHSteel,
    installationTimeItemsForSteelSheetPiles,
  ]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {productivity.remark || '新しい歩掛り'}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  label="名称"
                  variant="standard"
                  defaultValue={productivity.name}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="specification"
                  name="specification"
                  label="規格"
                  variant="standard"
                  defaultValue={productivity.specification}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="machine"
                  labelId="usedMachineLabel"
                  label="使用機械"
                  name="usedMachine"
                  items={machineItems}
                  defaultValue={productivity.usedMachine}
                  control={control}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Select
                  required
                  fullWidth
                  id="drilling-diameter"
                  labelId="drillingDiameter"
                  label="削孔径"
                  name="drillingDiameter"
                  items={drillingDiameterItems}
                  defaultValue={productivity.drillingDiameter}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  required
                  fullWidth
                  id="steel-material"
                  labelId="steelMaterial"
                  label="鋼材"
                  name="steelMaterial"
                  items={steelMaterialItems}
                  defaultValue={productivity.steelMaterial}
                  control={control}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  required
                  fullWidth
                  id="casing"
                  labelId="casing"
                  label="ケーシング"
                  name="casing"
                  items={casingItems}
                  defaultValue={productivity.casing}
                  control={control}
                />
              </Grid>
              {productivity.soils?.map((soil) => {
                return (
                  <Grid item container xs={12} key={soil.id} spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Select
                        fullWidth
                        id="soil"
                        labelId={`soils.soil${soil.id}.soil`}
                        label="土質"
                        name={`soils.soil${soil.id}.soil`}
                        items={soilItems}
                        defaultValue={soil.soil}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Select
                        type="number"
                        fullWidth
                        id="nValue"
                        labelId={`soils.soil${soil.id}.nValue`}
                        label="N値"
                        name={`soils.soil${soil.id}.nValue`}
                        items={sandySoilNItems}
                        defaultValue={soil.nValue}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        id="value"
                        type="number"
                        name={`soils.soil${soil.id}.value`}
                        label="削孔長"
                        variant="standard"
                        defaultValue={soil.value}
                        control={control}
                        InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="screw-joint"
                  labelId="screwJointLabel"
                  label="スクリュージョイント回数"
                  name="screwJointTimesSelect"
                  defaultValue={productivity.screwJointTimesSelect}
                  items={screwJointTimeItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="injection-time-grout"
                  labelId="InjectionTimeGrout"
                  label="1本当たりグラウト注入時間"
                  name="injectionTimeGrout"
                  defaultValue={productivity.injectionTimeGrout}
                  items={injectionTimeGroutItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="driven-time-grout"
                  labelId="DrivenTime"
                  label="1本あたり打込時間"
                  name="drivenTime"
                  defaultValue={productivity.drivenTime}
                  items={drivenTimeItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="installation-time"
                  labelId="installationTime"
                  label="溶接・ボルト取り付け"
                  name="installationTime"
                  defaultValue={productivity.installationTime}
                  items={selectableInstallationTimeItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="installation-count"
                  labelId="installationCount"
                  label="溶接・ボルト取り付け回数"
                  name="installationCountSelect"
                  defaultValue={productivity.installationCountSelect}
                  items={installationCountItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  required
                  fullWidth
                  id="sand-filling-time"
                  labelId="SandFillingTime"
                  label="1本あたり砂充填時間"
                  name="sandFillingTime"
                  defaultValue={productivity.sandFillingTime}
                  items={sandFillingTimeItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <WorkCorrection
                  title="作業補正係数"
                  selected={correctionFactors}
                  workCorrectionCategories={WorkCorrectionFactorCategory}
                  workCorrections={WorkCorrectionFactorList}
                  onChange={handleChangeCheckbox}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  その他（作業条件・制約、使用機器、N値（非常に高い）など別途考慮）
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-name"
                  name="otherFactor.name"
                  label="条件"
                  variant="standard"
                  defaultValue={productivity.otherFactor?.name || ''}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-value"
                  name="otherFactor.value"
                  label="作業係数"
                  variant="standard"
                  type="number"
                  InputProps={{
                    inputProps: { step: '0.1' },
                  }}
                  defaultValue={productivity.otherFactor?.value || 0}
                  control={control}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
                戻る
              </Button>
              <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
                確認
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <CalculationResults productivity={productivity} open={openCalculate} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Create;
