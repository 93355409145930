import { useState, useEffect, useCallback } from 'react';
import { User } from 'model/User';
import { useReadUserRepository, useReadUsersRepository } from 'repository/User';

export const useFetchUsers = (): [User[], unknown | null] => {
  const [masterData, setMasterData] = useState<User[]>([]);
  const [error, setError] = useState<unknown | null>(null);
  const readUsers = useReadUsersRepository();

  const fetchData = useCallback(async (): Promise<User[]> => {
    return await readUsers();
  }, [readUsers]);

  useEffect(() => {
    (async () => {
      setError(null);
      try {
        const data = await fetchData();
        setMasterData(data);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [masterData, error];
};

export const useFetchUser = (): ((id: string) => Promise<User | undefined>) => {
  const readUser = useReadUserRepository();

  return useCallback(
    async (id: string): Promise<User | undefined> => {
      return await readUser(id);
    },
    [readUser]
  );
};
