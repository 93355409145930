import { useCallback, useEffect, useMemo, FC, useState } from 'react';
import { Grid, Paper, Typography, SelectChangeEvent, InputAdornment, Button, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Select, SelectItem } from 'component/Select';
import { TextField } from 'component/TextField';
import Copyright from 'component/Copyright';
import { TypeIn as HammerIn } from 'model/Productivity/TypeIn';
import {
  BondingCountItems,
  BondingTimeItems,
  DrivenConstantItems,
  PreparationTimeItems,
  SteelMaterialItems,
  SteelMaterialTypeItems,
  SteelMaterialTypeItemsForHSteel,
  SteelMaterialTypeItemsForSteelSheetPiles,
  WorkCorrectionFactorCategory,
} from 'store/TypeIn';
import CalculationResults from './CalculationResults';
import { WorkCorrectionFactorList } from 'store/TypeIn';
import WorkCorrection from 'component/Productivity/WorkCorrection';
import { MasterData } from 'model/Master';

interface Props {
  productivity: HammerIn;
  openCalculate: boolean;
  machines: MasterData[];
  onClickBack: () => void;
  onClickConfirm: (productivity: HammerIn) => void;
  updateProductivity: (prop: keyof HammerIn, productivity: HammerIn) => void;
  onUpdate?: (productivity: HammerIn) => void;
}

const schema = z.object({
  name: z.string().min(1),
  specification: z.string().min(1),
  usedMachine: z.string(),
  steelMaterial: z.string(), // 鋼材種類
  steelMaterialType: z.string(), // 鋼材種類 寸法・型
  steelMaterialLength: z.number().nonnegative(), //`鋼材長
  drivenLength: z.number().nonnegative(), // 打込み長
  drivenConstant: z.string(), // 打込み定数
  bondingTime: z.string(), // 溶接時間・ボルト取り付け時間
  bondingCountSelect: z.string(), // 溶接時間・ボルト取り付け回数
  preparationTime: z.string(), // T1：１単位当たり準備時間
  otherFactor: z.object({
    name: z.string(),
    value: z.number(),
  }),
});

const Create: FC<Props> = ({
  productivity,
  openCalculate,
  machines,
  onClickBack,
  onClickConfirm,
  updateProductivity,
  onUpdate,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm({ resolver: zodResolver(schema) });
  const [correctionFactors, setCorrectionFactors] = useState<string[]>(productivity.correctionFactors);

  // eslint-disable-next-line
  const convertSelectItem = (item: any): SelectItem => ({
    key: item?.key || '',
    label: item?.name,
    value: item?.key || '',
  });
  const machineItems = useMemo(() => machines.map(convertSelectItem), [machines]);
  const steelMaterialItems = useMemo(() => SteelMaterialItems.map(convertSelectItem), []);
  const steelMaterialTypeItems = useMemo(() => SteelMaterialTypeItems.map(convertSelectItem), []);
  const steelMaterialTypeItemsForHSteel = useMemo(() => SteelMaterialTypeItemsForHSteel.map(convertSelectItem), []);
  const steelMaterialTypeItemsForSteelSheetPiles = useMemo(
    () => SteelMaterialTypeItemsForSteelSheetPiles.map(convertSelectItem),
    []
  );
  const drivenConstantItems = useMemo(() => DrivenConstantItems.map(convertSelectItem), []);
  const bondingTimeItems = useMemo(() => BondingTimeItems.map(convertSelectItem), []);
  const bondingCountItems = useMemo(() => BondingCountItems.map(convertSelectItem), []);
  const preparationTimeItems = useMemo(() => PreparationTimeItems.map(convertSelectItem), []);
  const [selectableItems, setSelectableItems] = useState(steelMaterialTypeItems);

  const updateSelectValue = useCallback(
    (prop: keyof HammerIn) => (e: SelectChangeEvent<unknown>) =>
      updateProductivity(prop, { ...productivity, [prop]: e.target.value }),
    [updateProductivity, productivity]
  );

  const calcFactorTotal = (items: string[]): number =>
    WorkCorrectionFactorList.filter((factor) => items.includes(factor.key || '')).reduce(
      (prev, current) => prev + (current.value || 0),
      0
    );

  const handleChangeCheckbox = useCallback(
    (key: string, checked: boolean) => {
      const newItems = ((items: string[]): string[] => {
        const index = items.indexOf(key);
        if (checked && index === -1) {
          return [...items, key];
        } else if (!checked && index !== -1) {
          return items.filter((c) => key !== c);
        }
        return items;
      })(correctionFactors);
      setCorrectionFactors(newItems);
      const factorTotal = calcFactorTotal(newItems) + productivity.otherFactor.value;
      onUpdate &&
        onUpdate({
          ...productivity,
          correctionFactors: newItems,
          factorTotal,
        });
    },
    [setCorrectionFactors, correctionFactors, productivity, onUpdate]
  );

  // eslint-disable-next-line
  const parseProductivity = (data: any): HammerIn => {
    const { bondingCountSelect } = data;
    const bondingCount = Number.parseInt(bondingCountSelect);
    const { otherFactor } = data;
    const factorTotal = calcFactorTotal(correctionFactors) + otherFactor.value;
    return { ...productivity, ...data, bondingCount, factorTotal };
  };

  // eslint-disable-next-line
  const onSubmit = (data: any) => {
    onClickConfirm(parseProductivity(data));
  };

  useEffect(() => {
    const subscription = watch((value) => onUpdate && onUpdate(parseProductivity(value)));
    return () => subscription.unsubscribe();
  }, [onUpdate, parseProductivity, watch]);

  useEffect(() => {
    if (productivity.steelMaterial) {
      const preparationTime = PreparationTimeItems.find((pti) => pti.key === productivity.steelMaterial)?.key;
      setValue('preparationTime', preparationTime);
    }
  }, [productivity.steelMaterial, setValue]);

  useEffect(() => {
    // NOTE: 鋼材種類 選択後の 鋼材種類寸法・型の選択肢変更と選択内容のクリア
    const hSteel = 'h';
    const steelSheetPiles = 'steel';

    if (productivity.steelMaterial === hSteel) {
      setSelectableItems(steelMaterialTypeItemsForHSteel);
      if (!steelMaterialTypeItemsForHSteel.find((item) => item.value === productivity.steelMaterialType)) {
        setValue('steelMaterialType', '');
      }
    } else if (productivity.steelMaterial === steelSheetPiles) {
      setSelectableItems(steelMaterialTypeItemsForSteelSheetPiles);
      if (!steelMaterialTypeItemsForSteelSheetPiles.find((item) => item.value === productivity.steelMaterialType)) {
        setValue('steelMaterialType', '');
      }
    }
  }, [
    productivity.steelMaterial,
    productivity.steelMaterialType,
    setValue,
    steelMaterialTypeItems,
    steelMaterialTypeItemsForHSteel,
    steelMaterialTypeItemsForSteelSheetPiles,
  ]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography sx={{ mb: 3 }} component="h1" variant="h4" align="center">
            {productivity.remark || '新しい歩掛り'}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  name="name"
                  label="名称"
                  variant="standard"
                  defaultValue={productivity.name}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="specification"
                  name="specification"
                  label="規格"
                  variant="standard"
                  defaultValue={productivity.specification}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="machine"
                  labelId="used-machine-label"
                  label="使用機械"
                  name="usedMachine"
                  items={machineItems}
                  defaultValue={productivity.usedMachine}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="steel-material"
                  labelId="steel-material-label"
                  label="鋼材種類"
                  name="steelMaterial"
                  items={steelMaterialItems}
                  defaultValue={productivity.steelMaterial}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="steel-material2"
                  labelId="steel-material2-label"
                  label="鋼材種類 寸法・型"
                  name="steelMaterialType"
                  items={selectableItems}
                  defaultValue={productivity.steelMaterialType}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="preparation-time"
                  labelId="preparation-time"
                  label="T1：１単位当たり準備時間"
                  name="preparationTime"
                  defaultValue={productivity.preparationTime}
                  items={preparationTimeItems}
                  control={control}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="steel-material-length"
                  name="steelMaterialLength"
                  label="鋼材長"
                  variant="standard"
                  type="number"
                  defaultValue={productivity.steelMaterialLength}
                  control={control}
                  InputProps={{
                    inputProps: { min: 0, step: '0.1' },
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  required
                  fullWidth
                  id="driven-length"
                  name="drivenLength"
                  label="L：打込み長"
                  variant="standard"
                  type="number"
                  defaultValue={productivity.drivenLength}
                  control={control}
                  InputProps={{
                    inputProps: { min: 0, step: '0.1' },
                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="driven-constant"
                  labelId="driven-constant-label"
                  label="β：打込み定数"
                  name="drivenConstant"
                  control={control}
                  defaultValue={productivity.drivenConstant}
                  items={drivenConstantItems}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="bonding-times"
                  labelId="bonding-times-label"
                  label="溶接・ボルト取り付け"
                  name="bondingTime"
                  items={bondingTimeItems}
                  defaultValue={productivity.bondingTime}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  fullWidth
                  id="bonding-count"
                  labelId="bonding-count-label"
                  label="溶接・ボルト取り付け回数"
                  name="bondingCountSelect"
                  control={control}
                  defaultValue={productivity.bondingCountSelect}
                  items={bondingCountItems}
                  onChange={updateSelectValue('bondingCount')}
                />
              </Grid>
              <Grid item xs={12}>
                <WorkCorrection
                  title="作業補正係数"
                  selected={correctionFactors}
                  workCorrectionCategories={WorkCorrectionFactorCategory}
                  workCorrections={WorkCorrectionFactorList}
                  onChange={handleChangeCheckbox}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  その他（作業条件・制約、使用機器、N値（非常に高い）など別途考慮）
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-name"
                  name="otherFactor.name"
                  label="条件"
                  variant="standard"
                  defaultValue={productivity.otherFactor?.name || ''}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="other-work-correction-value"
                  name="otherFactor.value"
                  label="作業係数"
                  variant="standard"
                  type="number"
                  InputProps={{
                    inputProps: { step: '0.1' },
                  }}
                  defaultValue={productivity.otherFactor?.value || 0}
                  control={control}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="outlined" sx={{ mt: 3, ml: 1 }} data-testid="button back" onClick={onClickBack}>
                戻る
              </Button>
              <Button variant="contained" sx={{ mt: 3, ml: 1 }} type="submit">
                確認
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <CalculationResults productivity={productivity} open={openCalculate} />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
};

export default Create;
