import { FC } from 'react';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { DatePicker as Base, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type Props<TInputDate, TDate> = Omit<DatePickerProps<TInputDate, TDate>, 'onChange' | 'value' | 'renderInput'> &
  TextFieldProps & {
    name: string;
    // eslint-disable-next-line
    control?: Control<FieldValues, any>;
    defaultValue?: Date;
    onChange?: (date: unknown, keyboardInputValue?: string | undefined) => void;
    value?: string;
    renderInput?: string;
  };

export const DatePicker: FC<Props<Date, Date>> = (props) => (
  <Controller
    name={props.name}
    control={props.control}
    defaultValue={props.defaultValue}
    render={({ field, fieldState }) => (
      <Base
        {...props}
        {...field}
        value={field.value}
        onChange={field.onChange}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
          return (
            <TextField
              {...props}
              {...params}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          );
        }}
      />
    )}
  />
);
