import { FC } from 'react';
import LoginComponent from 'component/Login';
import { useLogin } from 'usecase/Login';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserMutators } from 'globalStates/User';
import { useErrorsMutators } from 'globalStates/Errors';
import { FirebaseError } from 'firebase/app';
import { useFetchUser } from 'usecase/User';
import { useRoleState } from 'globalStates/Role';
import { useSendAudit } from 'usecase/Audit';

const Login: FC = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const [setUserState] = useUserMutators();
  const setErrors = useErrorsMutators();
  const getUserData = useFetchUser();
  const [, setRole] = useRoleState();
  const postAudit = useSendAudit();
  const location = useLocation();

  const handleLogin = async (email: string, password: string) => {
    try {
      const credential = await login(email, password);
      if (credential) {
        const data = await getUserData(credential.user.uid);
        setUserState({
          id: credential.user.uid,
          name: credential.user.displayName || '',
          email: credential.user.email || '',
          role: data?.role || 'user',
        });
        setRole(data?.role === 'admin' ? 'admin' : 'user');
        postAudit('success', location, data, ['login'], undefined, undefined);
        navigate('/', { replace: false });
      }
    } catch (e) {
      postAudit('failed', location, null, ['login'], undefined, String(e));
      if (e instanceof FirebaseError && (e.code === 'auth/user-not-found' || e.code === 'auth/wrong-password')) {
        setErrors({ code: 999, message: `メールアドレスまたはパスワードに誤りがあります` });
      } else if (e instanceof Error) {
        setErrors({ code: 999, message: e.message });
      } else {
        setErrors({ code: 999, message: e as string });
      }
    }
  };

  return (
    <div>
      <LoginComponent onClickConfirm={handleLogin} />
    </div>
  );
};

export default Login;
